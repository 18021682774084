import React, { Component } from "react";
import Form from "./form";
import { updateUserRoles, createUserRoles, fetchRoleData } from "api/roles";
import { fetchPermissions } from "api/permissions.js";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.colors = [
      "#ff5252",
      "#7c4dff",
      "#40c4ff",
      "#00c853",
      "#ffd600",
      "#ff6e40",
      "#ff4081",
      "#536dfe",
      "#00b8d4",
      "#64dd17",
      "#ffd740",
      "#e040fb",
      "#448aff",
      "#00bfa5",
      "#aeea00",
      "#ffab40",
    ];
    this.state = {
      open: false,
      allSubPermissions: [],
      data: {
        name: "",
        status: "active",
        subPermissions: [],
        type: "UserRole",
      },
    };
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    let { data } = this.state;
    data[name] = value;
    this.setState({ data });
  };

  requestParams = () => {
    const { subPermissions, type, ...restData } = this.state.data;
    let sub_permission_ids = subPermissions
      ? subPermissions.map((subPermission) => subPermission.value)
      : [];
    return {
      ...restData,
      sub_permission_ids,
      type,
    };
  };

  onSubmit = () => {
    const { id, props } = this;
    props.submitClick();
    const method = id ? updateUserRoles : createUserRoles;
    method({ roles: this.requestParams() }, id)
      .then((data) => {
        success(data.message);
        this.props.handleSubmitSuccess();
        props.submitClick();
      })
      .catch((error) => {
        props.submitClick();
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    fetchPermissions({ search: "", perPage: 50 })
      .then(({ data }) => {
        let allSubPermissions = data.map((permission, index) => {
          return {
            label: permission.name,
            options: permission.sub_permissions.map((sub_permission) => {
              return {
                value: sub_permission.id,
                label: sub_permission.name,
                group: permission.name,
                color: this.colors[index] || "#00B8D9",
              };
            }),
          };
        });
        this.setState({ allSubPermissions });
      })
      .catch((error) => {
        handleResponse(error);
      });

    if (this.props.data) {
      const { id, name, status, type } = this.props.data;
      this.id = id;
      fetchRoleData(id)
        .then(({ data }) => {
          let subPermissions = [];
          data.permissions.map((permission, index) =>
            permission.sub_permissions.map((sub_permission) =>
              subPermissions.push({
                value: sub_permission.id,
                label: sub_permission.name,
                group: permission.name,
                color: this.colors[index] || "#00B8D9",
              })
            )
          );
          this.setState({ data: { ...this.state.data, subPermissions } });
        })
        .catch((error) => {
          handleResponse(error);
        });
      this.setState({
        data: {
          ...this.state.data,
          name,
          status,
          type,
        },
      });
    }
  };

  render() {
    const { id, handleChange } = this;
    const { data, allSubPermissions } = this.state;
    const formProps = { id, ...data, allSubPermissions, handleChange };
    return (
      <React.Fragment>
        <Form {...formProps} />
      </React.Fragment>
    );
  }
}
export default DialogForm("Roles")(CRUD);
