import makeRequest from ".";

function generateParams(params = {}, totalEntries = null) {
  return `query=${params.search || ""}&page=${params.page ||
    1}&per_page=${totalEntries || params.perPage || 25}`;
}

export function fetchAlertType(requestParams) {
  return makeRequest({
    uri: `/api/v1/alert_types?${generateParams(requestParams)}`,
  });
}

export function createAlertType(requestBody) {
  return makeRequest({
    uri: `/api/v1/alert_types`,
    body: JSON.stringify(requestBody),
    method: "POST",
  });
}

export function updateAlertType(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/alert_types/${id}`,
    body: JSON.stringify(requestBody),
    method: "PUT",
  });
}
