import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";
import Button from "./Button";
import { Col } from "antd";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
} from "material-dashboard-react/components";

const ConfirmBox = (props) => {
  const {
    open,
    content,
    onDelete,
    onCancel,
    headerContent,
    confirmBtnText,
    fontSize,
  } = props;
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onDelete
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: { overflow: "visible" } }}
      >
        <Col xs={24} sm={24} md={24}>
          <Card
            style={{
              margin: 0,
              width: 450,
            }}
          >
            <CardHeader
              style={{
                padding: "0px 30px",
                borderBottom: "1px solid lightgray",
                margin: 0,
              }}
            >
              <p
                style={{
                  textAlign: "left",
                  color: "#e73e3a",
                  fontSize: 18,
                  marginTop: 20,
                  textTransform: "capitalize",
                }}
              >
                {headerContent}
              </p>
            </CardHeader>
            <CardBody
              style={{
                minHeight: 100,
                textAlign: "center",
                fontSize: fontSize || "14px",
              }}
            >
              {content}
            </CardBody>
            <CardFooter
              style={{
                padding: "20px 30px",
                margin: 0,
                borderTop: "1px solid lightgray",
              }}
            >
              <div style={{ flexGrow: 1 }} />
              <Button
                onClick={onCancel}
                autoFocus
                color="success"
                text="No, Go Back"
              />
              <Button
                onClick={onDelete}
                color="danger"
                variant="outlined"
                text={confirmBtnText || "Yes, Delete it"}
              />
            </CardFooter>
          </Card>
        </Col>
      </Dialog>
    </React.Fragment>
  );
};

ConfirmBox.prototype = {
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  content: PropTypes.string,
  open: PropTypes.bool.isRequired,
};

ConfirmBox.defaultProps = {
  content: (
    <div>
      <h3 style={{ marginBottom: 20 }}>
        Data once deleted cannot be retrived.!!!
      </h3>
      <h2>Are you sure to delete ?</h2>
    </div>
  ),
  open: false,
};

export default ConfirmBox;
