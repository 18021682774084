import React from "react";
import { Row, Col } from "antd";
import SimpleTextInput from "components/inputs/simpleTextInput";
import Multiselect from "components/inputs/Multiselect";
import { allNotificationTypes, allNotificationstatus } from "config";
import RadioButton from "components/inputs/radioButton";

const Form = ({
  handleChange,
  name,
  sms_users,
  whatsapp_users,
  mail_users,
  status,
  notification_type,
  users,
}) => {
  return (
    <Row>
      <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
        <SimpleTextInput
          labelText="Name"
          name="name"
          required
          value={name}
          onChange={handleChange}
        />
      </Col>
      <Col
        xs={24}
        sm={12}
        md={12}
        style={{ padding: "20px 10px", textAlign: "center" }}
      >
        <RadioButton
          labelText="Status"
          value={status}
          data={allNotificationstatus}
          onChange={handleChange}
          name="status"
        />
      </Col>
      <Col
        xs={24}
        sm={12}
        md={12}
        style={{ padding: "20px 10px", textAlign: "center" }}
      >
        <RadioButton
          labelText="Notification Type"
          value={notification_type}
          data={allNotificationTypes}
          onChange={handleChange}
          name="notification_type"
        />
      </Col>
      <Col xs={24} sm={24} md={24} style={{ padding: "10px" }}>
        <Multiselect
          isMulti
          name="sms_users"
          selected={sms_users}
          heading={"SMS"}
          options={users}
          handleChange={handleChange}
        />
      </Col>
      <Col xs={24} sm={24} md={24} style={{ padding: "10px" }}>
        <Multiselect
          isMulti
          name="whatsapp_users"
          selected={whatsapp_users}
          heading={"WHATSAPP"}
          options={users}
          handleChange={handleChange}
        />
      </Col>
      <Col xs={24} sm={24} md={24} style={{ padding: "10px" }}>
        <Multiselect
          isMulti
          name="mail_users"
          selected={mail_users}
          heading={"MAIL"}
          options={users}
          handleChange={handleChange}
        />
      </Col>
    </Row>
  );
};
export default Form;
