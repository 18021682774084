import React from "react";
import { formatTime, presence, timeDiff } from "helpers";
import moment from "moment";

export const columns = [
  {
    title: "Vehicle Number",
    dataIndex: "number_plate",
    align: "center",
    width: "40%",
    render: (number_plate) => `${presence(number_plate)}`,
  },
  {
    title: "In Time",
    dataIndex: "in_time",
    align: "center",
    render: (in_time) => (
      <React.Fragment>
        <span style={{ display: "block" }}>{formatTime(in_time)}</span>
        <span>{timeDiff(moment(), in_time)}</span>
      </React.Fragment>
    ),
  },
];
