import React, { Component } from "react";
import { handleResponse } from "components/notifications/handleResponse";
import PaginationTable from "components/paginationTables/dashboardTable";
import { setCookie } from "helpers";
import { Row } from "antd";
import { withRouter } from "react-router-dom";

class vehicleInOut extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.fetchTableData();
    this.autoRefresh();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  fetchTableData = async () => {
    await this.props
      .fetchUrl()
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  autoRefresh = () => {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.fetchTableData();
    this.timer = setTimeout(this.autoRefresh, 1000 * 60 * 2);
  };

  onRowClick = (data) => {
    let vehicle_entry_id = data.vehicle_entry_id,
      number_plate = data.number_plate;

    if (number_plate) {
      setCookie("vehicle_entry_id", vehicle_entry_id);
      this.props.history.push({
        pathname: `/vehicles/${number_plate}`,
      });
    }
  };

  render() {
    const { data } = this.state;
    const { onRowClick } = this;
    const {
      icon,
      tabColor,
      title,
      color,
      columns,
      titleColor,
      type,
      subtitle,
      subicon,
    } = this.props;
    const tableProps = {
      tabColor: tabColor,
      title: title,
      subtitle: subtitle,
      color: color,
      icon: icon,
      subicon: subicon,
      type: type,
      columns: columns,
      data: data,
      titleColor: titleColor,
      onRowClick: onRowClick,
    };
    return (
      <Row>
        <PaginationTable {...tableProps} />
      </Row>
    );
  }
}

export default withRouter(vehicleInOut);
