import React, { Component } from "react";
import DialogForm from "hoc/dialogForm";
import Form from "./Form";
import {
  success,
  handleResponse,
} from "components/notifications/handleResponse";
import { createVehicle, updateVehicle } from "api/vehicles";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = null;
    this.state = {
      submitted: false,
      open: false,
      number_plate: "",
      vehicle_type: "normal",
      welcome_message: "",
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  requestParams = () => {
    const {
      number_plate,
      vehicle_type,
      // welcome_message
    } = this.state;
    return {
      vehicles: {
        number_plate,
        vehicle_type,
        // welcome_message
      },
    };
  };

  onSubmit = () => {
    const { id } = this;
    const method = !id ? createVehicle : updateVehicle;
    method(this.requestParams(), id)
      .then((data) => {
        success(data.message);
        this.props.handleSubmitSuccess();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    const { data } = this.props;
    if (data) {
      this.id = data.id;
      this.setState({
        number_plate: data.number_plate,
        vehicle_type: data.vehicle_type,
      });
    }
  };

  render() {
    const { handleChange } = this;
    const { number_plate, vehicle_type, welcome_message } = this.state;
    const props = {
      number_plate,
      vehicle_type,
      welcome_message,
    };
    return <Form handleChange={handleChange} {...props} />;
  }
}
export default DialogForm("Vehicles")(CRUD);
