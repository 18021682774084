import React, { Component } from "react";
import { ContextProvider } from "context/ContextProvider";
import { withStyles } from "@material-ui/core/styles";
import { InputAdornment } from "@material-ui/core";
import { MdSecurity, MdLock } from "react-icons/md";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "material-dashboard-react/components/index";
import { signupPageStyle } from "../style";
import { Row, Col } from "antd";
import SimpleTextInput from "components/inputs/simpleTextInput";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAnimaton: "cardHidden",
    };
  }
  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Row style={{ justifyContent: "center", display: "flex" }}>
          <Col xs={24} sm={24} md={8}>
            <Card className={classes[this.state.cardAnimaton]}>
              <ContextProvider.Consumer>
                {(context) => (
                  <form
                    className={classes.form}
                    onSubmit={(e) => {
                      e.preventDefault();
                      context.update();
                    }}
                  >
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h3 style={{ margin: 10, color: "white" }}>
                        Update Password
                      </h3>
                    </CardHeader>
                    <CardBody>
                      <SimpleTextInput
                        labelText="Enter Verification Code"
                        id="otp_code"
                        required
                        onChange={context.handleChange}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <MdSecurity className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <SimpleTextInput
                        labelText="New Password"
                        id="password"
                        required
                        onChange={context.handleChange}
                        type="password"
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <MdLock className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button
                        simple
                        color="primary"
                        size="lg"
                        type="submit"
                        style={{ width: "100%" }}
                      >
                        Update
                      </Button>
                    </CardFooter>
                  </form>
                )}
              </ContextProvider.Consumer>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withStyles(signupPageStyle)(Form);
