import React from "react";
import AppBar from "components/AppBar";
import { Row, Col } from "antd";
import PeopleTraffic from "./Home/Sections/PeopleTraffic";

const PeopleCount = () => {
  return (
    <React.Fragment>
      <AppBar />
      <Row>
        <Col
          xs={0}
          md={24}
          sm={24}
          lg={24}
          style={{ paddingRight: "15px", paddingBottom: "15px" }}
        >
          <PeopleTraffic />
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default PeopleCount;
