import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "flex-start",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  picker: {
    marginBottom: 5,
    marginRight: 5,
    color: "#000",
  },
  input: {
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    border: "0.5px solid #cacaca",
    padding: 9,
    borderRadius: 4,
    color: "#000",
    fontSize: 15,
    opacity: 0.9,
  },
});

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {};
  }

  render() {
    const {
      classes,
      id,
      label,
      value,
      onChange,
      dateFormat,
      timeFormat,
    } = this.props;
    return (
      <form className={classes.container}>
        <label htmlFor={label} style={{ fontSize: 14, color: "#737272" }}>
          {label}
        </label>
        <Datetime
          dateFormat={dateFormat || "DD/MM/YYYY"}
          timeFormat={timeFormat === false ? false : true}
          value={value}
          className={classes.picker}
          inputProps={{
            className: classes.input,
          }}
          onChange={(e) => {
            onChange(id, e._d);
          }}
        />
      </form>
    );
  }
}
DatePicker.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(DatePicker);
