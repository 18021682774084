import React, { Component } from "react";
import "antd/dist/antd.css";
import { Button, Form, Upload, Icon } from "antd";

const dummyRequest = ({ onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

class UploadFile extends Component {
  constructor(props) {
    super(props);
    this.handleRef = React.createRef();
    this.state = {
      selectedFile: null,
      selectedFileList: [],
      btn: false,
    };
  }

  onChangeHandler = (info) => {
    const nextState = {};
    switch (info.file.status) {
      case "uploading":
        nextState.selectedFileList = [info.file];
        break;
      case "done":
        nextState.selectedFile = info.file;
        nextState.selectedFileList = [info.file];
        this._btn(true);
        break;
      default:
        nextState.selectedFile = null;
        nextState.selectedFileList = [];
        this._btn(false);
    }
    this.setState(() => nextState);
  };

  requestParams = () => {
    const { selectedFile } = this.state;
    return selectedFile && selectedFile["originFileObj"];
  };

  _btn = (bool) => this.setState({ btn: bool });

  render() {
    const { accept, title, uploadButton } = this.props;
    return (
      <React.Fragment>
        <Form.Item label={title} style={{ textTransform: "capitalize" }}>
          <Upload.Dragger
            name="file"
            customRequest={dummyRequest}
            fileList={this.state.selectedFileList}
            accept={accept}
            onChange={this.onChangeHandler}
            required
          >
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Support for a single upload.</p>
          </Upload.Dragger>
        </Form.Item>
        {uploadButton && (
          <Form.Item wrapperCol={{ span: 12, offset: 10 }}>
            <Button disabled={!this.state.btn} type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        )}
      </React.Fragment>
    );
  }
}

export default UploadFile;
