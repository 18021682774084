import React, { Component } from "react";
import Form from "./Form";
import { updateSchedulers } from "api/schedulers";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";
import { titleize } from "helpers";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      open: false,
      data: {
        name: "",
        status: "active",
        exec: "",
        schedule_time: "",
        health_check_url: "",
      },
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    let { data } = this.state;
    data[name] = value;
    this.setState({ data });
  };

  onSubmit = () => {
    const { id, props } = this;
    if (!id) {
      return;
    }
    props.submitClick();
    const {
      name,
      status,
      schedule_time,
      health_check_url,
      exec,
    } = this.state.data;
    const body = {
      schedulers: {
        name,
        status,
        schedule_time: schedule_time && schedule_time.value,
        health_check_url,
        exec,
      },
    };
    updateSchedulers(body, id)
      .then((data) => {
        success(data.message);
        this.props.handleSubmitSuccess();
        props.submitClick();
      })
      .catch((error) => {
        props.submitClick();
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    if (this.props.data) {
      let {
        id,
        name,
        status,
        exec,
        schedule_time,
        health_check_url,
      } = this.props.data;

      this.id = id;
      this.setState({
        data: {
          ...this.state.data,
          name,
          status,
          exec,
          schedule_time: schedule_time && {
            label: titleize(schedule_time),
            value: schedule_time,
          },
          health_check_url,
        },
      });
    }
  };

  render() {
    const { id, handleChange } = this;
    const { data } = this.state;
    const formProps = { id, handleChange, ...data };
    return (
      <React.Fragment>
        <Form {...formProps} />
      </React.Fragment>
    );
  }
}
export default DialogForm("Scheduler")(CRUD);
