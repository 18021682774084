import React from "react";
import c3 from "c3";
import "c3/c3.css";
import { purple } from "@material-ui/core/colors";
import { Card, CardHeader } from "material-dashboard-react/components/index";
import { AppBarContext } from "context/ContextProvider";
import { handleResponse } from "components/notifications/handleResponse";
import { VehicleAnalytics, VisitorAnalytics } from "api/vehicles";
import moment from "moment";

class RecentEntryGraph extends React.Component {
  constructor(props) {
    super(props);
    this.chart = null;
    this.graph_data = [];
    this.state = {
      data: [],
      menuOpen: false,
    };
  }

  componentDidMount = async () => {
    await this.fetchAnalyticsData();
    this.generateChart();
  };

  generateChart = () => {
    this.chart = c3.generate({
      bindto: "#recent-entry-chart",
      color: {
        pattern: ["orange"],
      },
      data: {
        x: "x",
        xFormat: "%Y-%m-%d",
        type: "bar",
        columns: this.graph_data.columns || [],
        color: "orange",
      },
      size: {
        height: this.props.graphHeight,
      },
      bar: {
        width: {
          ratio: 0.8,
        },
      },
      axis: {
        x: {
          type: "timeseries",
          tick: {
            format: "%b-%d",
          },
        },
      },
      legend: {
        show: false,
      },
      tooltip: {
        format: {
          title: function(d) {
            return moment(d).format("DD-MMM");
          },
          value: function(value) {
            return value;
          },
        },
      },
    });
  };

  fetchAnalyticsData = async () => {
    let data = null,
      action = null;

    const { number_plate, phone } = this.props;

    if (number_plate) {
      data = number_plate;
      action = VehicleAnalytics;
    } else if (phone) {
      data = phone;
      action = VisitorAnalytics;
    }

    if (data) {
      await action(data)
        .then(({ data }) => {
          if (data.length !== 0) {
            this.setState({ data }, async () => {
              await this.processed_data();
            });
          }
        })
        .catch((error) => {
          handleResponse(error);
        });
    }
  };

  processed_data = () => {
    let { data } = this.state;

    let date = ["x"];
    let value = ["Number of Entries"];

    data.forEach((item) => {
      date.push(item.datetime);
      value.push(item.count);
    });

    this.graph_data = {
      columns: [date, value],
    };
    return;
  };

  render() {
    return (
      <Card
        style={{
          flex: "2",
        }}
      >
        <CardHeader
          style={{
            marginTop: " -20px",
            marginRight: "20px",
            marginLeft: "20px",
            borderRadius: "3px",
            boxShadow: "#db8cea -1px 7px 8px -5px",
            background: `linear-gradient(60deg, ${purple[600]}, ${purple[400]})`,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p
              style={{
                color: "#fff",
                fontWeight: "300",
                margin: "initial",
              }}
            >
              {this.props.number_plate
                ? "Vehicle Entries in last 60 days"
                : "Visits in last 60 days"}
            </p>
          </div>
        </CardHeader>
        <AppBarContext.Consumer>
          {({ state: { menuOpen } }) => {
            if (menuOpen !== this.state.menuOpen) {
              setTimeout(
                function() {
                  this.generateChart();
                }.bind(this),
                100 * 10
              );
              this.setState({ menuOpen });
            }
          }}
        </AppBarContext.Consumer>
        <div
          id="recent-entry-chart"
          style={{
            margin: 30,
          }}
        />
      </Card>
    );
  }
}

export default RecentEntryGraph;
