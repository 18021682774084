import React, { Component } from "react";
import moment from "moment";
import {
  HeatMapComponent,
  Inject,
  Tooltip,
  Adaptor,
} from "@syncfusion/ej2-react-heatmap";
import {
  Card,
  CardHeader,
  CardBody,
} from "material-dashboard-react/components/index";
import { purple } from "@material-ui/core/colors";

let labels = [];
for (let i = 7; i >= 1; i--) {
  labels.push(
    moment(new Date())
      .subtract(i, "days")
      .format("DD-MMM")
  );
}

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.xLabels = labels;
    this.maxColorShades = 10;
    this.yLabels = [
      "12 AM",
      "01 AM",
      "02 AM",
      "03 AM",
      "04 AM",
      "05 AM",
      "06 AM",
      "07 AM",
      "08 AM",
      "09 AM",
      "10 AM",
      "11 AM",
      "12 PM",
      "01 PM",
      "02 PM",
      "03 PM",
      "04 PM",
      "05 PM",
      "06 PM",
      "07 PM",
      "08 PM",
      "09 PM",
      "10 PM",
      "11 PM",
    ];
  }

  color_palette = () => {
    let diff = Math.ceil(this.props.max / this.maxColorShades);
    let values = [{ value: 0, color: "rgb(169, 169, 169)" }];
    let currentValue = 1;
    let color_value = 169;
    let color_value1 = 169;

    for (let i = 1; i <= 10; i++) {
      values.push({
        value: currentValue,
        color: `rgb(${color_value}, ${color_value1}, 169)`,
      });
      currentValue += diff;
      color_value -= 6;
      color_value1 -= 15;
    }

    return values;
  };

  render() {
    const { data, title, subTitle, label } = this.props;
    let xValue = this.xLabels;
    // let newData = data?.filter(e => e) || [];
    let newData = [];
    let temp = false;

    let data1 = data.filter((e) => e);

    if (data1 && data1?.length > 0) {
      for (let i = 0; i < 7; i++) {
        for (let j = 0; j < data1?.length + 2; j++) {
          temp = false;
          if (this.xLabels[i] === label[j]) {
            newData.push(data1[i]);
            temp = true;
          }
        }
        if (!temp) {
          newData.push([0]);
        }
      }
    } else {
      newData = data1;
    }
    return (
      <Card style={{ marginBottom: "0px", height: "540px" }}>
        <CardHeader
          style={{
            marginTop: "-20px",
            marginRight: "20px",
            marginLeft: "20px",
            borderRadius: "3px",
            boxShadow: "#db8cea -1px 7px 8px -5px",
            background: `linear-gradient(60deg, ${purple[500]}, ${purple[300]})`,
          }}
        >
          <h4
            style={{
              margin: "0px 0px 3px",
              color: "white",
              fontWeight: "300",
              fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            }}
          >
            {title}
          </h4>
          <p
            style={{
              color: "rgba(255,255,255,.62)",
              fontWeight: "300",
              margin: "initial",
            }}
          >
            {subTitle}
          </p>
        </CardHeader>
        <CardBody>
          <HeatMapComponent
            height={"420px"}
            xAxis={{ labels: xValue, labelRotation: -45 }}
            yAxis={{ labels: this.yLabels }}
            cellSettings={{ format: "{value} Vehicles", showLabel: false }}
            paletteSettings={{ palette: this.color_palette() }}
            dataSource={newData || []}
          >
            <Inject services={[Adaptor, Tooltip]} />
          </HeatMapComponent>
        </CardBody>
      </Card>
    );
  }
}

export default HeatMap;
