import React, { Component } from "react";
import AppBar from "components/AppBar";
import { fetchPermissions, fetchRolesSubPermissions } from "api/permissions";
import PermissionTable from "components/paginationTables/permissions";
import { Row } from "antd";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import { fetchRoles } from "api/roles";
import { submitPermissions } from "api/access";
import CRUDPermission from "components/form/permission/CRUD";
import { createAccess, updateAccess, debounce } from "helpers";

class Permissions extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.state = {
      permissionsData: [],
      rolesData: [],
      rolesSubPermissions: {},
      formOpen: false,
      rowData: undefined,
    };
  }
  initialRequestParams = () => {
    return {
      perPage: 50,
      page: 1,
      search: "",
    };
  };
  componentDidMount = () => {
    this.fetchTableData();
    this.fetchRolesData();
  };

  fetchTableData = () => {
    fetchPermissions(this.requestParams)
      .then(({ data }) => {
        this.setState({
          permissionsData: data,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  fetchRolesData = () => {
    fetchRoles()
      .then(({ data }) => {
        let userRoleData = [];
        data.forEach((role) => {
          if (role.type === "UserRole") {
            userRoleData.push(role);
          }
        });
        this.setState({
          rolesData: userRoleData,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  fetchRolesPermission = (id) => {
    fetchRolesSubPermissions(id)
      .then(({ data }) => {
        let rolesSubPermissions = {
          ...this.state.rolesSubPermissions,
          [id]: data,
        };
        this.setState({
          rolesSubPermissions,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  handleCheckBoxChange = (subPermissionId, roleId, permissionId) => {
    let rolesSubPermissions = { ...this.state.rolesSubPermissions };
    let subPermissionPosition = rolesSubPermissions[permissionId]
      .map((obj) => Object.keys(obj)[0])
      .indexOf(subPermissionId);

    let roleIds = Object.values(
      rolesSubPermissions[permissionId][subPermissionPosition]
    )[0];
    let itemIndex = roleIds.indexOf(roleId);
    if (itemIndex === -1) {
      roleIds.push(roleId);
    } else {
      roleIds.splice(itemIndex, 1);
    }
    rolesSubPermissions[permissionId][subPermissionPosition][
      subPermissionId
    ] = roleIds;

    this.setState({
      rolesSubPermissions,
    });
  };

  permissionParams = (id) => {
    let permissions = {};
    this.state.rolesSubPermissions[id].map((obj) => {
      return (permissions[Object.keys(obj)[0]] = Object.values(obj)[0]);
    });
    return { permissions };
  };

  updateRoles = (id) => {
    if (!this.state.rolesSubPermissions[id]) {
      return;
    }
    submitPermissions(id, this.permissionParams(id))
      .then((data) => {
        success(data.message);
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  handleClose = () => {
    this.setState({
      formOpen: false,
      rowData: undefined,
    });
  };

  openEditForm = (row) => {
    let access = this.updateAction();
    if (access) {
      this.setState({ formOpen: true, rowData: row });
    }
  };

  openNewForm = () => {
    this.setState({ formOpen: true, rowData: undefined });
  };

  handleSubmitSuccess = (id) => {
    let rolesSubPermissions = {
      ...this.state.rolesSubPermissions,
      [id]: undefined,
    };
    this.setState({ rolesSubPermissions });
    this.handleClose();
    setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  createAction = () => {
    return createAccess("permissions");
  };

  updateAction = () => {
    return updateAccess("permissions");
  };

  render() {
    const {
      permissionsData,
      rolesData,
      rolesSubPermissions,
      formOpen,
      rowData,
    } = this.state;
    const {
      handleCheckBoxChange,
      fetchRolesPermission,
      handleClose,
      handleSubmitSuccess,
      updateRoles,
      openEditForm,
      openNewForm,
      onChange,
    } = this;
    const tableProps = {
      tabColor: "info",
      title: "Permissions",
      subTitle: "Create/Update permissions, sub-permission of the project",
      dataValue: "ADD permissions",
      permissionsData,
      rolesData,
      rolesSubPermissions,
      fetchRolesPermission,
      handleCheckBoxChange,
      updateRoles,
      openEditForm,
      handleOnClick: openNewForm,
      createAction: this.createAction(),
      updateAction: this.updateAction(),
    };

    const CRUDProps = {
      data: rowData,
      handleClose,
      handleSubmitSuccess,
      tabColor: "info",
      open: formOpen,
      onCancel: handleClose,
    };
    const appBarProps = {
      search: this.requestParams.search,
      searchOpen: true,
      onChange,
    };
    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <PermissionTable {...tableProps} />
        </Row>
        <CRUDPermission {...CRUDProps} />
      </React.Fragment>
    );
  }
}

export default Permissions;
