import React from "react";
import { formatTime, timeDiff, titleize, presence } from "helpers";
import { Tooltip } from "antd";
import moment from "moment";
const Status = ({ row: { status, from_time, to_time } }) => {
  let backgroundColor = "orange";
  let tooltipText = "";
  switch (status) {
    case "Raised":
      backgroundColor = "red";
      tooltipText = timeDiff(moment(), from_time);
      break;
    case "Resolved":
      backgroundColor = "green";
      tooltipText = timeDiff(from_time, to_time);
      break;
    default:
      break;
  }

  return (
    <Tooltip title={tooltipText}>
      <span
        style={{
          backgroundColor,
          color: "#fff",
          borderRadius: 20,
          padding: "5px 15px",
        }}
      >
        {titleize(status)}
      </span>
    </Tooltip>
  );
};

export const columns = [
  {
    title: "Triggered At",
    dataIndex: "from_time",
    render: (from_time) => `${formatTime(from_time)}`,
    align: "center",
    sorter: true,
  },

  {
    title: "Type",
    dataIndex: "type",
    align: "center",
    render: (_, row) =>
      `${presence(row.alert_type_name)} - ${presence(row.alert_group_name)}`,
  },
  {
    title: "Details",
    dataIndex: "description",
    width: "40%",
    render: (description) => `${presence(description)}`,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (_, row) => <Status row={row} />,
    align: "center",
  },
  {
    title: "Remarks",
    render: (row) => {
      return presence(row.remarks);
    },
    align: "center",
  },
];
