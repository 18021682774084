import React from "react";
import ReactDOM from "react-dom";
import c3 from "c3";
import "c3/c3.css";
import { MdUpdate } from "react-icons/md";
import {
  Card,
  CardIcon,
  CardHeader,
  CardBody,
} from "material-dashboard-react/components/index";
import { shuffleArray, titleize } from "helpers";
import NoChartData from "./NoChartData";
import { AppBarContext } from "context/ContextProvider";
import { handleResponse } from "components/notifications/handleResponse";

class Charts extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.chart = null;
    this.graph_data = [];
    this.colorPattern = shuffleArray([
      "#ef6c00",
      "#00695c",
      "#673ab7",
      "#f44336",
      "#e91e63",
      "#ff5722",
      "#607d8b",
      "#9e9e9e",
    ]);
    this.state = {
      menuOpen: false,
    };
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.chart = null;
  };

  autoRefresh = () => {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.fetchTableData();
    this.timer = setTimeout(this.autoRefresh, 1000 * 60 * 5);
  };

  componentDidMount = () => {
    this.generateChart();
    this.autoRefresh();
  };

  generateChart = () => {
    this.chart = c3.generate({
      bindto: `#${this.props.bindto}`,
      data: {
        json: [],
        type: this.props.chartType,
      },
      color: {
        pattern: this.colorPattern,
      },
      size: {
        height: 300,
      },
      tooltip: {
        format: {
          title: function(id) {
            return id;
          },
          value: function(value) {
            return value;
          },
        },
      },
    });
  };

  reloadChart = () => {
    let data = this.graph_data;
    let names = {};
    Object.keys(data).forEach(function(key) {
      names[key] = `${titleize(key)} (${data[key]})`;
    });

    if (!data || data.length === 0) {
      return;
    }
    this.chart.load({
      type: this.props.chartType,
      json: data,
      names: names,
    });
  };

  fetchTableData = async () => {
    await this.props
      .fetchFun()
      .then(({ data }) => {
        this.graph_data = data;
        if (!Object.keys(data).length) {
          this.chart = null;
          ReactDOM.render(
            <NoChartData />,
            document.getElementById(this.props.bindto)
          );
          return;
        }
        this.reloadChart(data);
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    const { title, subtitle, icon } = this.props;
    return (
      <Card
        style={{
          marginBottom: 5,
          height: 430,
        }}
      >
        <CardHeader color="info" stats icon>
          <CardIcon color="info">{icon}</CardIcon>
          <div>
            <p
              style={{
                textAlign: "left",
                color: "#0EB3C8",
                fontSize: "18px",
                marginBottom: "0px",
                marginTop: "10px",
              }}
            >
              {title}
            </p>
            <p
              style={{
                color: " #999999",
                fontSize: "12px",
                textAlign: "start",
                marginTop: "5px",
              }}
            >
              <MdUpdate
                style={{
                  color: " #999999",
                  height: "18px",
                  margin: " 0px -2px -4px 0px",
                  fontSize: "25px",
                }}
              />
              {subtitle}
            </p>
          </div>
        </CardHeader>
        <CardBody>
          <AppBarContext.Consumer>
            {({ state: { menuOpen } }) => {
              if (menuOpen !== this.state.menuOpen) {
                setTimeout(
                  function() {
                    this.chart && this.chart.flush();
                    this.generateChart();
                    this.reloadChart();
                  }.bind(this),
                  100 * 3.5
                );
                this.setState({ menuOpen });
              }
            }}
          </AppBarContext.Consumer>
          <div id={this.props.bindto} dataFormat="JSON" />
        </CardBody>
      </Card>
    );
  }
}

export default Charts;
