import makeRequest from ".";

function generateParams(params = {}, totalEntries = null) {
  let statuses = "";
  if (params.status) {
    statuses = params.status.map((value) => `&status[]=${value}`).join("");
  }
  return `from_time=${params.fromTime}&to_time=${
    params.toTime
  }${statuses}&query=${params.search}&page=${params.page ||
    1}&per_page=${totalEntries || params.perPage}`;
}

export function fetchListingData(requestParams) {
  return makeRequest({
    uri: `/api/v1/invites?${generateParams(requestParams)}`,
  });
}

export function triggerReportDownload(requestParams, totalEntries) {
  return makeRequest({
    uri: `/api/v1/invites/generate_report?${generateParams(
      requestParams,
      totalEntries
    )}`,
  });
}

export function blockInvite(id) {
  return makeRequest({
    method: "PUT",
    uri: `/api/v1/invites/${id}/block`,
  });
}

export function fetchUnitVisitor(id) {
  return makeRequest({
    uri: `/api/v1/visitors?unit_id=${id}&sort_by=visit_time_desc&per_page=250`,
  });
}

export function getInvites(requestParams, id) {
  return makeRequest({
    uri: `/api/v1/invites?staff_id=${id}&from_time=${
      requestParams.fromTime
    }&to_time=${requestParams.toTime}&page=${requestParams.page ||
      1}&per_page=${requestParams.perPage}`,
  });
}
