import React, { Component } from "react";
import { Row, Col } from "antd";

const alertStyle = {
  detailStyle: {
    padding: ".3rem",
    color: "white",
    borderRadius: "0px",
    marginBottom: "0",
    backgroundColor: "#008080",
    width: "150px",
    textAlign: "center",
    fontSize: "16px",
    fontWeight: "bold",
  },
  numberPlateIcon: {
    fontSize: "22px",
    marginLeft: ".5rem",
    cursor: "pointer",
    color: "blue",
  },
  entryInfo: {
    textAlign: "left",
    minWidth: "35%",
    color: "grey",
    fontSize: "16px",
  },
};
class VehicleDetails extends Component {
  render() {
    let { props } = this;
    let { entity } = props;

    return (
      <Row>
        {(entity?.vehicle?.owner?.full_name ||
          entity?.vehicle?.owner?.phone) && (
          <Col xs={24} sm={24}>
            <h2
              style={{
                textAlign: "initial",
                marginBottom: "0%",
                marginTop: "1%",
              }}
            >
              <p style={alertStyle.detailStyle}>Visitor Details</p>
            </h2>
            <div
              style={{
                borderBottom: "1px solid gray",
                marginRight: "5%",
              }}
            ></div>
            {entity?.vehicle?.owner && (
              <Col
                xs={24}
                sm={12}
                md={24}
                style={{ padding: "10px 0px 0px 10px" }}
              >
                {entity?.vehicle?.owner?.full_name && (
                  <div style={{ display: "flex", color: "#393A3B" }}>
                    <h2 style={alertStyle.entryInfo}>
                      Name
                      <span
                        style={{
                          fontWeight: "bold",
                          marginLeft: "1.2rem",
                        }}
                      >
                        :
                      </span>
                    </h2>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bolder",
                        marginLeft: "-3rem",
                      }}
                    >
                      {entity?.vehicle?.owner?.full_name}
                    </p>
                  </div>
                )}
                {entity?.vehicle?.owner?.phone && (
                  <div style={{ display: "flex", color: "#393A3B" }}>
                    <h2 style={alertStyle.entryInfo}>
                      Phone
                      <span
                        style={{
                          fontWeight: "bold",
                          marginLeft: "1.2rem",
                        }}
                      >
                        :
                      </span>
                    </h2>
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "bolder",
                        marginLeft: "-3rem",
                      }}
                    >
                      {entity?.vehicle?.owner?.phone}
                    </p>
                  </div>
                )}
              </Col>
            )}
          </Col>
        )}
      </Row>
    );
  }
}

export default VehicleDetails;
