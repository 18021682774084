import React, { Component } from "react";
import AppBar from "components/AppBar";
import PaginationTable from "components/paginationTables/withAddEdit";
import { fetchStaffsData } from "api/staffs";
import { columns } from "data/staffs";
import { Row } from "antd";
import { debounce, createAccess, updateAccess, uploadAccess } from "helpers";
import { handleResponse } from "components/notifications/handleResponse";
import CRUDStaffs from "components/form/staffs/CRUD";
import CRUDUpload from "components/form/staffs/CRUDUpload"

class Staffs extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      total_entries: 0,
      formOpen: false,
      rowData: undefined,
      skeleton: true,
      upload: false,
    };
  }

  initialRequestParams = () => {
    return {
      search: "",
      perPage: 25,
      page: 1,
    };
  };

  componentDidMount() {
    this.fetchTableData();
  }

  flushTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  componentWillUnmount = () => {
    this.flushTimer();
  };
  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  fetchTableData = () => {
    fetchStaffsData(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
          skeleton: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.setState({ ...this.state, skeleton: true });
    this.debounceSearch();
  };

  handleSubmitSuccess = () => {
    this.onCancel();
    setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  onCancel = () => {
    this.setState({
      formOpen: false,
      rowData: undefined,
      upload: false,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.fetchTableData();
  };

  createAction = () => {
    return createAccess("staffs");
  };

  updateAction = () => {
    return updateAccess("staffs");
  };

  uploadAction = () => {
    return uploadAccess("staffs");
  };

  editStaffs = (row) => {
    this.setState({ formOpen: true, rowData: row });
  };

  aboutStaff = (row) => {
    if (row.phone) {
      this.props.history.push({
        pathname: `/staffs/${row.phone}`,
        state: {
          row,
        },
      });
    }
  };

  render() {
    const { data, skeleton, formOpen, rowData, upload } = this.state;
    const {
      onChange,
      handleSubmitSuccess,
      onCancel,
      editStaffs,
      aboutStaff,
    } = this;

    const appBarProps = {
      search: this.requestParams.search,
      // searchOpen: true,
      onChange,
    };
    const tableProps = {
      tabColor: "info",
      title: "Staffs",
      subTitle: "Staffs working in CBR",
      columns: columns(editStaffs, aboutStaff),
      data: data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      onRowClick: () => { },
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      OnClickUpload: () => {
        this.setState({ upload: true });
      },
      skeleton,
      dataValue: "ADD Staffs",
      createAction: this.createAction(),
      dataValueUpload: "Upload",
      uploadAction: this.uploadAction(),
    };
    const CRUDStaffProps = {
      open: formOpen,
      tabColor: "info",
      titleText: "Staffs",
      data: rowData,
      handleSubmitSuccess,
      onCancel,
    };

    const CRUDStaffsUploadProps = {
      open: upload,
      titleText: "Staffs Data",
      onCancel: onCancel,
      handleSubmitSuccess,
      upload: true,
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        {formOpen && <CRUDStaffs {...CRUDStaffProps} />}
        <CRUDUpload {...CRUDStaffsUploadProps} />
      </React.Fragment>
    );
  }
}
export default Staffs;
