import React, { Component } from "react";
import { columns } from "data/roles";
import AppBar from "components/AppBar";
import { fetchRoles } from "api/roles";
import PaginationTable from "components/paginationTables/withAddEdit";
import CRUDVehicle from "components/form/roles/CRUD";
import { Row } from "antd";
import { handleResponse } from "components/notifications/handleResponse";
import { debounce } from "lodash";

class Roles extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      rowData: undefined,
      formOpen: false,
    };
  }

  initialRequestParams = () => {
    return {
      perPage: 25,
      page: 1,
      search: "",
      type: ["UserRole"],
    };
  };

  componentDidMount = () => {
    this.fetchTableData();
  };

  fetchTableData = () => {
    fetchRoles(this.requestParams)
      .then(({ data }) => {
        this.setState({
          data: data,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  handleSubmitSuccess = () => {
    this.handleClose();
    this.timer = setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  handleClose = () => {
    this.setState({
      formOpen: false,
      rowData: undefined,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination, filters) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.requestParams.type = filters.type;
    this.fetchTableData();
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  render() {
    const { data, rowData, formOpen } = this.state;
    const { handleClose, handleSubmitSuccess, onChange } = this;

    const CRUDProps = {
      data: rowData,
      onCancel: handleClose,
      open: formOpen,
      handleSubmitSuccess,
      tabColor: "danger",
      paperSize: "large",
    };

    const tableProps = {
      tabColor: "danger",
      title: "Roles",
      subTitle: "Roles of Users here",
      columns: columns,
      data: data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      onRowClick: (row) => {
        this.setState({ formOpen: true, rowData: row });
      },
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      dataValue: "ADD user roles",
    };

    const appBarProps = {
      search: this.requestParams.search,
      searchOpen: true,
      onChange,
    };
    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        <CRUDVehicle {...CRUDProps} />
      </React.Fragment>
    );
  }
}

export default Roles;
