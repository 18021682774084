import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import Table from "./Table";
import { apiHost } from "config";
import { headers } from "helpers";
import AppBar from "components/AppBar";
import { handleResponse } from "components/notifications/handleResponse";

class Alerts extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: undefined,
      page: 0,
      total_entries: 0,
    };
  }

  componentDidMount = async () => {
    this.autoRefresh();
  };

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  initialRequestParams = () => {
    return {
      search: "",
      perPage: 100,
    };
  };

  autoRefresh = () => {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.fetchTableData();
    this.timer = setTimeout(this.autoRefresh, 20000);
  };

  generateParams() {
    let { requestParams } = this;
    let pages = this.state.page + 1;
    return `&query=${requestParams.search}&page=${pages}&per_page=${requestParams.perPage}`;
  }

  fetchTableData = async () => {
    fetch(`${apiHost}/api/v1/alerts?${this.generateParams()}`, {
      method: "GET",
      headers: headers(),
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(res.status, await res.json());
      })
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
        });
      })
      .catch((err) => {
        handleResponse(err);
      });
  };

  render() {
    const { data, page, total_entries } = this.state;
    const { fetchTableData } = this;
    const tableProps = {
      data,
      total_entries,
      page,
      fetchTableData,
    };

    return (
      <React.Fragment>
        <AppBar />
        <Grid container justify="center" alignItems="center">
          <Table {...tableProps} />
        </Grid>
      </React.Fragment>
    );
  }
}

export default Alerts;
