import React, { Component } from "react";
import { createPermissions, updatePermissions } from "api/permissions";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";
import Form from "./form";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      name: "",
      value: "",
      sub_permissions: [],
    };
  }

  handleChange = (event, index) => {
    const { name, value } = event.target;
    if (index !== undefined) {
      let sub_permissions = [...this.state.sub_permissions];
      sub_permissions[index][name] = value;

      this.setState({ sub_permissions });
    } else {
      this.setState({ [name]: value });
    }
  };

  requestParams = () => {
    let { name, value, sub_permissions } = this.state;

    return {
      permissions: {
        name,
        value,
        sub_permissions,
      },
    };
  };

  onSubmit = () => {
    const { id, requestParams, props } = this;
    props.submitClick();
    if (id) {
      updatePermissions(id, requestParams())
        .then((data) => {
          success(data.message);
          this.props.handleSubmitSuccess(id);
          props.submitClick();
        })
        .catch((error) => {
          props.submitClick();
          handleResponse(error);
        });
    } else {
      createPermissions(requestParams())
        .then((data) => {
          success(data.message);
          this.props.handleSubmitSuccess(id);
          props.submitClick();
        })
        .catch((error) => {
          props.submitClick();
          handleResponse(error);
        });
    }
  };

  handleDelete = (index) => {
    let temp = [...this.state.sub_permissions];
    temp.splice(index, 1);
    this.setState({ sub_permissions: temp });
  };

  componentDidMount = () => {
    if (this.props.data) {
      const { id, name, value, sub_permissions } = this.props.data;
      this.id = id;
      this.setState({
        name,
        value,
        sub_permissions,
      });
    }
  };

  handleAddNew = () => {
    let temp = [...this.state.sub_permissions, { name: "", value: "" }];
    this.setState({ sub_permissions: temp });
  };

  render() {
    const { name, value, sub_permissions } = this.state;
    const { handleChange, handleAddNew, handleDelete } = this;
    const formProps = {
      name,
      value,
      sub_permissions,
      handleChange,
      handleAddNew,
      handleDelete,
    };

    return (
      <React.Fragment>
        <Form {...formProps} />
      </React.Fragment>
    );
  }
}

export default DialogForm("Permission")(CRUD);
