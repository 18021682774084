import makeRequest from ".";

export function reports() {
  return makeRequest({
    uri: `/api/v1/reports/list`,
  });
}

export function submitReports(report_ids) {
  return makeRequest({
    uri: `/api/v1/users/reports`,
    method: "PUT",
    body: JSON.stringify({ report_ids }),
  });
}

export function notifications() {
  return makeRequest({
    uri: `/api/v1/notifications/list`,
  });
}

export function submitNotifications(notification_ids) {
  return makeRequest({
    uri: `/api/v1/users/notifications`,
    method: "PUT",
    body: JSON.stringify({ notification_ids }),
  });
}

export function profile() {
  return makeRequest({
    uri: `/api/v1/users/profile`,
  });
}

export function submitProfile(data) {
  return makeRequest({
    uri: `/api/v1/users/profile`,
    method: "PUT",
    body: JSON.stringify(data),
  });
}

export function password(current_password, new_password) {
  return makeRequest({
    uri: `/api/v1/users/update_password`,
    method: "PUT",
    body: JSON.stringify({ current_password, new_password }),
  });
}
export function userHomepage(home_page) {
  return makeRequest({
    uri: `/api/v1/users/homepage`,
    method: "PUT",
    body: JSON.stringify({ home_page }),
  });
}
