import makeRequest from ".";

function generateParams(params = {}, totalEntries = null) {
  let visitor_types = "";
  let number_plates = "";
  let phone_no = "";
  let vehicle_types = "";
  let alertLink = "";
  const {
    visitor_type,
    phone,
    number_plate,
    vehicle_type,
    other_filter,
  } = params;
  if (visitor_type) {
    visitor_types = visitor_type
      .map((value) => `&visitor_type[]=${value}`)
      .join("");
  }
  if (phone && phone.length > 0) {
    phone_no = phone.map((key) => `&phone[]=${key.label}`).join("");
  }
  if (number_plate && number_plate.length > 0) {
    number_plates = number_plate
      .map((key) => `&number_plate[]=${key.label}`)
      .join("");
  }
  if (vehicle_type && vehicle_type.length > 0) {
    vehicle_types = vehicle_type
      .map((key) => `&vehicle_type[]=${key}`)
      .join("");
  }

  if (other_filter) alertLink = `&alert_linked=${other_filter}`;
  else alertLink = `&alert_linked=${false}`;

  return `from_time=${params.fromTime}&to_time=${params.toTime
    }${visitor_types}${phone_no}${number_plates}${vehicle_types}${alertLink}&query=${params.search
    }&page=${params.page || 1}&per_page=${totalEntries || params.perPage}`;
}

export function fetchListingData(requestParams) {
  return makeRequest({
    uri: `/api/v1/visits?${generateParams(requestParams)}`,
  });
}

export function aggregation(params) {
  return makeRequest({
    uri: `/api/v1/visits/aggs?from_time=${params.fromTime}&to_time=${params.toTime}&visitor_type[]=${params.purpose}`,
  });
}

export function triggerReportDownload(requestParams, totalEntries) {
  return makeRequest({
    uri: `/api/v1/visits/generate_report?${generateParams(
      requestParams,
      totalEntries
    )}`,
  });
}
