import React, { Component } from "react";
import moment from "moment";
import { columns } from "data/complaints";
import AppBar from "components/AppBar";
import { fetchComplaints, updateComplaint } from "api/complaints";
import PaginationTable from "components/paginationTables/withAddEdit";
import { handleResponse } from "components/notifications/handleResponse";
import { debounce } from "helpers";

class Announcements extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      formOpen: false,
      skeleton: true,
      rowData: undefined,
      total_entries: 0,
    };
  }

  initialRequestParams = () => {
    return {
      perPage: 25,
      page: 1,
      search: "",
      status: "",
      fromTime: moment
        .utc(
          moment()
            .subtract(7, "days")
            .startOf("day")
        )
        .format(),
      toTime: moment.utc(moment().endOf("day")).format(),
    };
  };

  componentDidMount = () => {
    this.fetchTableData();
  };

  fetchTableData = async () => {
    await fetchComplaints(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({ data, total_entries: total_entries });
      })
      .catch((error) => {
        handleResponse(error);
      });
    this.setState({ skeleton: false });
  };

  handleSubmitSuccess = () => {
    this.handleClose();
    setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  handleClose = () => {
    this.setState({
      formOpen: false,
      rowData: undefined,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };
  handleTableChange = (pagination) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.fetchTableData();
  };

  onDateChange = (from_time, to_time) => {
    this.requestParams.fromTime = from_time;
    this.requestParams.toTime = to_time;
    this.fetchTableData();
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  handleChange = (status, id) => {
    this.requestParams.status = status;
    if (id) {
      updateComplaint(this.requestParams.status, id);
    }
    setTimeout(() => {
      this.fetchTableData();
    }, 10000);
  };

  render() {
    const {
      state: { data, skeleton },
      onChange,
      handleChange,
      handleTableChange,
      onDateChange,
    } = this;
    const appBarProps = {
      fromTime: moment(this.requestParams.fromTime),
      toTime: moment(this.requestParams.toTime),
      search: this.requestParams.search,
      dateFilter: true,
      searchOpen: true,
      onChange,
      onDateChange,
      shortcut: {
        "This Week": {
          start: moment().startOf("week"),
          end: moment().endOf("week"),
        },
        "Last Week": {
          start: moment()
            .subtract(1, "weeks")
            .startOf("week"),
          end: moment()
            .subtract(1, "weeks")
            .endOf("week"),
        },
        "This Month": {
          start: moment().startOf("month"),
          end: moment().endOf("month"),
        },
      },
    };

    const tableProps = {
      tabColor: "info",
      title: "Complaints",
      subTitle: "Resident's complaints are here",
      columns: columns(handleChange),
      data: data,
      pagination: this.pagination(),
      handleTableChange,
      // handleOnClick: () => {
      //   this.setState({ formOpen: true });
      // },
      onRowClick: () => {},
      skeleton,
    };
    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <PaginationTable {...tableProps} />
        {/* {formOpen && <CRUDAnnouncements {...CRUDProps} />} */}
      </React.Fragment>
    );
  }
}
export default Announcements;
