import React from "react";
import { Row, Col } from "antd";
import SimpleTextInput from "components/inputs/simpleTextInput";
import RadioButton from "components/inputs/radioButton.jsx";
import { scheduledTime } from "config";
import Singleselect from "components/inputs/Singleselect";

export default function Form(props) {
  const {
    handleChange,
    name,
    status,
    exec,
    schedule_time,
    health_check_url,
  } = props;
  const statusProps = {
    labelText: "Status",
    value: status,
    data: { Active: "active", Inactive: "inactive" },
    onChange: handleChange,
    name: "status",
    color: true,
  };
  return (
    <Row>
      <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
        <SimpleTextInput
          labelText="Name"
          name="name"
          required
          value={name}
          onChange={handleChange}
          readOnly
        />
      </Col>
      <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
        <SimpleTextInput
          labelText="Execute"
          name="exec"
          value={exec}
          readOnly
        />
      </Col>
      <Col
        xs={24}
        sm={12}
        md={12}
        style={{ padding: "20px 10px", width: "250px" }}
      >
        <RadioButton {...statusProps} />
      </Col>
      <Col
        xs={24}
        sm={12}
        md={12}
        style={{ padding: "20px 10px", textAlign: "center", width: "220px" }}
      >
        <Singleselect
          required
          heading="Scheduled Time"
          options={scheduledTime}
          selected={schedule_time}
          name="schedule_time"
          handleChange={handleChange}
        />
      </Col>
      <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
        <SimpleTextInput
          labelText="Health Check URL"
          name="health_check_url"
          value={health_check_url}
          onChange={handleChange}
          required={status === "active" ? true : false}
        />
      </Col>
    </Row>
  );
}
