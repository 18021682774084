import makeRequest from ".";

function generateParams(params = {}) {
  return `query=${params.search}&page=${params.page || 1}&per_page=${
    params.perPage
  }`;
}

export function fetchPeopleData(requestParams, peopleType) {
  return makeRequest({
    uri: `/api/v1/${peopleType}?${generateParams(requestParams)}`,
  });
}

export function createPeople(requestBody, peopleType) {
  return makeRequest({
    uri: `/api/v1/${peopleType}`,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updatePeople(requestBody, peopleType, id) {
  return makeRequest({
    uri: `/api/v1/${peopleType}/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

export function deletePeople(peopleType, id) {
  return makeRequest({
    uri: `/api/v1/${peopleType}/${id}`,
    method: "DELETE",
  });
}
