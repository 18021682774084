import makeRequest from ".";

function generateParams(params = {}, totalEntries = null) {
  let mediums = "";
  let statuses = "";
  let time = "";
  let recipients = "";

  if (params.selectedMedium) {
    mediums = params.selectedMedium
      .map((value) => `&medium[]=${value}`)
      .join("");
  }

  if (params.selectedStatus) {
    statuses = params.selectedStatus
      .map((value) => `&status[]=${value}`)
      .join("");
  }

  // if (params.recipient_type) {
  //   recipient_types = params.recipient_type
  //     .map(value => `&recipient_type[]=${value}`)
  //     .join("");
  // }

  if (params.selectedRecipient) {
    recipients = params.selectedRecipient
      .map((value) => `&recipient_full_name[]=${value}`)
      .join("");
  }
  if (params.fromTime || params.toTime) {
    time = `from_time=${params.fromTime || ""}&to_time=${params.toTime || ""}`;
  }
  return `${time}${mediums}${statuses}${recipients}&query=${
    params.search
  }&page=${params.page || 1}&per_page=${totalEntries || params.perPage || 25}`;
}

export function fetchListingData(requestParams) {
  return makeRequest({
    uri: `/api/v1/sent_alerts?${generateParams(requestParams)}`,
  });
}

export function fetchIVRLogs(requestParams) {
  return makeRequest({
    uri: `/api/v1/sent_alerts/ivrs?${generateParams(requestParams)}`,
  });
}

export function showAlerts(id) {
  return makeRequest({ uri: `/api/v1/sent_alerts/${id}` });
}
