import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import navPillsStyle from "./pillsStyle.jsx";
import { VehicleEntriesContext } from "context/ContextProvider";
import { Row, Col } from "antd";

class NavPills extends React.Component {
  render() {
    const {
      classes,
      tabs,
      direction,
      color,
      horizontal,
      alignCenter,
    } = this.props;
    const flexContainerClasses = classNames({
      [classes.flexContainer]: true,
      [classes.horizontalDisplay]: horizontal !== undefined,
    });

    const tabButtons = (
      <VehicleEntriesContext.Consumer>
        {({ handleClick }) =>
          tabs.length ? (
            <Tabs
              classes={{
                root: classes.root,
                fixed: classes.fixed,
                flexContainer: flexContainerClasses,
                indicator: classes.displayNone,
              }}
              value={this.props.active}
              onChange={(event, active) => {
                handleClick(event, active);
              }}
              centered={alignCenter}
            >
              {tabs.map((prop, key) => {
                var icon = {};
                if (prop.tabIcon !== undefined && this.props.active === key) {
                  icon["icon"] = <prop.tabIcon className={classes.tabIcon} />;
                }
                const pillsClasses = classNames({
                  [classes.pills]: true,
                  [classes.horizontalPills]: horizontal !== undefined,
                  [classes.pillsWithIcons]: prop.tabIcon !== undefined,
                });
                return (
                  <Tab
                    label={prop.tabButton}
                    key={key}
                    {...icon}
                    classes={{
                      root: [pillsClasses],
                      // labelContainer: classes.labelContainer,
                      // label: classes.label,
                      selected: classes[color],
                    }}
                  />
                );
              })}
            </Tabs>
          ) : (
            <p style={{ fontSize: "18px", textAlign: "center", margin: "10%" }}>
              No Previous Entry
            </p>
          )
        }
      </VehicleEntriesContext.Consumer>
    );
    const tabContent = (
      <div className={classes.contentWrapper}>
        <SwipeableViews
          axis={direction === "rtl" ? "x-reverse" : "x"}
          index={this.props.active}
        >
          {tabs.length ? (
            tabs.map((prop, key) => {
              return (
                <div
                  style={{ padding: "0px 10px" }}
                  className={classes.tabContent}
                  key={key}
                >
                  {prop.tabContent}
                </div>
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20%",
              }}
            >
              <p style={{ fontSize: "25px", fontWeight: 600 }}>
                No Previous Entry
              </p>
            </div>
          )}
        </SwipeableViews>
      </div>
    );
    return horizontal !== undefined ? (
      <Row>
        <Col {...horizontal.tabsCol}>{tabButtons}</Col>
        <Col {...horizontal.contentCol}>{tabContent}</Col>
      </Row>
    ) : (
      <div>
        {tabButtons}
        {tabContent}
      </div>
    );
  }
}

NavPills.defaultProps = {
  color: "primary",
};

NavPills.propTypes = {
  classes: PropTypes.object.isRequired,
  active: PropTypes.number,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabButton: PropTypes.string,
      tabIcon: PropTypes.func,
      tabContent: PropTypes.node,
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
  ]),
  direction: PropTypes.string,
  horizontal: PropTypes.shape({
    tabsCol: PropTypes.object,
    contentCol: PropTypes.object,
  }),
  alignCenter: PropTypes.bool,
};

export default withStyles(navPillsStyle)(NavPills);
