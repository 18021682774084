import React, { Component } from "react";
import { Paper } from "@material-ui/core";
import { Table, Checkbox, Button } from "antd";
import { presence } from "helpers";
import TableHeader from "./tableHeader";
class TableExpand extends Component {
  expand = () => {
    const {
      rolesData,
      subPermissionsData,
      permissionId,
      handleCheckBoxChange,
    } = this.props;
    let data =
      (rolesData &&
        rolesData.map((data) => ({
          title: data.name,
          key: data.name,
          align: "center",
          render: (row) => {
            let subPermissionId = Object.keys(row)[0];
            let roleIds = Object.values(row)[0];
            return (
              <Checkbox
                checked={roleIds.indexOf(data.id) > -1}
                onChange={() =>
                  handleCheckBoxChange(subPermissionId, data.id, permissionId)
                }
              />
            );
          },
        }))) ||
      [];
    data.unshift({
      title: "Name",
      key: "name",
      align: "center",
      render: (row) => {
        let subPermissionId = Object.keys(row)[0];
        let row_value = subPermissionsData.find(
          (data) => data.id === subPermissionId
        );
        return <React.Fragment>{row_value && row_value.name}</React.Fragment>;
      },
    });
    return data;
  };

  render() {
    const data = this.props.data || [];
    return (
      <Table
        rowKey={(record) => record.id}
        columns={this.expand()}
        dataSource={data}
        pagination={false}
      />
    );
  }
}

class PermissionTable extends Component {
  columns = () => {
    const {
      openEditForm,
      updateRoles,
      rolesSubPermissions,
      updateAction,
    } = this.props;
    return [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        align: "center",
      },
      {
        title: "Value",
        dataIndex: "value",
        render: (value) => `${presence(value)}`,
        align: "center",
      },
      {
        title: "Sub Permissions",
        dataIndex: "sub_permissions",
        render: (sub_permissions) => `${sub_permissions.length}`,
        align: "center",
      },
      {
        title: "Actions",
        render: (_, row) => {
          return (
            <React.Fragment>
              <Button
                onClick={() => openEditForm(row)}
                style={{
                  margin: "0px 10px 5px 10px",
                }}
                disabled={!updateAction}
              >
                Edit
              </Button>
              <Button
                onClick={() => updateRoles(row.id)}
                style={{
                  margin: "5px 10px 0px 10px",
                }}
                disabled={
                  Object.values(rolesSubPermissions)?.length && !updateAction
                }
              >
                Update Roles
              </Button>
            </React.Fragment>
          );
        },
        align: "center",
      },
    ];
  };

  expandedRowRender = (row) => {
    const { rolesSubPermissions, rolesData, handleCheckBoxChange } = this.props;
    if (!rolesSubPermissions[row.id]) {
      this.props.fetchRolesPermission(row.id);
    }

    return (
      <TableExpand
        data={rolesSubPermissions[row.id]}
        rolesData={rolesData}
        subPermissionsData={row.sub_permissions}
        handleCheckBoxChange={handleCheckBoxChange}
        permissionId={row.id}
      />
    );
  };

  render() {
    const { expandedRowRender, columns, props } = this;
    const {
      permissionsData,
      handleOnClick,
      tabColor,
      title,
      subTitle,
      dataValue,
      createAction,
    } = props;
    const headerprops = {
      tabColor,
      title,
      subTitle,
      handleOnClick,
      dataValue,
      createAction,
    };

    return (
      <Paper className="paperClass">
        <TableHeader {...headerprops} />
        <div
          style={{
            overflowX: "auto",
            width: "100%",
          }}
        >
          <Table
            rowKey={(record) => record.id}
            className="components-table-demo-nested"
            columns={columns()}
            expandedRowRender={expandedRowRender}
            dataSource={permissionsData}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} results`,
              pageSize: 50,
            }}
          />
        </div>
      </Paper>
    );
  }
}
export default PermissionTable;
