import makeRequest from ".";

function generateParams(params = {}, totalEntries = null) {
  return `query=${params.search || ""}&page=${params.page ||
    1}&per_page=${totalEntries || params.perPage || 25}`;
}

export function fetchAlertScreen(requestParams) {
  return makeRequest({
    uri: `/api/v1/presentations?${generateParams(requestParams)}`,
  });
}

export function updateAlertScreen(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/presentations/${id}`,
    body: JSON.stringify(requestBody),
    method: "PUT",
  });
}

export function fetchAlerts() {
  return makeRequest({
    uri: `/api/v1/alert_types`,
  });
}
