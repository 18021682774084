import React from "react";
import { titleize, keyByValue } from "helpers";
import { allNotificationTypes, allNotificationstatus } from "config";
import { StatusComp } from "data/index";
import { Tooltip } from "antd";

const UserTiles = ({ users_json }) => {
  return (
    <React.Fragment>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {users_json &&
          users_json.map((user, i) => {
            const subscribed = Object.keys(user).filter(
              (key) => user[key] === true
            );
            const bgColors = {
              gray: [],
              "#2196f3": ["sms", "whatsapp", "mail"],
              "#673ab7": ["whatsapp", "mail"],
              "#e91e63": ["sms", "mail"],
              "#0000FF": ["sms", "whatsapp"],
              "#000080": ["sms"],
              "#800000": ["whatsapp"],
              "#808080": ["mail"],
            };
            const notificationTypes = Object.values(bgColors);
            const socialMediaEntries = notificationTypes.entries();
            let color;

            for (let i = 0; i < notificationTypes.length; i++) {
              const iteration = socialMediaEntries.next().value;
              if (
                subscribed.every((socialMedia) =>
                  iteration[1].includes(socialMedia)
                ) &&
                subscribed.length === iteration[1].length
              ) {
                color = Object.keys(bgColors)[iteration[0]];
                break;
              }
            }
            return (
              <span
                key={i}
                style={{
                  color: "#fff",
                  backgroundColor: color,
                  padding: "5px 15px 5px",
                  borderRadius: 50,
                  marginLeft: 5,
                  whiteSpace: "nowrap",
                  marginTop: "4px",
                  marginBottom: "4px",
                }}
              >
                <Tooltip title={subscribed.join(" , ")}>
                  {" "}
                  {user.first_name}
                </Tooltip>
              </span>
            );
          })}
      </div>
    </React.Fragment>
  );
};

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    align: "center",
    render: (name) => `${titleize(name)}`,
  },

  {
    title: "Notification Type",
    dataIndex: "notification_type",
    render: (notification_type) =>
      `${titleize(
        allNotificationTypes &&
          keyByValue(allNotificationTypes, notification_type)
      )}`,
    align: "center",
    filters:
      allNotificationTypes &&
      Object.keys(allNotificationTypes).map((key) => ({
        text: titleize(key),
        value: allNotificationTypes[key],
      })),
  },
  {
    title: "Users",
    dataIndex: "users_json",
    render: (users_json) => <UserTiles users_json={users_json} />,
    align: "center",
    width: "30%",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    render: (status) => <StatusComp value={titleize(status)} />,
    filters:
      allNotificationstatus &&
      Object.values(allNotificationstatus).map((status) => {
        return { text: titleize(status), value: status };
      }),
  },
];
