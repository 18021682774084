import React from "react";
import SimpleTextInput from "components/inputs/simpleTextInput";
import { Row, Col } from "antd";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import { MdDelete, MdAdd } from "react-icons/md";

function Form(props) {
  const {
    name,
    value,
    sub_permissions,
    handleChange,
    handleAddNew,
    handleDelete,
  } = props;
  return (
    <React.Fragment>
      <Row>
        <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="Name"
            name="name"
            required
            value={name}
            onChange={handleChange}
            type="text"
          />
        </Col>
        <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="Value"
            name="value"
            required
            value={value}
            onChange={handleChange}
            type="text"
          />
        </Col>
      </Row>
      <Row>
        <Col
          sm={24}
          style={{
            flexDirection: "row",
            display: "flex",
            padding: "15px 0px",
          }}
        >
          <h2 style={{ margin: "10px" }}>Sub Permissions</h2>
          <Fab
            color="primary"
            aria-label="add"
            style={{ marginLeft: 8 }}
            onClick={() => {
              handleAddNew();
            }}
          >
            <MdAdd style={{ fontSize: "25px" }} />
          </Fab>
        </Col>
      </Row>
      <Row>
        <Row
          style={{
            padding: 1,
            maxHeight: 250,
            overflowY: "auto",
            overflowX: "hidden",
            width: 560,
          }}
        >
          {sub_permissions &&
            sub_permissions.map((sub_permission, i) => (
              <React.Fragment key={i}>
                <Col xs={24} sm={11} md={11} style={{ padding: "0px 10px" }}>
                  <SimpleTextInput
                    labelText="Name"
                    name="name"
                    required
                    value={sub_permission.name}
                    type="text"
                    onChange={(e) => handleChange(e, i)}
                  />
                </Col>
                <Col xs={20} sm={11} md={11} style={{ padding: "0px 10px" }}>
                  <SimpleTextInput
                    labelText="Value"
                    name="value"
                    required
                    value={sub_permission.value}
                    type="text"
                    onChange={(e) => handleChange(e, i)}
                  />
                </Col>
                <Col xs={4} sm={2} md={2} style={{ marginTop: 11 }}>
                  <IconButton
                    onClick={() => handleDelete(i)}
                    aria-label="Delete"
                  >
                    <MdDelete />
                  </IconButton>
                </Col>
              </React.Fragment>
            ))}
        </Row>
      </Row>
    </React.Fragment>
  );
}
export default Form;
