import React from "react";
import { Table } from "antd";
import {
  Card,
  CardHeader,
  CardBody,
  CardIcon,
} from "material-dashboard-react/components/index";
import { MdUpdate } from "react-icons/md";
import { withStyles } from "@material-ui/core/styles";

const style = () => ({
  tableWrapper: {
    height: 320,
    overflowY: "auto",
  },
});

function PaginationTable(props) {
  const {
    tabColor,
    title,
    icon,
    columns,
    data,
    onRowClick,
    color,
    titleColor,
    type,
    classes,
    subtitle,
  } = props;
  return (
    <Card style={{ height: "430px", marginBottom: "5px" }}>
      <CardHeader color={tabColor} stats icon>
        <CardIcon color={color}>{icon}</CardIcon>
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p
              style={{
                textAlign: "left",
                color: titleColor,
                fontSize: "16px",
                marginBottom: "0px",
                marginTop: "10px",
              }}
            >
              {title}
            </p>
            <span
              style={{
                fontSize: "13px",
                color: titleColor,
                margin: "12px 0px 0px 7px",
              }}
            >
              {type}
            </span>
          </div>
          <p
            style={{
              color: " #999999",
              fontSize: "12px",
              textAlign: "start",
              marginTop: "5px",
            }}
          >
            <MdUpdate
              style={{
                color: " #999999",
                height: "18px",
                margin: " 0px -2px -4px 0px",
              }}
            />
            {subtitle}
          </p>
        </div>
      </CardHeader>
      <CardBody style={{ paddingTop: "0px" }}>
        <div className={classes.tableWrapper}>
          <Table
            columns={columns}
            pagination={false}
            onRow={(record) => {
              return {
                onClick: () => {
                  onRowClick(record);
                },
              };
            }}
            rowKey={(record) => record.id}
            dataSource={data}
          />
        </div>
      </CardBody>
    </Card>
  );
}

export default withStyles(style)(PaginationTable);
