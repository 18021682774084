import React from "react";
import moment from "moment/moment";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import { CardHeader } from "material-dashboard-react/components";
import { MdArrowUpward, MdArrowDownward } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: theme.spacing(1),
    marginTop: 50,
    position: "relative",
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: "auto",
  },
  margin: {
    margin: `0px ${theme.spacing(1)}px`,
  },
  paper: {
    position: "absolute",
    top: 55,
    left: "10%",
    width: "80%",
  },
  listRoot: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  listItemIcon: {
    minWidth: 0,
  },
}));

const MyTable = (props) => {
  const { data: rows, page: initialPage, total_entries } = props;
  const classes = useStyles();
  const [page, setPage] = React.useState(initialPage);
  const noData = total_entries === 0;

  React.useEffect(() => {
    if (initialPage === 0) {
      if (!(page === initialPage)) {
        setPage(initialPage);
      }
    }
  }, [page, initialPage]);

  const renderTrend = (current, avg) => {
    let percent = ((current / avg) * 100 - 100).toFixed(2);
    let color = "green",
      icon = <MdArrowDownward style={{ height: "14px", margin: -2 }} />;
    if (percent > 0) {
      color = "red";
      icon = <MdArrowUpward style={{ height: "14px", margin: -2 }} />;
    }
    return (
      <span align="center" style={{ color, fontSize: "10px", paddingLeft: 10 }}>
        {icon}
        {" " + percent + "%"}
      </span>
    );
  };

  return (
    <Paper className={classes.root}>
      <CardHeader color="primary">
        <h3
          style={{
            margin: "0px 0px 0px 10px",
            flexGrow: 1,
            fontWeight: 300,
            color: "#fff",
          }}
        >
          Alerts
        </h3>
        <h4
          style={{
            margin: "0px 0px 0px 10px",
            flexGrow: 1,
            fontWeight: 300,
            color: "#fff",
          }}
        >
          Alerts
        </h4>
      </CardHeader>
      <div className={classes.tableWrapper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="center">Meter</TableCell>
              <TableCell align="center">Avg Consumption</TableCell>
              <TableCell align="center">Consumption</TableCell>
              {/* <TableCell align="center">Calculation</TableCell> */}
              <TableCell align="center">From Time</TableCell>
              <TableCell align="center">To Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="center">
                    {row.water_meter ? row.water_meter.name : "-"}
                  </TableCell>
                  <TableCell align="center">{row.avg_consumption}</TableCell>
                  <TableCell align="center">
                    {row.current_consumption}
                    {renderTrend(row.current_consumption, row.avg_consumption)}
                  </TableCell>
                  <TableCell align="center">
                    {row.from_time
                      ? moment(row.from_time).format("DD-MM-YYYY HH:mm")
                      : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {row.to_time
                      ? moment(row.to_time).format("DD-MM-YYYY HH:mm")
                      : "-"}
                  </TableCell>
                </TableRow>
              ))}

            {noData && (
              <TableRow style={{ height: 250 }}>
                <TableCell align="center" colSpan={5}>
                  No Data To Display
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default MyTable;
