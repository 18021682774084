import React, { useState } from "react";
import { Modal, Button } from "antd";
import { exportToJson } from "helpers";
import ReactJson from "react-json-view";
import PropTypes from "prop-types";

const ViewJSON = (props) => {
  const { title, data, filename } = props;
  const [open, setOpen] = useState(false);
  return (
    <React.Fragment>
      <Modal
        width={1000}
        title={title}
        visible={open}
        onCancel={() => {
          setOpen(false);
        }}
        footer={
          <React.Fragment>
            <Button
              type="primary"
              icon="download"
              size={"large"}
              onClick={() => exportToJson(data, filename)}
            >
              Download
            </Button>
            <Button
              type="primary"
              size={"large"}
              onClick={() => setOpen(false)}
            >
              OK
            </Button>
          </React.Fragment>
        }
      >
        <ReactJson
          src={data}
          displayObjectSize={false}
          displayDataTypes={false}
        />
      </Modal>
      <Button style={{ marginRight: 10 }} onClick={() => setOpen(true)}>
        View
      </Button>
    </React.Fragment>
  );
};

ViewJSON.defaultProps = {
  title: "JSON",
  data: {},
  filename: "export",
};

ViewJSON.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  filename: PropTypes.string.isRequired,
};

export default ViewJSON;
