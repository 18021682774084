import makeRequest from ".";

function generateParams(params = {}, totalEntries = null) {
  let statuses = "";
  let camera = "";
  let number_plates = "";
  let vehicle_types = "";
  let visitLinked = "";

  const { status, cameras, number_plate, vehicle_type, other_filter } = params;
  if (status && status.length > 0) {
    statuses = status.map((value) => {
      if (value.value === "In") {
        let inStatuses = ["In"];
        return inStatuses.map((val) => `&status[]=${val}`).join("");
      } else if (value.value === "Out") {
        let outStatuses = ["Out", "Direct Out", "Partially Out", "Neglected"];
        return outStatuses.map((val) => `&status[]=${val}`).join("");
      } else {
        return `&status[]=${value.value}`;
      }
    });
  }
  if (cameras && cameras.length > 0) {
    camera = cameras.map((value) => `&cameras[]=${value.value}`).join("");
  }
  if (number_plate && number_plate.length > 0) {
    number_plates = number_plate
      .map((key) => `&number_plate[]=${key.label}`)
      .join("");
  }
  if (vehicle_type && vehicle_type.length > 0) {
    vehicle_types = vehicle_type
      .map((key) => `&vehicle_type[]=${key}`)
      .join("");
  }

  if (other_filter) {
    visitLinked = `&visit_linked=true`;
  }

  return `from_time=${params.fromTime}&to_time=${
    params.toTime
  }${statuses}${camera}${number_plates}${vehicle_types}${visitLinked}&query=${
    params.search
  }&page=${params.page || 1}&per_page=${totalEntries || params.perPage}`;
}

export function fetchListingData(requestParams) {
  return makeRequest({
    uri: `/api/v1/vehicle_entries?${generateParams(requestParams)}`,
  });
}

export function aggregation(params) {
  return makeRequest({
    uri: `/api/v1/vehicle_entries/aggs?from_time=${params.fromTime}&to_time=${params.toTime}`,
  });
}

export function triggerReportDownload(requestParams, totalEntries) {
  return makeRequest({
    uri: `/api/v1/vehicle_entries/generate_report?${generateParams(
      requestParams,
      totalEntries
    )}`,
  });
}

export function fetchAboutVehicleEntries(id) {
  return makeRequest({ uri: `/api/v1/vehicle_entries/${id}/about` });
}
