import React from "react";
import { presence, titleize } from "helpers";
import ColIcons from "components/ColIcons";
import alert from "assets/img/alert.svg";
import camera from "assets/img/casino-cctv.svg";
import forbidden from "assets/img/forbidden.svg";
import bike from "assets/img/bike.svg";
import { DurationComp } from "data/index";

const columns = () => ({
  icon: {
    align: "center",
    render: (row) => <IconComp row={row} />,
  },
  number_plate: {
    title: "Number Plate",
    dataIndex: "number_plate",
    render: (number_plate) => `${presence(number_plate)}`,
    align: "center",
  },
  visitor_name: {
    title: "Name",
    align: "center",
    render: (_, row) => `${presence(row?.visitor?.name && row.visitor.name)}`,
  },
  visitor_phone: {
    title: "Phone",
    align: "center",
    render: (_, row) => `${presence(row?.visitor?.phone && row.visitor.phone)}`,
  },
  company_name:{
    title: "Comapny",
    align: "center",
    render: (_, row) => `${presence(row?.company_name)}`
  },
  employer: {
    title: "Employer",
    align: "center",
    render: (_, row) => {
      return <p>{presence(row?.visitor?.employer && row.visitor.employer)}</p>;
    },
  },
    temperature: {
      title: "Temperature",
      align: "center",
      render: (_, row) => `${presence(row?.visitor?.temperature?.toString())}`,
    },
  duration: {
    title: "Duration",
    render: (row) => <DurationComp row={row} />,
    align: "center",
  },
  employee_name: {
    title: "Name",
    align: "center",

    render: (_, row) => {
      return <p>{presence(row?.visitor?.name)}</p>;
    },
  },
  employee_phone: {
    title: "Phone",
    align: "center",

    render: (_, row) => {
      return <p>{presence(row?.visitor?.phone)}</p>;
    },
  },

  staff_name: {
    title: "Staff Name",
    align: "center",
    render: (row) => {
      if (row?.staff?.name) {
        return (
          <>
            <p style={{ margin: 0, fontWeight: 600 }}>
              {titleize(row.staff.name)}
            </p>
          </>
        );
      } else {
        return <p>-</p>;
      }
    },
  },
  visitor_type: {
    title: "Type",
    align: "center",
    render: (row) => `${presence(row.sub_visitor_type || row.visitor_type)}`,
    // filters: [
    //   { text: "Visitor", value: "visitor" },
    //   { text: "Contractor", value: "contractor" },
    //   { text: "Delivery", value: "delivery" },
    //   { text: "Pickup/drop", value: "pickup/drop" }
    // ],
  },
});

function filteredHead({ fields, ...kwargs }) {
  let result = columns({ ...kwargs });
  return fields.map((field) => result[field]);
}

export const VisitorEntries = {
  tabColor: "primary",
  title: "Visitor",
  subTitle: "Entry and exit of people can be monitored here",
  // enumerable: ["guest", "delivery", "pickup/drop", "contractor"],
  enumerable: ["Guest"],
  // filters: [
  //   { text: "Visitor", value: "visitor" },
  //   { text: "Contractor", value: "contractor" },
  //   { text: "Delivery", value: "delivery" },
  //   { text: "Pickup/drop", value: "pickup/drop" }
  // ],
  columns: filteredHead({
    fields: [
      "icon",
      "visitor_name",
      "visitor_phone",
      "company_name",
      "temperature",
      "duration",
    ],
  }),
};

export const ContractorEntries = {
  tabColor: "success",
  title: "Contractor",
  subTitle: "Entry and exit of people can be monitored here",
  filters: [
    { text: "Air Con Services", value: "Air Con Services" },
    { text: "Electrical", value: "Electrical" },
    { text: "Plumbing", value: "Plumbing" },
    { text: "Maintenance", value: "Maintenance" },
    { text: "Others", value: "Others" },
  ],
  enumerable: ["Contractor"],
  columns: filteredHead({
    fields: [
      "icon",
      "visitor_name",
      "visitor_phone",
      "company_name",
      "temperature",
      "duration",
    ],
  }),
};

export const DeliveryEntries = {
  tabColor: "danger",
  title: "Delivery",
  subTitle: "Entry and exit of people can be monitored here",
  enumerable: ["Delivery"],
  columns: filteredHead({
    fields: [
      "icon",
      "visitor_name",
      "visitor_phone",
      "company_name",
      "temperature",
      "duration",
    ],
  }),
};

export const EmployeeEntries = {
  tabColor: "warning",
  title: "Employees",
  subTitle: "Entry and exit of people can be monitored here",
  enumerable: ["Employee"],
  columns: filteredHead({
    fields: [
      "icon",
      "visitor_name",
      "visitor_phone",
      "department",
      "duration",
      "temperature",
    ],
  }),
};

export const PickupEntries = {
  tabColor: "warning",
  title: "Pickup/Drop-off",
  subTitle: "Entry and exit of people can be monitored here",
  enumerable: ["pickup/drop"],
  columns: filteredHead({
    fields: ["icon", "number_plate", "duration", "visitor_type", "temperature"],
  }),
};

const IconComp = ({ row: { vehicle_entry, alerts, vehicle_type } }) => {
  let blacklist_alert =
    alerts && alerts.filter((o) => o.alert_type === "blacklist").length;
  let non_blacklist_alert =
    alerts && alerts.filter((o) => o.alert_type !== "blacklist").length;

  return (
    <React.Fragment>
      {vehicle_entry &&
        vehicle_entry.vehicle_trackings &&
        vehicle_entry.vehicle_trackings.length !== 0 && (
          <div>
            <ColIcons
              icon_path={camera}
              count={vehicle_entry.vehicle_trackings.length}
            />{" "}
            &nbsp;
          </div>
        )}
      {vehicle_type === "Motor Bike" && (
        <div>
          <ColIcons icon_path={bike} width="25px" height="25px" /> &nbsp;
        </div>
      )}
      {!!blacklist_alert && (
        <div>
          <ColIcons icon_path={forbidden} count={blacklist_alert} />
          &nbsp;
        </div>
      )}

      {!!non_blacklist_alert && (
        <ColIcons icon_path={alert} count={non_blacklist_alert} />
      )}
    </React.Fragment>
  );
};
