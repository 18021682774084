import makeRequest from ".";

function generateParams({ page = 1, perPage = 25, totalEntries = null } = {}) {
  return `query=${""}&page=${page}&per_page=${totalEntries || perPage}`;
}

export function fetchSchedulers(requestParams) {
  return makeRequest({
    uri: `/api/v1/schedulers?${generateParams(requestParams)}`,
  });
}

export function updateSchedulers(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/schedulers/${id}`,
    body: JSON.stringify(requestBody),
    method: "PUT",
  });
}
