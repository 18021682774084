import React from "react";
import c3 from "c3";
import "c3/c3.css";
import { lightBlue } from "@material-ui/core/colors";
import { Card, CardHeader } from "material-dashboard-react/components";
import { AppBarContext } from "context/ContextProvider";
import Grid from "@material-ui/core/Grid";
import { headers } from "helpers";

class SmallMeter extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.chart = null;
    this.apiData = null;
    this.state = {
      menuOpen: false,
    };
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.chart = null;
  };

  generateChart = () => {
    this.chart = c3.generate({
      bindto: "#" + this.props.id,
      data: {
        headers: headers(),
        xFormat: "%Y-%m-%d %H:%M:%S%Z",
        json: [],
        types: {
          avg: "spline",
          current: "area-spline",
        },
        keys: {
          x: "datetime",
          value: ["current", "avg"],
        },
      },
      axis: {
        x: {
          padding: { left: 0, right: 0 },
          type: "timeseries",
          tick: {
            fit: true,
            format: "%H:%M",
          },
        },
        y: {
          min: 0,
          padding: { top: 10, bottom: 0 },
        },
      },
      size: { height: 250 },
    });
  };

  reloadChart = () => {
    fetch(`${this.props.url}`, {
      method: "GET",
      headers: headers(),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
      })
      .then((data) => {
        this.apiData = data;
      });

    this.chart.load({
      xFormat: "%Y-%m-%d %H:%M:%S%Z",
      json: this.apiData,
      mimeType: "json",
      names: {
        avg: "Avg Consumption",
        current: "Current Consumption",
      },
      types: {
        avg: "spline",
        current: "area-spline",
      },
      keys: {
        x: "datetime",
        value: ["current", "avg"],
      },
    });
  };

  autoRefresh = () => {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.reloadChart();
    this.timer = setTimeout(this.autoRefresh, 1000 * 60 * 5);
  };

  componentDidMount = () => {
    this.generateChart();
    this.autoRefresh();
  };

  render() {
    return (
      <Grid item xs={12} sm={12} md={4} style={{ padding: "0 8px" }}>
        <Card style={{ flexBasis: "33%" }}>
          <CardHeader
            style={{
              marginTop: " -20px",
              marginRight: " 20px",
              marginLeft: "20px",
              borderRadius: "3px",
              boxShadow: "rgb(80, 146, 223) -1px 7px 8px -5px",
              background: `linear-gradient(60deg, ${lightBlue[700]}, ${lightBlue[400]})`,
            }}
          >
            <h4
              style={{
                margin: "0px 0px 3px",
                color: "white",
                fontWeight: "300",
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
              }}
            >
              Small Meter
            </h4>
            <p
              style={{
                color: "rgba(255,255,255,.62)",
                fontWeight: "300",
                margin: "initial",
              }}
            >
              {this.props.subTitle}
            </p>
          </CardHeader>
          <AppBarContext.Consumer>
            {({ state: { menuOpen } }) => {
              if (menuOpen !== this.state.menuOpen) {
                setTimeout(
                  function() {
                    this.generateChart();
                    this.reloadChart();
                  }.bind(this),
                  100 * 3.5
                );
                this.setState({ menuOpen });
              }
            }}
          </AppBarContext.Consumer>
          <div id={this.props.id} style={{ margin: 20 }} />
        </Card>
      </Grid>
    );
  }
}

export default SmallMeter;
