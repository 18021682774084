import React from "react";
import PropTypes from "prop-types";
import { timeDiff } from "helpers";
import moment from "moment";

export const StatusComp = ({ value }) => {
  const colors = {
    Inactive: "#ff5252",
    Active: "#4caf50",
    false: "#ff5252",
    true: "#4caf50",
    normal: "#000769",
    Enabled: "#00bf26",
    Disabled: "#e62a00",
  };
  return (
    <span
      style={{
        color: "#fff",
        backgroundColor: colors[value] || "#9c27b0",
        width: 12,
        height: 12,
        display: "inline-block",
        borderRadius: 50,
      }}
    />
  );
};

StatusComp.propTypes = {
  value: PropTypes.string,
};

function formatTime(time, format) {
  if (!time) {
    return "N/A";
  }

  format = format || "MMM DD, HH:mm:ss";
  return moment(time).format(format);
}

export const DurationComp = ({ row: { in_time, out_time } }) => {
  return (
    <React.Fragment>
      <p
        style={{
          display: "flex",
          alignContent: "space-evenly",
          justifyContent: "center",
        }}
      >
        <span style={{ width: "50%" }}>{formatTime(in_time)}</span>
        <span>-</span>
        <span style={{ width: "50%" }}>{formatTime(out_time)}</span>
      </p>
      {in_time && out_time && <span>{timeDiff(in_time, out_time)}</span>}
    </React.Fragment>
  );
};

DurationComp.propTypes = {
  row: PropTypes.object,
};
