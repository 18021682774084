import React, { Component } from "react";
import c3 from "c3";
import "c3/c3.css";
import { handleResponse } from "components/notifications/handleResponse";
import { peopleCountGraph } from "api/peoplecount";
import { AppBarContext } from "context/ContextProvider";
import { blue } from "@material-ui/core/colors";
import { Card, CardHeader } from "material-dashboard-react/components/index";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
class PeopleTraffic extends Component {
  constructor(props) {
    super(props);
    this.chart = null;
    this.timer = null;
    this.state = {
      data: [],
      menuOpen: false,
      duration: 1,
      title: "Monitoring people count for the last 24 hours"
    };
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.chart = null;
  };

  generateChart = () => {
    this.chart = c3.generate({
      bindto: "#people-count",
      data: {
        xFormat: "%Y-%m-%d %H:%M:%S%Z",
        json: [],
      },
      size: {
        height: 380,
      },
      axis: {
        x: {
          type: "timeseries",
          show: true,
          tick: {
            fit: true,
            format: "%I %p",
          },
        },
        y: {
          min: 0,
          padding: { top: 10, bottom: 0 },
        },
      },
    });
  };

  fetchGraphData = async () => {
    await peopleCountGraph(this.state.duration)
      .then(({ data }) => {
        this.setState({
          data,
        });
        this.reloadChart();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  reloadChart = () => {
    let data = this.state.data;
    let display_names = Object.keys(data[0]);
    if (this.state.duration == 1) {
      this.chart.internal.config.axis_x_tick_format = "%I %p";
    } else {
      this.chart.internal.config.axis_x_tick_format = "%e-%b";
    }
    this.chart.flush();
    this.chart.load({
      xFormat: "%Y-%m-%d %H:%M:%S%Z",
      json: data,
      type: "bar",
      keys: {
        x: "datetime",
        value: display_names,
      },
    });
  };

  autoRefresh = async () => {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    await this.fetchGraphData()
    this.reloadChart();
    this.timer = setTimeout(this.autoRefresh, 1000 * 60 * 5);
  };


  async componentDidMount() {
    await this.fetchGraphData();
    this.generateChart();
    this.autoRefresh()
  }

  handleChange = () => (event) => {
    if (event.target.value === 1) {
      this.setState(
        {
          title: "Monitoring people count for the last 24 hours",
          duration: 1,
        },
        () => this.fetchGraphData()
      );
    } else if (event.target.value === 7) {
      this.setState(
        {
          title: "Monitoring people count for the last 7 days",
          duration: 7,
        },
        () => this.fetchGraphData()
      );
    } else if (event.target.value === 15) {
      this.setState(
        {
          title: "Monitoring people count for the last 15 days",
          duration: 15,
        },
        () => this.fetchGraphData()
      );
    } else {
      this.setState(
        {
          title: "Monitoring people count for the last 30 days",
          duration: 30,
        },
        () => this.fetchGraphData()
      );
    }

  };

  render() {
    return (
      <Card style={{ marginBottom: "0px" }}>
        <CardHeader
          style={{
            marginTop: " -20px",
            marginRight: " 20px",
            marginLeft: "20px",
            borderRadius: "3px",
            boxShadow: "#42a5f5 -1px 7px 8px -5px",
            background: `linear-gradient(60deg, ${blue[600]}, ${blue[400]})`,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h4
                style={{
                  margin: "0px 0px 3px",
                  color: "white",
                  fontWeight: "300",
                  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                }}
              >
                {this.state.title}
              </h4>
              <p
                style={{
                  color: "rgba(255,255,255,.62)",
                  fontWeight: "300",
                  margin: "initial",
                }}
              >
              </p>
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <FormControl
                style={{
                  margin: "theme.spacing(1)",
                  minWidth: "30%",
                  color: "white",
                }}
              >
                <Select
                  style={{ background: "#42a5f5", color: "white" }}
                  defaultValue={1}
                  onChange={this.handleChange()}
                >
                  <MenuItem value={1}>Last 24 hours</MenuItem>
                  <MenuItem value={7}>Last 7 days</MenuItem>
                  <MenuItem value={15}>Last 15 days</MenuItem>
                  <MenuItem value={30}>Last 30 days</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </CardHeader>

        <AppBarContext.Consumer>
          {({ state: { menuOpen } }) => {
            if (menuOpen !== this.state.menuOpen) {
              setTimeout(
                function () {
                  this.generateChart();
                  this.reloadChart();
                }.bind(this),
                100 * 10
              );
              this.setState({ menuOpen });
            }
          }}
        </AppBarContext.Consumer>
        <div id="people-count" style={{ margin: "20px" }} />
      </Card>
    );
  }
}
export default PeopleTraffic;
