import React, { Component } from "react";
import { columns } from "data/notifications";
import AppBar from "components/AppBar";
import { fetchNotifications } from "api/notifications";
import PaginationTable from "components/paginationTables/withAddEdit";
import CRUDNotifications from "components/form/notifications/CRUD";
import { handleResponse } from "components/notifications/handleResponse";
import { allNotificationTypes, allNotificationstatus } from "config";
import { createAccess, updateAccess, debounce } from "helpers";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.location_id = window.location.pathname;
    this.state = {
      data: [],
      rowData: undefined,
      total_entries: 0,
      formOpen: false,
      empty: false,
      errorCode: 0,
      skeleton: true,
      loader: false,
    };
  }

  initialRequestParams = () => {
    return {
      perPage: 25,
      page: 1,
      search: "",
      notification_type:
        allNotificationTypes && Object.values(allNotificationTypes),
      status: allNotificationstatus,
    };
  };

  componentDidMount = () => {
    this.fetchTableData();
  };

  fetchTableData = () => {
    fetchNotifications(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
          skeleton: false,
          loader: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  handleSubmitSuccess = () => {
    this.handleClose();
    this.timer = setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  handleClose = () => {
    this.setState({
      formOpen: false,
      rowData: undefined,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination, filters) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.requestParams.notification_type =
      filters.notification_type && filters.notification_type.length > 0
        ? filters.notification_type
        : Object.values(allNotificationTypes);
    this.requestParams.status =
      filters.status && filters.status.length > 0
        ? filters.status
        : allNotificationstatus;
    this.fetchTableData();
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  createAction = () => {
    return createAccess("notifications");
  };

  updateAction = () => {
    return updateAccess("notifications");
  };

  onRowClick = (row) => {
    let access = this.updateAction();
    if (access) {
      this.setState({ formOpen: true, rowData: row });
    }
  };

  render() {
    const { data, rowData, formOpen, skeleton, loader } = this.state;
    const { handleClose, handleSubmitSuccess, onChange, onRowClick } = this;

    const CRUDProps = {
      data: rowData,
      onCancel: handleClose,
      open: formOpen,
      handleSubmitSuccess,
      tabColor: "info",
    };
    const tableProps = {
      tabColor: "info",
      title: "Notifications",
      subTitle: "Notifications of User here",
      columns: columns,
      data: data,
      skeleton,
      loader,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      onRowClick,
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      dataValue: "Add notification",
      createAction: this.createAction(),
    };

    const appBarProps = {
      search: this.requestParams.search,
      searchOpen: true,
      onChange,
    };
    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <PaginationTable {...tableProps} />
        <CRUDNotifications {...CRUDProps} />
      </React.Fragment>
    );
  }
}

export default Notifications;
