import React from "react";
import { apiHost } from "config";

const ShowImage = () => {
  const img_path = window.location.pathname;
  document.getElementsByClassName("content-inside")[0].style.paddingBottom = 0;
  var footer = document.getElementById("footer");
  if (footer) {
    footer.remove();
  }
  return (
    <img
      style={{ width: "100%", height: "100vh" }}
      src={`${apiHost}${img_path}`}
      alt=""
    />
  );
};
export default ShowImage;
