import makeRequest from ".";

export function fetchTilesData(number_plate) {
  return makeRequest({ uri: `/api/v1/vehicles/${number_plate}` });
}

export function fetchAllEntries(number_plate) {
  return makeRequest({ uri: `/api/v1/vehicles/${number_plate}/all_entries` });
}

export function updateOcrValue(id, requestBody) {
  return makeRequest({
    uri: `/api/v1/vehicle_images/${id}/ocr`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}
