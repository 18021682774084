import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { IoMdSearch } from "react-icons/io";
import InputBase from "@material-ui/core/InputBase";

const styles = (theme) => ({
  search: {
    position: "relative",
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    margin: 0,
    borderLeft: "1px solid lightgray",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
  },
  searchIcon: {
    color: "gray",
    width: theme.spacing(10),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    color: "gray",
    height: 70,
    padding: 0,
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 0,
      "&:focus": {
        paddingLeft: theme.spacing(9),
        paddingRight: theme.spacing(4),
        width: 120,
      },
    },
  },
  inputValue: {
    color: "gray",
    padding: 0,
    height: 70,
    paddingLeft: theme.spacing(10),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing(9),
      paddingRight: theme.spacing(4),
      width: 120,
    },
  },
});

class SearchBar extends Component {
  render() {
    const { classes, value, onChange } = this.props;
    return (
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <IoMdSearch style={{ fontSize: "25px" }} />
        </div>
        <InputBase
          key=""
          value={value}
          onChange={onChange}
          placeholder="Search"
          classes={{
            root: classes.inputRoot,
            input: !value ? classes.inputInput : classes.inputValue,
          }}
        />
      </div>
    );
  }
}

SearchBar.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(SearchBar);
