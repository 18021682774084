import {
  enableGraylog,
  graylogFacility,
  graylogUrl,
  honeyBadger,
} from "config";

const preparePayload = ({ message, status, payload }) =>
  JSON.stringify({
    short_message: message,
    host: honeyBadger,
    facility: graylogFacility,
    status,
    ...payload,
  });

export const graylog = ({ message, status, payload }) => {
  try {
    if (enableGraylog) {
      const body = preparePayload({
        message,
        status,
        payload,
      });

      fetch(graylogUrl, {
        method: "POST",
        body,
      }).catch((error) => console.log(error));
    } else {
      console.log({ message }, { status }, { payload });
    }
  } catch (e) {
    console.log(e, e.stack);
  }
};
