import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(2),
    marginTop: "auto",
    backgroundColor: "#f1f3f4",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    color: "#757575",
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <CssBaseline />
      <Container maxWidth="xs" className={classes.container}>
        <Typography variant="body1">
          {new Date().getFullYear()} &copy; Interlock Security Systems
        </Typography>
      </Container>
    </footer>
  );
}
