import React from "react";
import { presence, titleize } from "helpers";
import { StatusComp } from "data/index";

const roles = { UserRole: "User", BotRole: "Bot" };

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    align: "center",
    render: (name) => `${titleize(name)}`,
  },
  {
    title: "Type",
    dataIndex: "type",
    align: "center",
    render: (type) => `${titleize(type === "UserRole" ? "User" : "Bot")}`,
    filterMultiple: false,
    defaultFilteredValue: ["UserRole"],
    filters:
      roles &&
      Object.keys(roles).map((value) => ({ text: roles[value], value: value })),
  },
  {
    title: "Updated by",
    dataIndex: "updated_by",
    align: "center",
    render: (_, row) => {
      let obj = row.updated_by || row.created_by || {};
      let fistName = obj.first_name || "";
      let lastName = obj.last_name || "";
      let str = fistName && titleize(fistName) + " " + lastName;
      return `${presence(str)}`;
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    render: (status) => <StatusComp value={titleize(status)} />,
  },
];
