import makeRequest from ".";

function generateParams(params = {}) {
  return `query=${params.search}&page=${params.page ||
    1}&per_page=${params.perPage || 25}`;
}

export function fetchListingData(requestParams) {
  return makeRequest({
    uri: `/api/v1/vip_vehicles?${generateParams(requestParams)}`,
  });
}

export function createSpecialVehicle(requestBody) {
  return makeRequest({
    uri: "/api/v1/vip_vehicles",
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updateSpecialVehicle(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/vip_vehicles/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}
