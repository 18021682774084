import React, { Component } from "react";
import AppBar from "components/AppBar";
import Traffic from "./Home/Sections/Traffic.jsx";
import HmVehicles from "./Home/Sections/HmVehicles";
import { Row, Col } from "antd";
import { trafficCamera } from "api/dashboard";
import { handleResponse } from "components/notifications/handleResponse";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cameras: [],
    };
  }

  componentDidMount = () => {
    this.fetchCamera();
  };

  fetchCamera = () => {
    trafficCamera()
      .then(({ data }) => {
        this.setState({
          cameras: data,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    const { cameras } = this.state;
    return (
      <React.Fragment>
        <AppBar />
        <Row>
          <Col
            xs={0}
            md={24}
            sm={24}
            lg={16}
            style={{ paddingRight: "15px", paddingBottom: "15px" }}
          >
            <Traffic cameras={cameras} cardHeight="540px" graphHeight="400" />
          </Col>
          <Col xs={24} md={24} sm={24} lg={8} style={{ paddingRight: "15px" }}>
            <HmVehicles cameras={cameras} />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default Home;
