import React from "react";
import { presence, formatTime, titleize } from "helpers";
import { allMessageTypes } from "config";
import moment from "moment";

const StatusTiles = ({ status }) => {
  const colors = {
    Accepted: "#4baf50",
    Queued: "#f0c806eb",
    Sending: "#3d90ff",
    Sent: "#3b8bf7",
    Failed: "#eb4846",
    Delivered: "#4baf50",
    Received: "#4baf50",
    Unknown: "#7c8107de",
    UnDelivered: "#eb4846",
    Read: "#4baf50",
    Receiving: "#3d90ff",
  };
  const backgroundColor = colors[status] || "#7c8107de";
  return (
    <React.Fragment>
      <span
        style={{
          backgroundColor: backgroundColor,
          color: "#ffff",
          margin: "5px 5px 5px 5px",
          borderRadius: 50,
          padding: "5px 15px 5px",
          whiteSpace: "nowrap",
        }}
      >
        {titleize(status)}
      </span>
    </React.Fragment>
  );
};

export const sentAlertsColumn = (
  status,
  recipient,
  seletedStatus,
  selectedRecipient,
  selectedMedium
) => {
  return [
    {
      title: "Subject",
      dataIndex: "subject",
      align: "center",
      render: (subject) => {
        let subject_name =
          subject && subject.length > 30
            ? subject.substring(0, 30) + "...."
            : subject;
        return `${presence(subject_name)}`;
      },
      width: "30%",
    },
    {
      title: "Recipient",
      dataIndex: "recipient",
      align: "center",
      render: (recipient) => {
        return `${presence(recipient.full_name)}`;
      },
      filters: recipient.map((val) => ({ text: val, value: val })),
      filteredValue: selectedRecipient,
    },
    {
      title: "From",
      dataIndex: "from",
      render: (from, row) => {
        if (row.type === "MailSent") {
          return from && JSON.parse(from)[0];
        }
        if (row.type !== "MailSent") {
          return from;
        }
      },
      align: "center",
    },

    {
      title: "Sent At",
      align: "center",
      render: (row) => {
        let startTime = moment(row.created_at);
        let endTime = moment(row.last_updated_time);
        let duration = moment.duration(endTime.diff(startTime));
        let hours = parseInt(duration.asHours());
        let minutes = parseInt(duration.asMinutes()) - hours * 60;
        let seconds = parseInt(duration.asSeconds()) - hours * 60 * 60;

        return (
          <table>
            <tr style={{ display: "flex", justifyContent: "center" }}>
              <tr>
                <td style={{ textAlign: "center" }}>
                  {formatTime(row.created_at)}
                </td>
              </tr>
              <tr> - </tr>
              <tr>
                {!row.last_updated_time ? (
                  <div>N/A</div>
                ) : (
                  <td style={{ textAlign: "center", width: "80px" }}>
                    {formatTime(row.last_updated_time)}
                  </td>
                )}
              </tr>
            </tr>
            <tr>
              <td style={{ textAlign: "center" }}>
                {hours || minutes || seconds ? (
                  <p style={{ marginTop: ".5rem" }}>
                    {hours
                      ? `${hours} hour`
                      : minutes
                      ? `${minutes} minutes`
                      : `${seconds} seconds`}
                  </p>
                ) : (
                  <p style={{ marginTop: "1rem", marginLeft: "-2rem" }}>N/A</p>
                )}
              </td>
            </tr>
          </table>
        );
      },
      width: "28%",
    },
    {
      title: "Medium",
      dataIndex: "medium",
      filters:
        allMessageTypes &&
        allMessageTypes.map((type) => {
          return { text: titleize(type), value: type };
        }),
      align: "center",
      filteredValue: selectedMedium,
    },

    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (status) => <StatusTiles status={status} />,
      filters: status && status.map((val) => ({ text: val, value: val })),
      filteredValue: seletedStatus,
    },
  ];
};

export const IvrAlertsColumn = (
  status,
  recipient,
  seletedStatus,
  selectedRecipient
) => {
  return [
    {
      title: "Subject",
      dataIndex: "subject",
      align: "center",
      render: (subject) => {
        let subject_name =
          subject && subject.length > 30
            ? subject.substring(0, 30) + "...."
            : subject;
        return `${presence(subject_name)}`;
      },
      width: "30%",
    },
    {
      title: "Recipient",
      dataIndex: "recipient",
      align: "center",
      render: (recipient) => {
        return `${presence(recipient.full_name)}`;
      },
      filters: recipient.map((val) => ({ text: val, value: val })),
      filteredValue: selectedRecipient,
    },
    {
      title: "From",
      dataIndex: "from",
      render: (from, row) => {
        if (row.type === "MailSent") {
          return from && JSON.parse(from)[0];
        }
        if (row.type !== "MailSent") {
          return from;
        }
      },
      align: "center",
    },
    {
      title: "Sent At",
      dataIndex: "created_at",
      render: (created_at) => `${formatTime(created_at)}`,
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (status) => <StatusTiles status={status} />,
      filters: status && status.map((val) => ({ text: val, value: val })),
      filteredValue: seletedStatus,
    },
  ];
};
