import React, { Component } from "react";
import { withRouter } from "react-router";
import AppBar from "components/AppBar";
import PaginationTable from "components/paginationTables/withAddEdit";
import { softwareColumns } from "data/softwares";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import { Row } from "antd";
import { fetchSoftwares, updateSoftware } from "api/software";
import CRUDSoft from "components/form/software/CRUD";
import { softwareFilter } from "config";

class Softwares extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: undefined,
      total_rows: 0,
      total_entries: 0,
      rowData: undefined,
      openForm: false,
      path: "",
      skeleton: true,
    };
  }

  componentDidMount = () => {
    this.fetchSoftware();
  };

  fetchSoftware = async () => {
    await fetchSoftwares(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({ data: data, skeleton: false, total_entries });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  onClose = () => {
    this.setState({
      openForm: false,
      rowData: undefined,
    });
  };

  initialRequestParams = () => {
    return {
      page: 1,
      perPage: 25,
      search: "",
      status: "",
      category: Object.values(softwareFilter),
    };
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination, filters) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.requestParams.category =
      filters.category && filters.category.length > 0
        ? filters.category
        : Object.values(softwareFilter);
    this.fetchSoftware();
  };

  handleSubmitSuccess = () => {
    this.onClose();
    this.timer = setTimeout(() => {
      this.fetchSoftware();
    }, 1000);
  };

  handleChange = (status, id) => {
    this.requestParams.status = status;
    if (id) {
      updateSoftware(this.requestParams.status, id)
        .then((res) => {
          if (res.ok) {
            success("Saved Successfully..!");
          }
        })
        .catch((err) => {
          handleResponse(err);
          console.log(err);
        });
    }
  };

  render() {
    const { data, openForm, skeleton } = this.state;
    const { onClose, requestParams, handleSubmitSuccess, handleChange } = this;

    const CRUDProps = {
      open: openForm,
      onCancel: onClose,
      handleSubmitSuccess,
      tabColor: "success",
    };

    const appBarProps = {
      fromOpen: false,
      toOpen: false,
      search: requestParams.search,
      condo: { name: "Admin" },
    };
    const tableProps = {
      tabColor: "success",
      title: "Software",
      subTitle: "Software and configure here",
      data,
      columns: softwareColumns(handleChange),
      pagination: this.pagination(),
      skeleton,
      onRowClick: () => {},
      handleOnClick: () => {
        this.setState({ openForm: true });
      },
      dataValue: "Add Software",
      handleTableChange: this.handleTableChange,
      createAction: true,
    };
    return (
      <React.Fragment>
        <AppBar {...appBarProps}></AppBar>
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        {openForm && <CRUDSoft {...CRUDProps} />}
      </React.Fragment>
    );
  }
}
export default withRouter(Softwares);
