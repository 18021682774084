import React from "react";
import { Row, Col } from "antd";
import SimpleTextInput from "components/inputs/simpleTextInput";
import RadioButton from "components/inputs/radioButton";

const Form = ({ id, name, block, status, handleChange }) => {
  return (
    <React.Fragment>
      <Row spacing={3} style={{ padding: 10, minWidth: 500, minHeight: 230 }}>
        <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="Company"
            name="name"
            required
            value={name}
            onChange={handleChange}
            readOnly={id ? true : false}
          />
        </Col>
        <Col item xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="block"
            name="block"
            value={block}
            onChange={handleChange}
            readOnly={id ? true : false}
          />
        </Col>
        <Col xs={24} sm={12} style={{ padding: 10, zIndex: 0 }}>
          <RadioButton
            labelText="Status"
            value={status}
            data={{ Active: "active", Inactive: "inactive" }}
            onChange={handleChange}
            name="status"
            width="40%"
            color={true}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Form;
