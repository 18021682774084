import React, { Component } from "react";
import moment from "moment";
import { apiHost } from "config";
import { headers } from "helpers";
import { TableContext } from "context/ContextProvider";
import AppBar from "components/AppBar";
import TopComponent from "./Sections/TopComponent";
import Cards from "./Sections/Cards";
import MyTable from "./Sections/Table";
import { Grid } from "@material-ui/core";
import Graph from "./Sections/Graph";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";

// const from = moment(new Date())
//     .subtract(12, "hours")
//     .startOf("hour");
// const to = moment(new Date())
//     .add(12, "hours")
//     .startOf("hour");

const meters = {
  "All Meters": [
    "cd4e9d7d-6f00-479d-8aca-5d462c11fa1a",
    "d6c2c4cd-44c3-491f-835a-29c5e18bdef0",
  ],
  "Big Meter": ["cd4e9d7d-6f00-479d-8aca-5d462c11fa1a"],
  "Small Meter": ["d6c2c4cd-44c3-491f-835a-29c5e18bdef0"],
};

class WaterConsumption extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      meters,
      meterSelected: "All Meters",
      data: [],
      from_time: moment.utc(moment().startOf("day")).format(),
      to_time: moment.utc(moment().endOf("day")).format(),
      granularity: 1,
    };
  }

  componentDidMount = async () => {
    this.autoRefresh();
  };

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  autoRefresh = () => {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.fetchTableData();
    this.timer = setTimeout(this.autoRefresh, 1000 * 60 * 10);
  };

  generateParams() {
    let { from_time, to_time } = this.state;
    let from = moment.utc(from_time).format(),
      to = moment.utc(to_time).format();

    let ids = meters["All Meters"]
      .map((id) => {
        return `&ids[]=${id}`;
      })
      .join("");

    return `from_time=${from}&to_time=${to}${ids}&page=1&per_page=1000`;
  }

  fetchTableData = async () => {
    fetch(
      `${apiHost}/api/v1/water_meters/reading_summary?${this.generateParams()}`,
      {
        method: "GET",
        headers: headers(),
      }
    )
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        const errorMessage = [response.status, await response.json()];
        throw errorMessage;
      })
      .then(({ data }) => {
        let results = {};
        Object.keys(data).map((id) => {
          return (results[id] = this.processed_data(data[id]));
        });

        this.setState({
          data: this.mergeData(results),
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  processed_data = (data) => {
    if (!data || data.length === 0) {
      return;
    }
    const length = data.length / 25;
    let granularity = undefined;
    [48, 24, 12, 8, 6, 4, 2].map((x) => {
      return x >= length && (granularity = x);
    });
    this.setState({ granularity });
    let object = [];
    let offset = moment().utcOffset();
    let consumption = 0;
    data.reverse().forEach((obj) => {
      consumption += obj.consumption;
      let current_time = moment(obj.summary_datetime).utcOffset(offset);
      if (
        current_time.minute() === 0 &&
        current_time.hour() % (granularity / 2) === 0
      ) {
        object.push({
          datetime: current_time.format(),
          consumption,
        });
        consumption = 0;
      }
    });
    data.some((obj, index) => {
      let current_time = moment(obj.summary_datetime).utcOffset(offset);
      if (
        current_time.minute() === 0 &&
        current_time.hour() % (granularity / 2) === 0
      ) {
        if (index + 1 === granularity) {
          object.reverse();
          return true;
        } else {
          object = object.reverse().slice(0, -1);
          return true;
        }
      }
      return false;
    });
    return object;
  };

  mergeData = (data) => {
    let arr = [];
    data[Object.keys(data)[0]].forEach((obj, index) => {
      let tempObj = { datetime: obj.datetime };
      Object.keys(data).forEach(
        (key) => (tempObj[key] = data[key][index].consumption)
      );
      arr.push(tempObj);
    });
    return arr;
  };

  onDateChange = (from_time, to_time) => {
    this.setState({ from_time, to_time, page: 0 }, () => this.fetchTableData());
  };

  handleGroupButtonChange = (id) => {
    this.setState({ meterSelected: id });
  };

  handleRefresh = () => {
    success("Updated latest data..!!");
    this.fetchTableData();
  };

  render() {
    const appBarProps = {
      fromTime: moment(this.state.from_time),
      toTime: moment(this.state.to_time),
      dateFilter: true,
      onDateChange: this.onDateChange,
    };
    const {
      handleDateChange,
      handleGroupButtonChange,
      handleRefresh,
      state,
    } = this;
    return (
      <TableContext.Provider
        value={{
          handleDateChange,
          handleGroupButtonChange,
          handleRefresh,
          state,
        }}
      >
        <AppBar {...appBarProps} />
        <TopComponent />
        <Grid container style={{ paddingTop: 18 }}>
          <Grid item sm={12} lg={8} style={{ paddingRight: 8 }}>
            <Cards />
            <Graph
              data={this.state.data}
              meters={meters}
              granularity={this.state.granularity}
            />
          </Grid>
          <Grid item sm={12} lg={4} style={{ paddingLeft: 8 }}>
            <MyTable />
          </Grid>
        </Grid>
      </TableContext.Provider>
    );
  }
}

export default WaterConsumption;
