import makeRequest from ".";
import { apiHost } from "config";
import { headers } from "helpers";

function generateParams({
  search = "",
  page = 1,
  perPage = 25,
  order = "first_name",
  totalEntries = null,
  type,
} = {}) {
  let userType = "";
  if (type) {
    userType = Array.isArray(type)
      ? type.map((value) => `&type[]=${value}`).join("")
      : `&type[]=${type}`;
  }

  return `${userType}&query=${search}&order=${order}&page=${page}&per_page=${totalEntries ||
    perPage}`;
}

export function fetchListingData(requestParams) {
  return makeRequest({
    uri: `/api/v1/users?${generateParams(requestParams)}`,
  });
}

export function createUser(requestBody) {
  return makeRequest({
    uri: "/api/v1/users",
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updateUser(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/users/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

export function deleteUser(id) {
  return makeRequest({
    uri: `/api/v1/users/${id}`,
    method: "DELETE",
  });
}

export function fetchUserRoles() {
  return makeRequest({
    uri: `/api/v1/users/roles`,
    method: "GET",
  });
}

export function logOut(id) {
  return fetch(apiHost + `/api/v1/users/${id}/invalidate`, {
    method: "PUT",
    headers: headers(),
  }).then(async (response) => {
    if (response.ok) {
      return "Logout Successfully";
    }
    throw new Error([response.status, await response.json()]);
  });
}
