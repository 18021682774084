import makeRequest from ".";

function generateParams(params = {}) {
  return `from_time=${params.fromTime}&to_time=${
    params.toTime
  }&query=${params.search || ""}&page=${params.page ||
    1}&per_page=${params.perPage || 25}`;
}

export function fetchComplaints(requestParams) {
  return makeRequest({
    uri: `/api/v1/complaints?${generateParams(requestParams)}`,
  });
}

export function updateComplaint(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/complaints/${id}/update_status`,
    method: "PUT",
    body: JSON.stringify({ status: requestBody }),
  });
}
