import React from "react";
import { Row, Col } from "antd";
import SimpleTextInput from "components/inputs/simpleTextInput";
import { withStyles } from "@material-ui/core/styles";

const style = () => ({
  tableWrapper: {
    height: 350,
    overflowY: "auto",
    overflowX: "hidden",
  },
});

function MailPopup(props) {
  const { from, subject, recipient, content, msg_id, status } = props;
  let from_address = from;
  let recipients = recipient;

  return (
    <Row>
      <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
        <SimpleTextInput readOnly labelText="From" value={from_address} />
      </Col>
      <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
        <SimpleTextInput
          readOnly
          labelText="Recipient (To)"
          value={`${recipients &&
            `${recipients.full_name} ( ${recipients.email} )`}`}
        />
      </Col>
      <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
        <SimpleTextInput
          readOnly
          labelText="Message ID"
          value={`${msg_id || ""}`}
        />
      </Col>
      <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
        <SimpleTextInput readOnly labelText="Status" value={`${status}`} />
      </Col>
      <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
        <SimpleTextInput readOnly labelText="Subject" value={subject} />
      </Col>
      <div>
        <iframe
          title="Content of Sent Mail"
          src={"data:text/html," + encodeURIComponent(content)}
          style={{ width: "100%", border: "none", minHeight: "320px" }}
        />
      </div>
    </Row>
  );
}
export default withStyles(style)(MailPopup);
