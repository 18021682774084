import React, { Component } from "react";
import { columns } from "data/schedulers";
import AppBar from "components/AppBar";
import { fetchSchedulers } from "api/schedulers";
import RowspanTable from "components/paginationTables/withAddEdit";
import CRUDSchedulers from "components/form/schedulers/CRUD";
import { handleResponse } from "components/notifications/handleResponse";
import { Row } from "antd";

class Schedulers extends Component {
  constructor(props) {
    super(props);
    this.location_id = window.location.pathname;
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      total_entries: 0,
      schedulerData: undefined,
      formOpen: false,
      skeleton: true,
    };
  }

  initialRequestParams = () => {
    return {
      perPage: 25,
      page: 1,
    };
  };

  componentDidMount = async () => {
    this.fetchTableData();
  };

  fetchTableData = () => {
    fetchSchedulers(this.requestParams)
      .then(({ data, total_entries }) => {
        setTimeout(() => {
          this.setState({
            data: data,
            total_entries: total_entries,
            skeleton: false,
          });
        }, 500);
      })
      .catch((error) => {
        handleResponse(error);
        this.setState({ empty: true, errorCode: error[0] });
      });
  };

  handleSubmitSuccess = () => {
    this.handleClose();
    this.timer = setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  handleClose = () => {
    this.setState({
      formOpen: false,
      schedulerData: undefined,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };
  handleTableChange = (pagination) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.fetchTableData();
  };

  render() {
    const { data, schedulerData, formOpen, skeleton } = this.state;
    const { handleClose, handleSubmitSuccess, handleTableChange } = this;

    const CRUDProps = {
      data: schedulerData,
      onCancel: handleClose,
      open: formOpen,
      handleSubmitSuccess,
      tabColor: "info",
    };

    const tableProps = {
      tabColor: "info",
      title: "Schedulers",
      subTitle: "System scheduler services",
      columns: columns,
      data: data,
      skeleton,
      pagination: this.pagination(),
      handleTableChange,
      onRowClick: (row) => {
        this.setState({ formOpen: true, schedulerData: row });
      },
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      disableAddButton: true,
    };
    const appBarProps = {
      search: this.requestParams.search,
      searchOpen: true,
    };
    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <RowspanTable {...tableProps} />
        </Row>
        <CRUDSchedulers {...CRUDProps} />
      </React.Fragment>
    );
  }
}
export default Schedulers;
