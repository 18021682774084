import React from "react";
import moment from "moment";
import { MdEvent } from "react-icons/md";
import { VscEmptyWindow } from "react-icons/vsc";
import NavPills from "./NavPills";
import EntryDetails from "./EntryDetails";
import VisitorEntryDetails from "./VisitorEntryDetails";
import { VehicleEntriesContext } from "context/ContextProvider";
import { Row, Col } from "antd";
import { popCookie } from "../../../helpers";
import { fetchAllEntries } from "api/aboutVehicleEntries";
import { fetchAllVisitorEntries } from "api/outsiders";
import { handleResponse } from "components/notifications/handleResponse";
import { fetchAboutVehicleEntries } from "api/vehicleEntries";
import { fetchAboutVisitorEntries } from "api/outsiders";
import { Skeleton } from "antd";

class EntryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [],
      active: 0,
      data: [],
      skeleton: true,
      noData: false,
    };
  }

  handleClick = (event, active) => {
    this.setState({ active });
    const {
      tabs: {
        [active]: { id },
      },
    } = this.state;

    fetchAboutVehicleEntries(id)
      .then(({ data }) => {
        this.setState({
          data,
          skeleton: false,
          noData: data.length === 0 && true,
        });
      })
      .catch((error) => {
        this.setState({ noData: error[0] === 404 && true, skeleton: false });
        error[0] !== 404 && handleResponse(error);
      });
  };

  handleClickVisitors = (event, active) => {
    this.setState({ active });
    const {
      tabs: {
        [active]: { id },
      },
    } = this.state;

    fetchAboutVisitorEntries(id)
      .then(({ data }) => {
        this.setState({
          data,
          skeleton: false,
          noData: data.length === 0 && true,
        });
      })
      .catch((error) => {
        this.setState({ noData: error[0] === 404 && true, skeleton: false });
        error[0] !== 404 && handleResponse(error);
      });
  };

  GetAllVehicleEntries = async () => {
    const { number_plate } = this.props;
    await fetchAllEntries(number_plate)
      .then(({ data: { vehicle_entries } }) => {
        this.setState(
          {
            tabs: vehicle_entries.map((child) => ({
              id: child.id,
              tabButton: moment(child.in_time || child.out_time).format(
                "DD/MM/YYYY h:mm A"
              ),
              tabIcon: MdEvent,
              tabContent: <EntryDetails />,
            })),
            skeleton: false,
            noData: vehicle_entries.length === 0 && true,
          },
          () => {
            let index = null,
              id = popCookie("vehicle_entry_id");

            if (!id) {
              index = 0;
            } else {
              index =
                this.state.tabs && this.state.tabs.map((o) => o.id).indexOf(id);
            }
            vehicle_entries.length &&
              this.handleClick(null, index === -1 ? 0 : index);
          }
        );
      })
      .catch((error) => {
        this.setState({ noData: error[0] === 404 && true, skeleton: false });
        error[0] !== 404 && handleResponse(error);
      });
  };

  GetAllVisitorEntries = async () => {
    const { phone } = this.props;
    await fetchAllVisitorEntries(phone)
      .then(({ data: { visits } }) => {
        this.setState(
          {
            tabs: visits.map((child) => ({
              id: child.id,
              tabButton: moment(child.in_time || child.out_time).format(
                "DD/MM/YYYY h:mm A"
              ),
              tabIcon: MdEvent,
              tabContent: <VisitorEntryDetails />,
            })),
            skeleton: false,
            noData: visits.length === 0 && true,
          },
          () => {
            let index = null,
              id = popCookie("visitor_entry_id");

            if (!id) {
              index = 0;
            } else {
              index =
                this.state.tabs && this.state.tabs.map((o) => o.id).indexOf(id);
            }
            visits.length &&
              this.handleClickVisitors(null, index === -1 ? 0 : index);
          }
        );
      })
      .catch((error) => {
        this.setState({
          skeleton: false,
          noData: error[0] === 404,
        });
        error[0] !== 404 && handleResponse(error);
      });
  };

  componentDidMount = () => {
    const { number_plate } = this.props;
    number_plate ? this.GetAllVehicleEntries() : this.GetAllVisitorEntries();
  };

  render() {
    const { handleClick } = this;
    const { data, skeleton, noData } = this.state;
    const { scrollValue } = this.props;

    return (
      <Row>
        <Col xs={24} sm={24} md={24} lg={24}>
          {data.length !== 0 ? (
            <VehicleEntriesContext.Provider
              value={{
                handleClick: this.props.number_plate
                  ? handleClick
                  : this.handleClickVisitors,
                data,
              }}
            >
              <NavPills
                color="rose"
                horizontal={{
                  tabsCol: {
                    xs: 24,
                    sm: 7,
                    md: 7,
                    lg: 5,
                    style:
                      scrollValue > 416
                        ? {
                            ...style.navPills,
                            position: "fixed",
                            top: "4rem",
                            width: "15vw",
                          }
                        : { ...style.navPills },
                  },
                  contentCol: {
                    xs: 24,
                    sm: 17,
                    md: 17,
                    lg: 19,
                    style:
                      scrollValue > 416
                        ? { position: "relative", marginLeft: "15vw" } 
                        : "",
                  },
                }}
                tabs={this.state.tabs}
                active={this.state.active}
              />
            </VehicleEntriesContext.Provider>
          ) : skeleton && !noData ? (
            <Skeleton
              style={{
                width: "100%",
                padding: "10px 50px",
              }}
              active={true}
              title={false}
              paragraph={{
                rows: 5,
              }}
            />
          ) : (
            <div style={style.noData}>
              <div>
                <VscEmptyWindow style={style.noDataIcon} />
                <p style={style.noDataText}>No Data to display</p>
              </div>
            </div>
          )}
        </Col>
      </Row>
    );
  }
}

export default EntryList;

const style = {
  navPills: {
    height: 600,
    overflowY: "auto",
    maxHeight: "475px",
    background: "white",
    boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
    marginTop: "24px",
    borderRadius: "8px",
    padding: "0px 10px",
    width:"15vw",
  },
  noDataText: {
    fontSize: "20px",
    color: "gainsboro",
    marginTop: "10px",
  },
  noDataIcon: {
    fontSize: "90px",
    fill: "gainsboro",
    margin: "0px 30px",
  },
  noData: {
    width: "100%",
    height: "70vh",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
