import React, { Component } from "react";
import moment from "moment";
import { setCookie, popCookie, getCookie, debounce } from "helpers";
import { columns } from "data/vehicleEntries";
import { Col, Row } from "antd";
import AppBar from "components/AppBar";
import VehicleStatuses from "components/VehicleStatuses";
import {
  fetchListingData,
  triggerReportDownload,
  aggregation,
} from "api/vehicleEntries";
import PaginationTable from "components/paginationTables/withRefreshDownload";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import VehicleTiles from "./VehicleTiles";
import { trafficCamera } from "api/dashboard";

class VehicleEntries extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.requestParams = {};
    this.camera_names = [];
    this.previousLoad = false;
    this.currentUrl = this.props.history.location.pathname;
    this.state = {
      data: [],
      total_entries: 0,
      cameras: [],
      statuses: [],
      page: 1,
      ...this.initialFilterState(),
      skeleton: true,
      loader: false,
    };
  }

  initialRequestParams = () => {
    return {
      fromTime: moment.utc(moment().startOf("day")).format(),
      toTime: moment.utc(moment().endOf("day")).format(),
      search: "",
      perPage: 25,
      page: 1,
    };
  };

  fetchCameraData = () => {
    trafficCamera()
      .then(({ data }) => {
        this.camera_names = data.map((camera) => camera.name);
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  initialFilterState = () => {
    let prevStateValues = getCookie("prevStateValues");
    let vehicle_type = [];
    let cameraFilters = [],
      statusFilters = [],
      number_plates = [],
      other_filter = [];
    if (prevStateValues) {
      let data = JSON.parse(prevStateValues);
      vehicle_type = data.requestParams.vehicle_type;
      number_plates = data.requestParams.number_plate;
      cameraFilters = data.requestParams.cameras;
      statusFilters = data.requestParams.status;
      other_filter = data.requestParams.other_filter;
    }
    return {
      vehicle_type,
      cameraFilters,
      statusFilters,
      number_plates,
      other_filter,
    };
  };

  componentDidMount = () => {
    this.fetchCameraData();
    let scrollY = 0;
    let prevStateValues = popCookie("prevStateValues");
    let usePrevStateValues = popCookie("usePrevStateValues");

    if (usePrevStateValues && prevStateValues) {
      let data = JSON.parse(prevStateValues);

      if (this.currentUrl) {
        this.requestParams = data.requestParams;
        scrollY = data.scrollY;
        this.previousLoad = true;
      } else {
        this.requestParams = this.initialRequestParams();
      }
    } else {
      this.requestParams = this.initialRequestParams();
    }

    this.autoRefresh();

    setTimeout(function() {
      window.scrollTo({ top: scrollY });
    }, 1000);
  };

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    let prevStateValues = JSON.stringify({
      scrollY: window.scrollY,
      requestParams: this.requestParams,
      currentUrl: this.currentUrl,
    });
    setCookie("prevStateValues", prevStateValues);
  };

  autoRefresh = () => {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.fetchTableData();
    this.timer = setTimeout(this.autoRefresh, 10000);
  };

  onDateChange = (from_time, to_time, loader) => {
    this.requestParams.fromTime = from_time;
    this.requestParams.toTime = to_time;
    this.requestParams.page = 1;
    this.fetchTableData();
    this.setState({ loader: loader });
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  sortTilesData = (cameras) => {
    const { camera_names } = this;
    return cameras.slice().sort(function(a, b) {
      return camera_names.indexOf(a["key"]) - camera_names.indexOf(b["key"]);
    });
  };

  sortTilesStatusData = (statuses) => {
    return statuses.filter((obj) => "Partially Out" !== obj.key).sort();
  };

  fetchTableData = async () => {
    await fetchListingData(this.requestParams)
      .then(({ data, total_entries, aggregation }) => {
        let cameras =
          (aggregation &&
            aggregation.last_location.last_location.buckets &&
            this.sortTilesData(
              aggregation.last_location.last_location.buckets
            )) ||
          [];
        let statuses =
          (aggregation &&
            aggregation.status.status.buckets &&
            this.sortTilesStatusData(aggregation.status.status.buckets)) ||
          [];

        this.setState({
          data,
          total_entries,
          cameras,
          statuses,
          page: 1,
          skeleton: false,
          loader: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  getAggregation = async () => {
    let params = {
      fromTime: this.requestParams.fromTime,
      toTime: this.requestParams.toTime,
    };
    await aggregation(params)
      .then(({ data: { last_location, number_plate, status } }) => {
        let statusFilter =
          status && status["status"]["buckets"]
            ? status["status"]["buckets"].map((val) => ({
                label: val.key,
                value: val.key,
              }))
            : [];
        let number_plate_value =
          number_plate &&
          number_plate["number_plate"]["buckets"].map((val) => ({
            label: val.key,
            value: val.key,
          }));
        let cameraFilter =
          last_location && last_location["last_location"]["buckets"]
            ? last_location["last_location"]["buckets"].map((val) => ({
                label: val.key,
                value: val.key,
              }))
            : [];

        this.setState({
          statusFilter,
          cameraFilter,
          number_plate: number_plate_value,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  generateReport = async () => {
    await triggerReportDownload(this.requestParams, this.state.total_entries)
      .then((data) => {
        success(data.message);
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.fetchTableData();
  };

  filterChange = (obj) => {
    this.setState({
      statusFilters: obj.status,
      cameraFilters: obj.cameras,
      number_plates: obj.number_plate,
      vehicle_type: obj.vehicle_type,
      other_filter: obj.other_filter,
      loader: obj.loader,
    });
    this.requestParams.status = obj.status;
    this.requestParams.cameras = obj.cameras;
    this.requestParams.number_plate = obj.number_plate;
    this.requestParams.vehicle_type = obj.vehicle_type;
    this.requestParams.page = obj.page;
    this.requestParams.other_filter =
      obj.other_filter && obj.other_filter.length ? true : false;
    this.debounceSearch();
  };

  onRowClick = (data) => {
    let vehicle_entry_id = data.id,
      number_plate = data.number_plate;

    if (number_plate) {
      setCookie("vehicle_entry_id", vehicle_entry_id);
      this.props.history.push({
        pathname: `/vehicles/${number_plate}`,
      });
    }
  };

  render() {
    const {
      data,
      cameras,
      statuses,
      cameraFilter,
      number_plates,
      statusFilter,
      number_plate,
      cameraFilters,
      statusFilters,
      vehicle_type,
      other_filter,
      page,
      skeleton,
      loader,
    } = this.state;

    const headProps = { cameras, statuses };
    const {
      onChange,
      onDateChange,
      fetchTableData,
      generateReport,
      onRowClick,
      filterChange,
      getAggregation,
    } = this;
    if (this.previousLoad) {
      try {
        let index = columns.findIndex((obj) => obj.dataIndex === "status");
        columns[index]["filteredValue"] = this.requestParams.status;
      } catch (e) {
        console.log(e);
      }
    }
    const tableProps = {
      tabColor: "primary",
      title: "LNPR Logs",
      subTitle: "Vehicle In and Out Traffic records can be monitored here",
      columns: columns(cameras, statuses),
      data: data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      triggerRefresh: fetchTableData,
      generateReport: generateReport,
      onRowClick: onRowClick,
      skeleton,
      loader,
    };

    const appBarProps = {
      fromTime: moment(this.requestParams.fromTime),
      toTime: moment(this.requestParams.toTime),
      search: this.requestParams.search,
      dateFilter: true,
      // searchOpen: true,
      filter: true,
      onChange,
      onDateChange,
      filterChange,
      getAggregation,
      filterObj: {
        cameraFilter,
        statusFilter,
        number_plate,
        cameraFilters,
        statusFilters,
        vehicle_type,
        number_plates,
        other_filter,
        page,
      },
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <VehicleTiles {...headProps} />
        </Row>
        <Col>
          <PaginationTable {...tableProps} />
        </Col>
        <VehicleStatuses />
      </React.Fragment>
    );
  }
}

export default VehicleEntries;
