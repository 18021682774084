import React, { Component } from "react";
import { Card, CardBody } from "material-dashboard-react/components";
import { Col, Row, Tabs, Table } from "antd";
import { empColumn, visitColumn } from "data/employeeInfo";
import { handleResponse } from "components/notifications/handleResponse";
import { getEmployeeEntries } from "api/employeeEntries";
import { titleize, getCookie } from "helpers";
import moment from "moment";
import Skeleton from "components/Skeleton";
import { getCompanyData, getVisits } from "api/company";

const { TabPane } = Tabs;

class CompanyPages extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();

    this.state = {
      visitTotal_entries: 0,
      employeeTotal_entries: 0,
      total_entries: 0,
      skeleton: true,
      loader: false,
      loading: false,
      page: 1,
      visits: [],
      employeeEntries: [],
      companyData: [],
      active_tab: "visit",
    };
  }

  initialRequestParams = () => {
    return {
      fromTime: "2021-02-1T18:30:00Z",
      toTime: moment.utc().format(),
      search: "",
      perPage: 25,
      page: 1,
    };
  };

  handleTableChange = async (pagination) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.setState({ loading: true });
    if (this.state.active_tab === "visit") {
      await this.fetchVisits();
    } else {
      await this.fetchEmployeeEntries();
    }
  };

  fetchVisits = async () => {
    let id = this.state.companyData.id;
    await getVisits(this.requestParams, id)
      .then(({ data, total_entries }) => {
        this.setState({
          visits: data,
          visitTotal_entries: total_entries,
          skeleton: false,
          loading: false,
          loader: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };
  fetchEmployeeEntries = async () => {
    let id = this.state.companyData.id;
    let model = "company";
    await getEmployeeEntries(this.requestParams, id, model)
      .then(({ data, total_entries }) => {
        this.setState({
          employeeEntries: data,
          employeeTotal_entries: total_entries,
          skeleton: false,
          loading: false,
          loader: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  fetchCompanyData = async () => {
    let id = this.props.companyId && this.props.companyId;

    await getCompanyData(id)
      .then(({ data }) => {
        this.setState({
          companyData: data,
          skeleton: false,
          loading: false,
          loader: false,
        });
      })

      .catch((error) => {
        console.log(error);
        this.props.props_data.history.push(`/${getCookie("home_page")}`);
      });
  };

  componentDidMount = async () => {
    await this.fetchCompanyData();
    await this.fetchEmployeeEntries();
    await this.fetchVisits();
  };

  pagination = () => {
    const { requestParams, state } = this;

    if (this.state.active_tab === "visit") {
      return {
        total: state.visitTotal_entries,
        current: requestParams.page,
        pageSize: requestParams.perPage,
      };
    } else {
      return {
        total: state.employeeTotal_entries,
        current: requestParams.page,
        pageSize: requestParams.perPage,
      };
    }
  };

  handleChange = (e) => {
    this.setState({ active_tab: e.target.value });
  };

  render() {
    const { skeleton, companyData, loading, loader } = this.state;
    const { pagination, handleTableChange, handleChange } = this;
    // const imageUrl = companyData?.s3_image_path;

    return (
      <React.Fragment>
        <Card style={{ margin: 0 }}>
          <CardBody>
            <Row xs={24} sm={24}>
              <Col>
                <div style={{ textAlign: "center", padding: "1rem" }}>
                  <Row style={{ fontSize: 28, fontWeight: "bolder" }}>
                    {titleize(companyData?.name)}
                  </Row>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {/* visit tab */}
        <Tabs
          defaultActiveKey="1"
          style={{
            marginTop: "20px",
            background: "#ffffff",
            boxShadow: "0 1px 4px 0 rgba(0, 0, 0, 0.14)",
            borderRadius: "6px",
          }}
          onChange={(value) =>
            handleChange({ target: { value, name: "about_emp" } })
          }
        >
          <TabPane
            tab={
              <span>
                Visits{" "}
                {this.state.visitTotal_entries !== 0
                  ? `(${this.state.visitTotal_entries})`
                  : ""}
              </span>
            }
            key="visit"
            style={{
              color: "#000000",
              background: "#ffffff",
            }}
          >
            {skeleton ? (
              <Skeleton />
            ) : (
              <Table
                columns={visitColumn}
                dataSource={this.state.visits}
                onChange={handleTableChange}
                style={{ background: "#ffffff" }}
                pagination={
                  this.state.visitTotal_entries !== 0 && {
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} results`,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "25", "50", "100"],
                    ...pagination(),
                  }
                }
                loading={loading || loader}
              />
            )}
          </TabPane>
          <TabPane
            tab={
              <span>
                Employee Entries{" "}
                {this.state.employeeTotal_entries !== 0
                  ? `(${this.state.employeeTotal_entries})`
                  : ""}
              </span>
            }
            key="emp_entry"
            style={{ color: "#000000", background: "#ffffff" }}
          >
            {skeleton ? (
              <Skeleton />
            ) : (
              <Table
                columns={empColumn}
                dataSource={this.state.employeeEntries}
                onChange={handleTableChange}
                style={{ background: "#ffffff" }}
                pagination={
                  this.state.employeeTotal_entries !== 0 && {
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} results`,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "25", "50", "100"],
                    ...pagination(),
                  }
                }
                loading={loading || loader}
              />
            )}
          </TabPane>
        </Tabs>
      </React.Fragment>
    );
  }
}

export default CompanyPages;
