import React from "react";
import { Row, Col } from "antd";
import { InputLabel } from "@material-ui/core";
import SimpleTextInput from "components/inputs/simpleTextInput";
import MultiSelect from "./MultiSelect";
import RadioButton from "components/inputs/radioButton";

function Form(props) {
  const {
    handleChange,
    name,
    status,
    subPermissions,
    allSubPermissions,
    id,
    type,
  } = props;

  return (
    <React.Fragment>
      <Row style={{ padding: 10, minWidth: 170, minHeight: 250 }}>
        <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="Name"
            name="name"
            required
            value={name}
            onChange={handleChange}
          />
        </Col>
        <Col
          xs={24}
          sm={12}
          md={12}
          style={{ padding: "0px 10px", textAlign: "center" }}
        >
          <RadioButton
            labelText="Status"
            value={status}
            data={{ Active: "active", Inactive: "inactive" }}
            onChange={handleChange}
            name="status"
            width="40%"
          />
        </Col>
        {!id && (
          <Col
            xs={24}
            sm={12}
            md={12}
            style={{ padding: "0px 10px", textAlign: "center" }}
          >
            <RadioButton
              labelText="Type"
              value={type}
              data={["UserRole", "BotRole"]}
              onChange={handleChange}
              name="type"
              width="40%"
            />
          </Col>
        )}
        <Col xs={24} sm={24} md={24} style={{ padding: "10px 10px" }}>
          <InputLabel
            style={{ fontSize: 10, marginBottom: 6 }}
            htmlFor="status"
          >
            Sub Permissions{" "}
            {Array.isArray(subPermissions) && subPermissions.length
              ? subPermissions.length
              : ""}
          </InputLabel>
          <MultiSelect
            options={allSubPermissions}
            selected={subPermissions}
            handleChange={handleChange}
            name="subPermissions"
          />
        </Col>
      </Row>
    </React.Fragment>
  );
}
export default Form;
