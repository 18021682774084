import { notification } from "antd/lib/index";
import { kickUser } from "helpers";
import { graylog } from "api/graylog";
import { honeyBadger } from "config";
import { getCookie } from "helpers";

export const error = (msg) => {
  notification.error({
    duration: 5,
    placement: "bottomLeft",
    className: "error",
    message: msg,
    key: "error",
  });
};

export const success = (msg) => {
  notification.success({
    duration: 5,
    placement: "bottomLeft",
    className: "success",
    message: msg,
    key: "success",
  });
};

export const warn = (msg) => {
  notification.warn({
    duration: 5,
    placement: "bottomLeft",
    className: "warn",
    message: msg,
    key: "warn",
  });
};

export const copy = (msg) => {
  notification.success({
    duration: 5,
    placement: "bottomLeft",
    className: "success",
    message: msg,
    key: "success",
  });
};

export const handleResponse = async (error_obj, message) => {
  console.log(error_obj, "err", message);
  honeyBadger.setContext({
    user_email: getCookie("email"),
  });
  honeyBadger.notify(error_obj);

  let responseStatus = error_obj && error_obj[0],
    responseBody = error_obj && error_obj[1];

  if (!responseStatus) {
    graylog({
      message: (error_obj && error_obj.message) || "Api down",
      payload: {
        user_email: getCookie("email"),
        full_message: error_obj && error_obj.stack,
        app_version: window.navigator.appVersion,
        url: window.location.href,
      },
    });
    return;
  }

  graylog({
    message: responseBody.message,
    payload: {
      email: getCookie("email"),
      status: responseStatus,
      url: window.location.href,
    },
  });

  switch (responseStatus) {
    case 200:
      success(responseBody.message || message || "Saved Successfully.!!!");
      break;
    case 201:
      success(responseBody.message || message || "Saved Successfully.!!!");
      break;
    case 401:
      await kickUser();
      window.localStorage.clear();
      error(responseBody.message || message || "Access Denied.!!!");
      break;
    case 403:
      await kickUser();
      window.localStorage.clear();
      error(responseBody.message || message || "Access Denied.!!!");
      break;
    case 404:
      warn(responseBody.message || message || "Not Found.!!!");
      break;
    case 422:
      warn(
        responseBody.data
          ? responseBody.data[0]
          : responseBody.message || message
      );
      break;
    case 500:
      error(responseBody.message || message || "Internal Server Error.!!!");
      break;
    case 502:
      error(
        responseBody.message ||
          message ||
          "Could not fetch data from remote.!!!"
      );
      break;
    default:
      error("Something is not right.!!");
  }
};
