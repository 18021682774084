import React from "react";
import { Skeleton } from "antd";

const AntSkeleton = () => {
  return (
    <Skeleton
      style={{ width: "100%", padding: "10px 50px" }}
      active={true}
      title={false}
      paragraph={{
        rows: 10,
      }}
    />
  );
};
export default AntSkeleton;
