import makeRequest from ".";

function generateParams(params = {}, totalEntries = null) {
  let employees = "";
  let empName = "";
  let phoneNo = "";
  let empCompany = "";
  let deviceName = "";

  let { phone, emp_name, emp_company, device_name } = params;

  if (phone && phone.length > 0) {
    phoneNo = phone.map((key) => `&phone[]=${key.value}`).join("");
  }
  if (emp_name && emp_name.length > 0) {
    empName = emp_name.map((key) => `&name[]=${key.value}`).join("");
  }
  if (emp_company && emp_company.length > 0) {
    empCompany = emp_company
      .map((key) => `&company_id[]=${key.value}`)
      .join("");
  }
  if (device_name && device_name.length > 0) {
    deviceName = device_name.map((key) => `&device[]=${key.value}`).join("");
  }
  if (params.selectedCompany) {
    employees = params.selectedCompany
      .map((value) => `&company[]=${value}`)
      .join("");
  }

  return `from_time=${params.fromTime}&to_time=${params.toTime}&query=${
    params.search
  }${phoneNo}${empName}${empCompany}${deviceName}&page=${params.page ||
    1}&per_page=${totalEntries || params.perPage}${employees}`;
}

export function fetchEmployeeEntry(requestParams) {
  return makeRequest({
    uri: `/api/v1/employee_entries?${generateParams(requestParams)}`,
  });
}

export function getEmployeeEntries(requestParams, id, model) {
  let temp = model === "company" ? `company_id=${id}` : `employee_id=${id}`;
  return makeRequest({
    uri: `/api/v1/employee_entries?${temp}&from_time=${
      requestParams.fromTime
    }&to_time=${requestParams.toTime}&page=${requestParams.page ||
      1}&per_page=${requestParams.perPage}
    `,
  });
}

export function getEmployeeData(id) {
  return makeRequest({
    uri: `/api/v1/employees/${id}/about`,
  });
}

export function regenerateQr(id) {
  return makeRequest({
    uri: `/api/v1/employees/${id}/revoke_identity_id`,
    method: "PUT",
  });
}

export function employeeEntryDownload(requestParams, totalEntries) {
  return makeRequest({
    uri: `/api/v1/employee_entries/generate_report?${generateParams(
      requestParams,
      totalEntries
    )}`,
  });
}
