import React, { Component } from "react";
import { createPeople, updatePeople, deletePeople } from "api/peoples";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";
import Form from "./Form";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      open: false,
      name: "",
      phone: "",
      employer: "",
      entry_msg: "",
      exit_msg: "",
      vehicles: [],
    };
  }

  handleAddNew = () => {
    let temp = this.state.vehicles;
    temp.push({
      vehicle_type: "normal",
      number_plate: "",
      blacklist: false,
      expiry_date: "",
    });
    this.setState({ vehicles: temp });
  };

  handleChange = (event, index, id) => {
    if (index !== undefined) {
      let vehicles = this.state.vehicles;
      vehicles[index][id] = event.target.value;
      this.setState({ vehicles });
    } else {
      this.setState({ [event.target.name]: event.target.value });
    }
  };

  handleDelete = (index) => {
    let temp = this.state.vehicles;
    temp.splice(index, 1);
    this.setState({ vehicles: temp });
  };

  requestParams = () => {
    const { name, phone, employer, entry_msg, exit_msg, vehicles } = this.state;
    return {
      [this.props.peopleType]: {
        name,
        phone,
        employer,
        entry_msg,
        exit_msg,
        vehicles,
      },
    };
  };

  onSubmit = () => {
    const { peopleType, submitClick } = this.props;
    const { id } = this;
    submitClick();
    const method = !id ? createPeople : updatePeople;
    method(this.requestParams(), peopleType, id)
      .then((data) => {
        success(data.message);
        this.props.handleSubmitSuccess();
        submitClick();
      })
      .catch((error) => {
        submitClick();
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    if (this.props.data) {
      const {
        id,
        name,
        phone,
        employer,
        entry_msg,
        exit_msg,
        vehicles,
      } = this.props.data;

      this.id = id;
      this.setState({
        name,
        phone,
        employer,
        entry_msg,
        exit_msg,
        vehicles:
          vehicles &&
          vehicles.map((vehicle) => {
            return {
              vehicle_type: vehicle.vehicle_type,
              number_plate: vehicle.number_plate,
            };
          }),
      });
    }
  };

  onDelete = () => {
    this.setState({ open: true });
  };

  confirmDelete = () => {
    const { peopleType } = this.props;
    const { id } = this;

    deletePeople(peopleType, id)
      .then(() => {
        this.setState({ open: false });
        success("Deleted Successfully..!!");
        this.props.handleSubmitSuccess();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  cancelDelete = () => {
    this.setState({ open: false });
  };

  render() {
    const { handleChange, handleAddNew, handleDelete } = this;
    const { peopleType, dateFilter } = this.props;

    const { name, phone, entry_msg, exit_msg, vehicles } = this.state;
    const UserProps = {
      dateFilter,
      name,
      phone,
      entry_msg,
      exit_msg,
      vehicles,
      handleChange,
      handleAddNew,
      handleDelete,
      peopleType,
    };
    return <Form {...UserProps} />;
  }
}
export default DialogForm()(CRUD);
