import React from "react";
import { Row, Col } from "antd";
import SimpleTextInput from "components/inputs/simpleTextInput";
import Multiselect from "components/inputs/Multiselect";
import { fetchListingData } from "api/users";
import { allReportTypes, allReportStatus } from "config";
import RadioButton from "components/inputs/radioButton";

export default function Form(props) {
  const { handleChange, name, status, report_type, users } = props;
  const statusProps = {
    labelText: "Status",
    value: status,
    data: allReportStatus,
    onChange: handleChange,
    name: "status",
  };
  const typeProps = {
    labelText: "Report Type",
    value: report_type,
    data: allReportTypes,
    onChange: handleChange,
    name: "report_type",
  };

  let fetchUsers = async (searchQuery) => {
    let filter = [];
    await fetchListingData({
      page: 1,
      per_page: 5,
      search: searchQuery,
    }).then(({ data }) => {
      data.forEach((value) => {
        if (value.type === "User") {
          filter.push({
            label: value.first_name,
            value: value.id,
          });
        }
      });
    });
    return filter;
  };

  return (
    <Row>
      <Col xs={24} sm={24} style={{ padding: "0px 10px" }}>
        <SimpleTextInput
          labelText="Name"
          name="name"
          required
          value={name}
          onChange={handleChange}
        />
      </Col>
      <Col
        xs={24}
        sm={12}
        style={{ padding: "20px 10px", textAlign: "center" }}
      >
        <RadioButton {...statusProps} />
      </Col>
      <Col
        xs={24}
        sm={12}
        style={{ padding: "20px 10px", textAlign: "center" }}
      >
        <RadioButton {...typeProps} />
      </Col>
      <Col xs={24} sm={24} style={{ padding: "10px" }}>
        <Multiselect
          isMulti
          async
          heading={"Users"}
          selected={users}
          name="users"
          handleChange={handleChange}
          dataPromise={fetchUsers}
        />
      </Col>
    </Row>
  );
}
