import React, { Component } from "react";
import { ContextProvider } from "context/ContextProvider";
import { withStyles } from "@material-ui/core/styles";
import { InputAdornment } from "@material-ui/core";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "material-dashboard-react/components/index";
import { signupPageStyle } from "../style";
import { Row, Col } from "antd";
import { MdArrowBack, MdPeople } from "react-icons/md";
import SimpleTextInput from "components/inputs/simpleTextInput";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAnimaton: "cardHidden",
    };
  }

  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Row style={{ justifyContent: "center", display: "flex" }}>
          <Col xs={24} sm={24} md={8}>
            <Card className={classes[this.state.cardAnimaton]}>
              <ContextProvider.Consumer>
                {(context) => (
                  <React.Fragment>
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h3 style={{ margin: 10, color: "white" }}>
                        Forget Password
                      </h3>
                    </CardHeader>
                    <Button
                      style={{
                        padding: "15px 20px",
                        justifyContent: "flex-start",
                        boxShadow: "none",
                        backgroundColor: "white",
                        color: "#8e24aa",
                        fontSize: "12px",
                      }}
                      onClick={context.goBack}
                    >
                      <MdArrowBack />
                      Back to Login
                    </Button>
                    <form
                      className={classes.form}
                      onSubmit={(e) => {
                        e.preventDefault();
                        context.sendOTP();
                      }}
                    >
                      <CardBody>
                        <SimpleTextInput
                          labelText="Phone or Email"
                          id="user_name"
                          required
                          onChange={context.handleChange}
                          inputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <MdPeople className={classes.inputIconsColor} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </CardBody>
                      <CardFooter className={classes.cardFooter}>
                        <Button
                          simple
                          color="primary"
                          size="lg"
                          type="submit"
                          style={{ width: "100%" }}
                        >
                          Submit
                        </Button>
                      </CardFooter>
                    </form>
                  </React.Fragment>
                )}
              </ContextProvider.Consumer>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withStyles(signupPageStyle)(Form);
