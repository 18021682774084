import React, { Component } from "react";
import { setCookie, debounce } from "helpers";
import { columns } from "data/vehicles";
import AppBar from "components/AppBar";
import { fetchVehicleData } from "api/vehicles";
import PaginationTable from "components/paginationTables/withAddEdit";
import CRUDVehicle from "components/form/vehicle/CRUD";
import { Row } from "antd";
import { handleResponse } from "components/notifications/handleResponse";
import { createAccess, updateAccess } from "helpers";

class Vehicles extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.requestParams = this.initialRequestParams();
    this.state = {
      details: "",
      data: [],
      total_entries: 0,
      vehicleData: undefined,
      formOpen: false,
      skeleton: true,
    };
  }

  initialRequestParams = () => {
    return {
      search: "",
      perPage: 25,
      page: 1,
    };
  };

  componentDidMount = () => {
    this.fetchTableData();
  };

  fetchTableData = () => {
    fetchVehicleData(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
          skeleton: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  handleSubmitSuccess = () => {
    this.handleClose();
    setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  handleClose = () => {
    this.setState({
      formOpen: false,
      vehicleData: undefined,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  editVehicle = (row) => {
    this.setState({ formOpen: true, vehicleData: row });
  };

  vehicleHistory = (row) => {
    let vehicle_entry_id = row.id,
      number_plate = row.number_plate;

    if (number_plate) {
      setCookie("vehicle_entry_id", vehicle_entry_id);
      this.props.history.push({
        pathname: `/vehicles/${number_plate}`,
      });
    }
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    let field = sorter && sorter.field;
    let order = sorter && sorter.order === "ascend" ? "asc" : "desc";
    this.requestParams.sort_field = field && `${field}`;
    this.requestParams.sort_order = order && `${order}`;
    this.fetchTableData();
  };

  createAction = () => {
    return createAccess("vehicles");
  };

  updateAction = () => {
    return updateAccess("vehicles");
  };

  render() {
    const { data, vehicleData, formOpen, skeleton } = this.state;
    const {
      onChange,
      handleClose,
      handleSubmitSuccess,
      handleTableChange,
      updateAction,
    } = this;

    const CRUDProps = {
      onCancel: handleClose,
      open: formOpen,
      data: vehicleData,
      handleSubmitSuccess,
      tabColor: "warning",
    };

    const appBarProps = {
      search: this.requestParams.search,
      searchOpen: true,
      onChange,
    };

    const tableProps = {
      skeleton,
      tabColor: "warning",
      title: "Vehicles",
      subTitle: "Register vehicles and add Welcome Messages here",
      columns: columns(this.editVehicle, this.vehicleHistory, updateAction),
      data: data,
      pagination: this.pagination(),
      onRowClick: () => {},
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      dataValue: "Add Vehicle",
      createAction: this.createAction(),
      handleTableChange,
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row justify="center" alignItems="center">
          <PaginationTable {...tableProps} />
        </Row>
        {formOpen && <CRUDVehicle {...CRUDProps} />}
      </React.Fragment>
    );
  }
}

export default Vehicles;
