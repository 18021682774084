import React from "react";

const Status = () => {
  return (
    <div style={{ margin: "auto", width: "90%" }}>
      <p>
        <span style={{ fontSize: 16, fontWeight: "bold" }}>In</span> - Vehicles
        that have come in through Entry Gate and not crossed Exit gate
      </p>
      <p>
        <span style={{ fontSize: 16, fontWeight: "bold" }}>Out</span> - Vehicles
        that came in through Entry gate and left through exit gate
      </p>
    </div>
  );
};

export default Status;
