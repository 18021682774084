import React, { Component } from "react";
import moment from "moment";
import AppBar from "components/AppBar";
import { columns } from "data/invites";
import { allVisitorInviteStatuses } from "config";
import PaginationTable from "components/paginationTables/withRefreshDownload";
import {
  fetchListingData,
  triggerReportDownload,
  blockInvite,
} from "api/inviteVisitor";
import { Row } from "antd";
import { withRouter } from "react-router-dom";
import { Form } from "components/form/visitorInvites/Form";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import { debounce, setCookie, popCookie } from "helpers";

class VisitorInvites extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      total_entries: 0,
      skeleton: true,
      formOpen: false,
      rowData: undefined,
    };
  }

  initialRequestParams = () => {
    return {
      fromTime: moment.utc(moment().startOf("week")).format(),
      toTime: moment.utc(moment().endOf("week")).format(),
      status: allVisitorInviteStatuses,
      search: "",
      perPage: 25,
      page: 1,
    };
  };

  componentDidMount = () => {
    this.autoRefresh();
    this.fetchTableData();
  };

  componentDidMount = () => {
    let scrollY = 0;
    let prevStateValues = popCookie("prevStateValues");
    let usePrevStateValues = popCookie("usePrevStateValues");

    if (usePrevStateValues && prevStateValues) {
      let data = JSON.parse(prevStateValues);
      if (data.currentUrl === this.currentUrl) {
        this.requestParams = data.requestParams;
        scrollY = data.scrollY;
        this.previousLoad = true;
      } else {
        this.requestParams = this.initialRequestParams();
      }
    } else {
      this.requestParams = this.initialRequestParams();
    }

    this.autoRefresh();

    setTimeout(function() {
      window.scrollTo({ top: scrollY });
    }, 1000);
  };

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    let prevStateValues = JSON.stringify({
      scrollY: window.scrollY,
      requestParams: this.requestParams,
      currentUrl: this.currentUrl,
    });
    setCookie("prevStateValues", prevStateValues);
  };

  autoRefresh = () => {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.fetchTableData();
    this.timer = setTimeout(this.autoRefresh, 10000);
  };

  onDateChange = (from_time, to_time) => {
    this.requestParams.fromTime = from_time;
    this.requestParams.toTime = to_time;
    this.fetchTableData();
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 10000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  fetchTableData = () => {
    fetchListingData(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
          skeleton: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  generateReport = () => {
    triggerReportDownload(this.requestParams, this.state.total_entries).catch(
      (error) => {
        handleResponse(error);
      }
    );
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination, filters) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.requestParams.status = filters.status;
    this.fetchTableData();
  };

  onRowClick = (row) => {
    this.setState({ formOpen: true, rowData: row });
  };

  onClose = () => {
    this.setState({ formOpen: false });
  };

  blockInvite = (id) => {
    blockInvite(id)
      .then((data) => {
        success(data.message);
        this.setState({ formOpen: false });
        this.fetchTableData();
      })
      .catch((err) => {
        console.log(err);
        handleResponse(err);
      });
  };
  inviteeDetails = (row) => {
    let invitee_id = row.id;
    let invitee_phone = row.phone;

    if (invitee_id && invitee_phone && row.status === "visited") {
      setCookie("visitor_entry_id", invitee_id);
      this.props.history.push({
        pathname: `/outsiders/${invitee_phone}`,
      });
    }
  };

  render() {
    const { data, skeleton, formOpen, rowData } = this.state;
    const {
      onChange,
      onDateChange,
      fetchTableData,
      onClose,
      onRowClick,
    } = this;
    const tableProps = {
      tabColor: "info",
      title: "Invitation",
      subTitle: "Resident invitations records can be monitored here",
      columns: columns,
      data: data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      triggerRefresh: fetchTableData,
      // generateReport: generateReport,
      onRowClick: onRowClick,
      skeleton,
    };

    const appBarProps = {
      fromTime: moment(this.requestParams.fromTime),
      toTime: moment(this.requestParams.toTime),
      search: this.requestParams.search,
      dateFilter: true,
      // searchOpen: true,
      onChange,
      onDateChange,
    };
    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        {formOpen && (
          <Form {...rowData} onClose={onClose} formOpen={formOpen} />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(VisitorInvites);
