import React, { Component } from "react";
import AppBar from "components/AppBar";
import MeterReadings from "./Sections/MeterReadings";
import BigMeterGraph from "./Sections/BigMeterGraph";
import SmallMeterGraph from "./Sections/SmallMeterGraph";
import { apiHost } from "config";
import WaterPumpTable from "./Sections/Table";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const appBarProps = {
      fromOpen: false,
      toOpen: false,
    };
    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <MeterReadings />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexFlow: "row wrap",
          }}
        >
          <WaterPumpTable />
          <BigMeterGraph
            id="big-meter-chart"
            url={`${apiHost}/api/v1/water_meters/cd4e9d7d-6f00-479d-8aca-5d462c11fa1a/consumptions`}
            subTitle={"Water Consumption in Last 6 Hours"}
          />
          <SmallMeterGraph
            id="small-meter-chart"
            url={`${apiHost}/api/v1/water_meters/d6c2c4cd-44c3-491f-835a-29c5e18bdef0/consumptions`}
            subTitle={"Water Consumption in Last 6 Hours"}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
