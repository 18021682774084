import React, { Component } from "react";
import AlertUpdate from "./alertUpdate";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import { Card, CardBody } from "material-dashboard-react/components/index";
import { Row } from "antd";
import { MdClose } from "react-icons/md";
import { timeDiff } from "helpers";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import { updateResolved, showData } from "api/alerts";
import { setCookie } from "helpers";

const modalStyle = {
  closeButton: {
    fontSize: "27px",
    cursor: "pointer",
    color: "#fff",
    marginTop: "1rem",
  },
  timeStyle: {
    display: "flex",
    fontFamily: "serif",
    color: "white",
    fontSize: "15px",
  },
  modalHeader: {
    padding: "15px 20px 15px 20px",
    borderBottom: "1px solid lightgray",
    margin: 0,
    borderRadius: "1px",
  },
  alertName: {
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "serif",
  },
};

class AlertDetails extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      open: false,
      data: false,
      rowData: this.props.rowData,
      changeColor: false,
    };
  }

  componentDidMount = async () => {
    if (this.state.rowData) {
      const { rowData } = this.state;
      this.setState({ rowData });
      this.id = rowData.id;
    }
  };

  numberPlateOnclick = () => {
    const { number_plate } = this.props.rowData.entity;
    let number_plate1 = number_plate ? number_plate : "";
    this.props.history.push({
      pathname: `/vehicles/${number_plate1}`,
    });
  };

  visitorOnclick = () => {
    let visitor_entry_id = this.props.rowData?.entity?.id,
      phone = this.props.rowData?.entity?.visitor?.phone;

    if (phone) {
      setCookie("visitor_entry_id", visitor_entry_id);
      this.props.history.push({
        pathname: `/outsiders/${phone}`,
      });
    }
  };
  handleResolved = (remark_message) => {
    updateResolved(this.id, remark_message)
      .then(() => {
        this.props.handleSubmitSuccess();
        success("Updated Successfully..!");
        this.formData();
        this.setState({
          changeColor: true,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  formData = async () => {
    await showData(this.id)
      .then(({ data }) => {
        this.setState({ rowData: { ...this.state.rowData, ...data } });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  color = () => {
    if (!this.state.rowData) {
      return "#3FA4FD";
    }
    const { status } = this.state.rowData;

    return status === "Raised"
      ? "#e53935"
      : status === "Resolved"
      ? "#008000"
      : "#3FA4FD";
  };

  render() {
    const { handleClose } = this.props;
    const { rowData, changeColor } = this.state;
    const {
      handleFalseAlert,
      color,
      numberPlateOnclick,
      visitorOnclick,
      handleResolved,
    } = this;
    const functionalProps = {
      handleResolved,
      handleFalseAlert,
      handleClose,
      numberPlateOnclick,
      visitorOnclick,
      color: color(),
      changeColor,
    };
    if (!rowData) {
      return;
    }

    const { from_time, to_time, status, resolved_at } = rowData;
    const formatedDate = (date) =>
      moment(date).format("MMM Do YYYY, h:mm:ss a");

    let text = "";
    switch (status) {
      case "Raised":
        {
          const total_hours = timeDiff(moment(), from_time);
          text = `Trigged At ${formatedDate(from_time)} (${total_hours})`;
        }
        break;
      case "Resolved":
        {
          const total_hours = timeDiff(from_time, to_time || resolved_at);
          text = `Active between 
            ${formatedDate(from_time)}
            - 
            ${formatedDate(to_time || resolved_at)}
            (${total_hours})`;
        }
        break;
      default:
        break;
    }

    return (
      <React.Fragment>
        <Dialog
          open
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              overflow: "visible",
              minWidth: "60%",
              maxWidth: "65%",
              maxHeight: "75%",
              padding: "0px",
            },
          }}
        >
          <Row xs={24} sm={24} md={24}>
            <Card style={{ margin: "0%" }}>
              <Card
                style={{
                  ...modalStyle.modalHeader,
                  backgroundColor: color(),
                }}
              >
                <div style={modalStyle.alertName}>
                  <h1
                    style={{
                      color: "white",
                      margin: "0px",
                    }}
                  >
                    {rowData.alert_group_name}
                  </h1>

                  <MdClose
                    style={modalStyle.closeButton}
                    onClick={handleClose}
                  />
                </div>
                <Row>
                  <div style={modalStyle.timeStyle}>{text}</div>
                </Row>
              </Card>

              <CardBody>
                <AlertUpdate {...rowData} {...functionalProps} />
              </CardBody>
            </Card>
          </Row>
        </Dialog>
      </React.Fragment>
    );
  }
}
export default AlertDetails;
