import makeRequest from ".";

function generateParams({
  search = "",
  page = 1,
  perPage = 25,
  type = "",
} = {}) {
  let typevalue = "";
  typevalue =
    type && Array.isArray(type)
      ? type.map((value) => `&type[]=${value}`).join("")
      : `&type[]=${type}`;
  return (
    `${type === "" || null || undefined ? "" : typevalue}&` +
    `query=${search}&page=${page}&per_page=${perPage}`
  );
}

export function fetchRoles(requestParams) {
  return makeRequest({ uri: `/api/v1/roles?${generateParams(requestParams)}` });
}

export function fetchRoleData(id) {
  return makeRequest({ uri: `/api/v1/roles/${id}` });
}

export function createUserRoles(requestBody) {
  return makeRequest({
    uri: "/api/v1/roles",
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updateUserRoles(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/roles/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}
