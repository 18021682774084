import React from "react";
import { presence } from "helpers";
import { Button } from "antd";

const Status = ({ value }) => {
  const colors = {
    active: "green",
    inactive: "red",
  };

  return (
    <span
      style={{
        color: "#fff",
        backgroundColor: colors[value] || "#9c27b0",
        width: 12,
        height: 12,
        display: "inline-block",
        borderRadius: 50,
      }}
    />
  );
};


export const Columns = (editAction) => [
  {
    title: "Name",
    dataIndex: "name",
    align: "center",
    render: (name) => `${presence(name)}`,
  },
  {
    title: "Block",
    dataIndex: "block",
    align: "center",

    render: (block) => `${presence(block)}`,
  },

  {
    title: "Status",
    dataIndex: "status",
    align: "center",

    render: (status) => <Status value={status} />,
  },
  {
    title: "Manage",
    align: "center",
    // width: "30%",
    render: (row) => {
      return (
        <React.Fragment>
          <div
            className="download-reset-container"
            style={{ marginTop: "10px" }}
          >
            <Button
              onClick={() => editAction(row)}
              style={{
                margin: "0px 10px 5px 10px",
              }}
            >
              Edit
            </Button>
          </div>
        </React.Fragment>
      );
    },
  },
];
