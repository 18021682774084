import React, { Component } from "react";
import AppBar from "components/AppBar";
import { fetchListingData } from "api/specialvehicles";
import PaginationTable from "components/paginationTables/withAddEdit";
import CRUDVipVehicle from "components/form/vipvehicles/CRUD";
import { Row } from "antd";
import { columns } from "data/specialvehicles";
import { handleResponse } from "components/notifications/handleResponse";
import { allBlackListsStatus } from "config";
import { createAccess, updateAccess, debounce, setCookie } from "helpers";

class SpecialVehicles extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      total_entries: 0,
      formOpen: false,
      skeleton: true,
      vehicleData: undefined,
    };
  }

  initialRequestParams = () => {
    return {
      perPage: 25,
      page: 1,
      search: "",
    };
  };

  componentDidMount = () => {
    this.fetchTableData();
  };

  fetchTableData = () => {
    fetchListingData(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
          skeleton: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  flushTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  handleSubmitSuccess = () => {
    this.handleClose();
    this.flushTimer();
    this.timer = setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  handleClose = () => {
    this.setState({
      formOpen: false,
      vehicleData: undefined,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination, filters) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.requestParams.status =
      filters.status && filters.status.length > 0
        ? filters.status
        : allBlackListsStatus;
    this.fetchTableData();
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  createAction = () => {
    return createAccess("vip_vehicles");
  };

  updateAction = () => {
    return updateAccess("vip_vehicles");
  };

  editVehicle = (row) => {
    this.setState({ formOpen: true, vehicleData: row });
  };

  vehicleHistory = (row) => {
    let vehicle_entry_id = row.id,
      number_plate = row.vehicle.number_plate;

    if (number_plate) {
      setCookie("vehicle_entry_id", vehicle_entry_id);
      this.props.history.push({
        pathname: `/vehicles/${number_plate}`,
      });
    }
  };

  render() {
    const { data, formOpen, skeleton, vehicleData } = this.state;
    const { handleClose, handleSubmitSuccess, onChange } = this;
    const CRUDProps = {
      data: vehicleData,
      onCancel: handleClose,
      handleSubmitSuccess,
      open: formOpen,
      tabColor: "warning",
      onChange,
    };

    const tableProps = {
      skeleton,
      title: "VIP Vehicles",
      subTitle: "Add VIP Vehicles",
      tabColor: "warning",
      columns: columns(
        this.editVehicle,
        this.vehicleHistory,
        this.updateAction
      ),
      data: data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      onRowClick: () => {},
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      dataValue: "ADD VIP VEHICLE",
      createAction: this.createAction(),
      updateAction: this.updateAction(),
    };

    const appBarProps = {
      search: this.requestParams.search,
      searchOpen: true,
      onChange,
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        {formOpen && <CRUDVipVehicle {...CRUDProps} />}
      </React.Fragment>
    );
  }
}

export default SpecialVehicles;
