import React, { Component } from "react";
import { Row, Col } from "antd";

import { formatTime } from "helpers";

const alertStyle = {
  cameraStyle: {
    padding: ".3rem",
    color: "white",
    borderRadius: "0px",
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "0",
    backgroundColor: "#008080",
    width: "150px",
    textAlign: "center",
  },

  entryInfo: {
    textAlign: "left",
    minWidth: "35%",
    color: "grey",
    fontSize: "16px",
    marginLeft: ".5rem",
  },

  borderStyle: {
    borderBottom: "1px solid gray",
    marginBottom: "1%",
    marginRight: "1rem",
  },
};

class CameraDetails extends Component {
  render() {
    let { props } = this;
    let { entity } = props;
    return (
      <Row>
        <Col xs={24} sm={12}>
          <React.Fragment>
            <h2
              style={{
                textAlign: "left",
                marginBottom: "0%",
                marginTop: "1%",
              }}
            >
              <p style={alertStyle.cameraStyle}>Camera Entry</p>
            </h2>
            <div style={alertStyle.borderStyle}></div>
            <Row>
              <div style={{ display: "flex" }}>
                <div style={alertStyle.entryInfo}>
                  {entity?.vehicle_entry?.vehicle_trackings?.map(
                    (camera, index) => (
                      <p key={index}>{camera.camera_name}</p>
                    )
                  )}
                </div>
                <div style={{ fontSize: "16px", fontWeight: "bolder" }}>
                  {entity?.vehicle_entry?.vehicle_trackings?.map(
                    (detTime, index) => (
                      <p key={index}>: {formatTime(detTime.detected_time)}</p>
                    )
                  )}
                </div>
              </div>
            </Row>
          </React.Fragment>
        </Col>
      </Row>
    );
  }
}

export default CameraDetails;
