import React, { Component } from "react";
import AppBar from "components/AppBar";
import StepChart from "./Sections/StepGraph";
import WaterPumpChart from "./Sections/WaterPumpChart";

import { TableContext } from "context/ContextProvider";
import moment from "moment";

import { setHours } from "helpers";

let from = moment(new Date().setHours(0, 0, 0, 0))
  .utc()
  .format();
class PumpDashbord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from_time: new Date(),
      selectedDate: from,
    };
  }
  handleDateChange = (id, value) => {
    let start_date = setHours(value, "START");
    this.setState({
      [id]: value,
      selectedDate: moment(start_date)
        .utc()
        .format(),
    });
  };

  render() {
    const { handleDateChange, state } = this;
    const { selectedDate } = this.state;
    return (
      <TableContext.Provider
        value={{
          handleDateChange,
          state,
        }}
      >
        <AppBar
          fromOpen
          fromLabel="Select Date"
          dateFormat="DD-MM-YYYY"
          timeFormat={false}
        />
        <StepChart selectedDate={selectedDate} />
        <WaterPumpChart
          id="bfe587a0-506a-4160-8588-746e3aa8f96b"
          name="Pump 1 (High Raise)"
          chartID="R1"
          selectedDate={selectedDate}
        />
        <WaterPumpChart
          id="537d58e1-0ab7-425a-8281-c9d17ccf0379"
          name="Pump 2 (High Raise)"
          chartID="R2"
          selectedDate={selectedDate}
        />
        <WaterPumpChart
          id="6f263701-ec90-4180-a24e-47a8f3447b27"
          name="Pump 3 (High Raise)"
          chartID="R3"
          selectedDate={selectedDate}
        />
        <WaterPumpChart
          id="e4fa79a7-0974-4da8-92a1-8f5efed5e1ba"
          name="Pump 1 (Low Raise)"
          chartID="R4"
          selectedDate={selectedDate}
        />
        <WaterPumpChart
          id="eceab509-2a8a-4ec5-b24e-df55c72f25ef"
          name="Pump 2 (Low Raise)"
          chartID="R5"
          selectedDate={selectedDate}
        />
        <WaterPumpChart
          id="bea052cc-1725-4076-bea3-5d357a2f1a91"
          name="Pump 3 (Low Raise)"
          chartID="R6"
          selectedDate={selectedDate}
        />
      </TableContext.Provider>
    );
  }
}

export default PumpDashbord;
