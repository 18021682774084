import React from "react";
import { Modal, Descriptions } from "antd";
import { presence, titleize } from "helpers";
import moment from "moment";

export const Form = ({
  invitees,
  invitor,
  purpose,
  state,
  created_at,
  status,
  visiting_time,
  onClose,
  formOpen,
}) => {
  const visitor_name = invitees?.map((visitor) => presence(visitor.name));
  const visitor_phone = invitees?.map((visitor) => presence(visitor.phone));
  const visitor_fin = invitees?.map((visitor) => presence(visitor.fin_number));
  const visitor_identity_id = invitees?.map((visitor) =>
    presence(visitor.identity_id)
  );
  const visitor_employer = invitees?.map((visitor) =>
    presence(visitor.employer)
  );
  const visited_at = invitees?.map((visitor) => visitor.visited_at).join();
  const { name, phone } = invitor || " ";
  return (
    <React.Fragment>
      <Modal
        title="Invitation Details"
        rowData
        centered
        visible={formOpen}
        onCancel={onClose}
        footer={null}
        width={750}
      >
        <Descriptions
          title={"Registration Details"}
          layout="horizontal"
          bordered
          colon
          column={2}
          style={{
            marginBottom: "30px",
            fontSize: "18px",
            fontWeight: "normal",
            padding: "0px 24px",
          }}
        >
          <Descriptions.Item label={"Created At"}>
            {moment(created_at).format("MMM DD, HH:mm:ss")}
          </Descriptions.Item>
          <Descriptions.Item label={"Visiting Time"}>
            {moment(visiting_time).format("MMM DD, HH:mm:ss")}
          </Descriptions.Item>
          <Descriptions.Item label={"Current Status"}>
            {presence(titleize(status))}
          </Descriptions.Item>
          <Descriptions.Item label={"Current Status"}>
            {presence(titleize(state))}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions
          title={"Visitor Details"}
          layout="horizontal"
          bordered
          colon
          column={2}
          style={{
            marginBottom: "30px",
            fontSize: "18px",
            fontWeight: "normal",
            padding: "0px 24px",
          }}
        >
          <Descriptions.Item label={"Name"}>{visitor_name}</Descriptions.Item>
          <Descriptions.Item label={"Phone"}>{visitor_phone}</Descriptions.Item>
          <Descriptions.Item label={"FIN Number"}>
            {visitor_fin}
          </Descriptions.Item>
          <Descriptions.Item label={"Identity ID"}>
            {visitor_identity_id}
          </Descriptions.Item>
          <Descriptions.Item label={"Employer"}>
            {visitor_employer}
          </Descriptions.Item>
          <Descriptions.Item label={"Purpose of Visit"}>
            {presence(purpose)}
          </Descriptions.Item>
          <Descriptions.Item label={"Visited at"}>
            {visited_at ? moment(visited_at).format("MMM DD, HH:mm:ss") : "-"}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions
          title={"Invitor Details"}
          layout="horizontal"
          bordered
          colon
          column={2}
          style={{
            marginBottom: "30px",
            fontSize: "18px",
            fontWeight: "normal",
            padding: "0px 24px",
          }}
        >
          <Descriptions.Item label={"Name"}>
            {presence(titleize(name))}
          </Descriptions.Item>
          <Descriptions.Item label={"Phone"}>
            {presence(phone)}
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </React.Fragment>
  );
};
