import React from "react";
import { titleize } from "helpers";

export const Status = ({ status }) => {
  const colors = {
    Resolved: "green",
    Pending: "red",
  };
  const backgroundColor = colors[status];
  const textColor = status === "-" ? "#000" : "#fff";
  return (
    <React.Fragment>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        <div>
          <span
            style={{
              color: textColor,
              backgroundColor,
              padding: "6px 15px 5px",
              borderRadius: 50,
              marginLeft: 5,
              whiteSpace: "nowrap",
              marginTop: "4px",
              marginBottom: "4px",
            }}
          >
            {status}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

export const columns = (handleChange) => {
  return [
    {
      title: "Subject",
      dataIndex: "subject",
      align: "center",
    },
    {
      title: "Description",
      dataIndex: "description",
      align: "center",
    },
    {
      title: "Phone",
      align: "center",
      render: (row) => {
        return row.resident.phone;
      },
    },
    {
      title: "Name",
      align: "center",
      render: (row) => {
        return row.resident.name;
      },
    },
    {
      title: "Unit",
      align: "center",
      render: (row) => {
        return row.resident.unit_number;
      },
    },
    {
      title: "Status",
      align: "center",
      render: (row) => {
        return (
          <React.Fragment>
            {row.status === "pending" ? (
              <div
                onClick={() => {
                  let e = "resolved";
                  handleChange(e, row.id);
                }}
              >
                <Status status={titleize(row.status)} />
              </div>
            ) : (
              <Status status={titleize(row.status)} />
            )}
          </React.Fragment>
        );
      },
    },
  ];
};
