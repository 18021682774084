import React, { Component } from "react";
import { ContextProvider } from "context/ContextProvider";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {
  InputAdornment,
  Link,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { MdPeople, MdLock, MdCheck } from "react-icons/md";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "material-dashboard-react/components/index";
import { signupPageStyle, checkbox } from "../style";
import { Row, Col } from "antd";
import SimpleTextInput from "components/inputs/simpleTextInput";

const useStyles = makeStyles(checkbox);

const CustomCheckBox = () => {
  const classes = useStyles();
  return (
    <ContextProvider.Consumer>
      {(context) => (
        <FormControlLabel
          style={{ marginRight: 37, marginTop: -5 }}
          classes={{
            root: classes.checkboxLabelControlClassName,
            label: classes.checkboxLabel,
          }}
          control={
            <Checkbox
              tabIndex={-1}
              checkedIcon={
                <MdCheck
                  style={{ color: "#a43fb8" }}
                  className={classes.checkedIcon}
                />
              }
              icon={
                <MdCheck
                  style={{ color: "#a43fb8" }}
                  className={classes.uncheckedIcon}
                />
              }
              classes={{
                checked: classes.checked,
              }}
              onChange={context.checkedChange}
              checked={context.state.checked}
            />
          }
          label={<span style={{ fontSize: 15 }}>Remember me</span>}
        />
      )}
    </ContextProvider.Consumer>
  );
};

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAnimaton: "cardHidden",
    };
  }

  componentDidMount() {
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      700
    );
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Row style={{ justifyContent: "center", display: "flex" }}>
          <Col xs={24} sm={24} md={12} lg={8}>
            <Card className={classes[this.state.cardAnimaton]}>
              <ContextProvider.Consumer>
                {(context) => (
                  <form
                    className={classes.form}
                    onSubmit={(e) => {
                      e.preventDefault();
                      context.login();
                    }}
                  >
                    <CardHeader color="primary" className={classes.cardHeader}>
                      <h4 style={{ margin: 10, color: "white" }}>Login</h4>
                    </CardHeader>
                    <p className={classes.divider}>Hello Guest.!!!</p>
                    <CardBody>
                      <SimpleTextInput
                        labelText="Username"
                        id="user_name"
                        required
                        onChange={context.handleChange}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <MdPeople className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <SimpleTextInput
                        labelText="Password"
                        id="password"
                        required
                        onChange={context.handleChange}
                        type="password"
                        inputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <MdLock className={classes.inputIconsColor} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <CustomCheckBox />
                      <Link
                        variant="body2"
                        onClick={context.onClickForget}
                        style={{
                          color: "rgb(0, 0, 0,0.26)",
                          fontSize: 15,
                          fontFamily:
                            '"Roboto", "Helvetica", "Arial", sans-serif',
                          fontWeight: "400",
                          lineHeight: "1.5",
                          letterSpacing: "0.00938em",
                          cursor: "pointer",
                        }}
                      >
                        Forgot password?
                      </Link>
                    </CardBody>
                    <CardFooter className={classes.cardFooter}>
                      <Button
                        simple
                        color="primary"
                        size="lg"
                        type="submit"
                        style={{ width: "100%" }}
                      >
                        LET&apos;S GO
                      </Button>
                    </CardFooter>
                  </form>
                )}
              </ContextProvider.Consumer>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default withStyles(signupPageStyle)(Form);
