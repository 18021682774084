import React from "react";
import { Button } from "antd";
import { presence, noValue, timeDiff } from "helpers";
import moment from "moment";

export const columns = (editOutsider, outsiderHistory, updateAction) => [
  {
    title: "Name / Phone",
    align: "center",
    render: (row) => {
      if (row.name && row.phone)
        return (
          <React.Fragment>
            <p style={{ margin: 0 }}>{presence(row.name)}</p>
            <p style={{ margin: 0, fontWeight: 600 }}>{presence(row.phone)}</p>
          </React.Fragment>
        );
      else
        return <p style={{ margin: 0 }}>{presence(row.name || row.phone)}</p>;
    },
  },
  {
    title: "Vehicles",
    dataIndex: "vehicles",
    render: (vehicles) => {
      let number_plates =
        vehicles && vehicles.map((e) => e.number_plate).join(", ");
      return `${presence(number_plates)}`;
    },
    align: "center",
    width: "30%",
  },

  {
    title: "Last Visited",
    align: "center",
    dataIndex: "latest_entry_at",
    render: (latest_entry_at) => `${timeDiff(moment(), latest_entry_at)}`,
  },
  {
    title: "Previous Visits",
    align: "center",
    dataIndex: "no_of_visits",
    render: (visits_count) => `${noValue(visits_count)}`,
    sorter: true,
  },
  {
    title: "Manage",
    render: (row) => {
      return (
        <React.Fragment>
          {updateAction() && (
            <Button
              onClick={() => editOutsider(row)}
              style={{
                margin: "0px 10px 5px 10px",
              }}
            >
              Edit
            </Button>
          )}
          <Button
            onClick={() => outsiderHistory(row)}
            style={{
              margin: "5px 10px 0px 10px",
            }}
          >
            History
          </Button>
        </React.Fragment>
      );
    },
    align: "center",
  },
];
