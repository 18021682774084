import React from "react";
import { setCookie } from "helpers";
import { withStyles } from "@material-ui/styles";
import { ContextProvider } from "context/ContextProvider";
import Login from "./Sections/Login";
import { style } from "./style.jsx";
import { logIn } from "api/login";
import { handleResponse, error } from "components/notifications/handleResponse";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "login",
      checked: true,
    };
  }
  componentDidMount = () => {
    document.getElementsByClassName(
      "content-inside"
    )[0].style.paddingBottom = 0;
    var footer = document.getElementById("footer");
    if (footer) {
      footer.remove();
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  login = async () => {
    const { user_name, password, checked } = this.state;
    await logIn(user_name, password)
      .then(
        ({
          data: { first_name, last_name, email, auth_token, home_page, roles },
        }) => {
          let role = roles && roles.map((role) => role.name);
          first_name && setCookie("first_name", first_name, 60);
          last_name && setCookie("last_name", last_name, 60);
          email && setCookie("email", email, 60);
          home_page && setCookie("home_page", home_page, 60);
          roles && window.localStorage.setItem("roles", JSON.stringify(role));
          checked
            ? setCookie("token", auth_token, 60)
            : setCookie("token", auth_token);
          setCookie("active", "1", 0, 2);
          window.location.reload();
        }
      )
      .catch((err) => {
        if (err[0] === 401) {
          error(err[1].message);
        } else {
          handleResponse(err[0], err[1]);
        }
      });
  };

  checkedChange = () => {
    this.setState((prev) => {
      return { checked: !prev.checked };
    });
  };

  onClickForget = () => {
    this.props.history.push("/forgot-password");
  };

  render() {
    const { handleChange, login, state, checkedChange, onClickForget } = this;
    const { classes } = this.props;

    return (
      <ContextProvider.Provider
        value={{
          state,
          handleChange,
          login,
          checkedChange,
          onClickForget,
        }}
      >
        <div className={classes.backgroundWithImage}>
          <div className={classes.dimLayer}>
            <Login />
          </div>
        </div>
      </ContextProvider.Provider>
    );
  }
}

export default withStyles(style)(LoginPage);
