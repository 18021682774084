import makeRequest from ".";

function generateParams(params = {}) {
  return `query=${params.search}&page=${params.page || 1}&per_page=${
    params.perPage
  }`;
}

export function fetchStaffsData(requestParams) {
  return makeRequest({
    uri: `/api/v1/staffs?${generateParams(requestParams)}`,
  });
}

export function createStaffs(requestBody) {
  return makeRequest({
    uri: `/api/v1/staffs`,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updateStaffs(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/staffs/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

function StaffEntryParams(params = {}, totalEntries = null) {
  return `from_time=${params.fromTime || ""}&to_time=${params.toTime ||
    ""}&query=${params.search || ""}&page=${params.page ||
    1}&per_page=${totalEntries || params.perPage}`;
}

export function fetchStaffEntry(requestParams) {
  return makeRequest({
    uri: `/api/v1/staff_entries?${StaffEntryParams(requestParams)}`,
  });
}

export function getStaffEntries(requestParams, id) {
  return makeRequest({
    uri: `/api/v1/staff_entries?staff_id=${id}&from_time=${
      requestParams.fromTime
    }&to_time=${requestParams.toTime}&page=${requestParams.page ||
      1}&per_page=${requestParams.perPage}
    `,
  });
}

export function getStaffData(id) {
  return makeRequest({
    uri: `/api/v1/staffs/${id}/about`,
  });
}

export function regenerateId(id) {
  return makeRequest({
    uri: `/api/v1/staffs/${id}/revoke_identity_id`,
    method: "PUT",
  });
}

export function uploadBulkStaff(body) {
  return makeRequest({
    uri: `/api/v1/staffs/import`,
    method: "POST",
    body,
  });
}
