import React from "react";
import { presence, titleize, formatTime } from "helpers";
import { Button, Select } from "antd";
import { StatusComp } from "data/index";
import { MdContentCopy } from "react-icons/md";
import { copy } from "components/notifications/handleResponse";
import { softwareFilter } from "config";
import { apiHost } from "config";

const { Option } = Select;

const copyPath = (path) => {
  let textField = document.createElement("textarea");
  textField.innerText = path;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
  copy("Path Copied");
};

const SoftTiles = ({ name }) => {
  let bgColor = "#673ab7";
  if (/^([A-D])$/.test(name[0].toUpperCase())) {
    bgColor = "#2196f3";
  } else if (/^([E-M])$/.test(name[0].toUpperCase())) {
    bgColor = "#e91e63";
  } else if (/^([M-R])$/.test(name[0].toUpperCase())) {
    bgColor = "#0000FF";
  } else if (/^([R-T])$/.test(name[0].toUpperCase())) {
    bgColor = "#000080";
  } else {
    bgColor = "#800000";
  }

  return (
    <React.Fragment>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        <span
          style={{
            color: "#fff",
            backgroundColor: bgColor,
            padding: "5px 15px 5px",
            borderRadius: 50,
            marginLeft: 5,
            whiteSpace: "nowrap",
            marginTop: "4px",
            marginBottom: "4px",
          }}
        >
          {name}
        </span>
      </div>
    </React.Fragment>
  );
};

export const softwareColumns = (handleChange) => {
  return [
    {
      dataIndex: "status",
      align: "center",
      render: (status) => <StatusComp value={titleize(status)} />,
    },
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
    },
    {
      title: "Version",
      dataIndex: "version",
      align: "center",
    },
    {
      title: "Category",
      dataIndex: "category",
      align: "center",
      render: (category) => <SoftTiles name={titleize(category)} />,
      filters:
        softwareFilter &&
        Object.keys(softwareFilter).map((key) => {
          return { text: titleize(key), value: softwareFilter[key] };
        }),
    },
    {
      title: "Description",
      align: "center",
      render: (_, row) => (
        <React.Fragment>
          <p>{presence(row.description)}</p>
        </React.Fragment>
      ),
    },
    {
      title: "Uploaded By",
      dataIndex: "created_at",
      align: "center",
      render: (_, row) => (
        <React.Fragment>
          <p style={{ margin: 0, fontWeight: "bolder", fontSize: "16px" }}>
            {presence(row.created_by?.first_name || "-")}
          </p>
          <p style={{ margin: 0 }}>{presence(formatTime(row.created_at))}</p>
        </React.Fragment>
      ),
    },
    {
      title: "Status",
      align: "center",
      render: (row) => {
        return (
          <React.Fragment>
            <div>
              <Select
                defaultValue={row.status}
                style={{ width: 120 }}
                onChange={(e) => handleChange(e, row.id)}
              >
                <Option value="alpha">Alpha</Option>
                <Option value="beta">Beta</Option>
                <Option value="stable">Stable</Option>
                <Option value="unstable">Unstable</Option>
              </Select>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      title: "Path",
      align: "center",
      render: (row) => {
        let apk_path = row.s3_image_path
          ? row.s3_image_path
          : `${apiHost}${row.path}`;
        return (
          <React.Fragment>
            <Button
              onClick={() => copyPath(apk_path)}
              value={apk_path}
              size="large"
            >
              <MdContentCopy />
            </Button>
          </React.Fragment>
        );
      },
    },
  ];
};
