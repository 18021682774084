import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { MdOpacity, MdUpdate, MdDateRange } from "react-icons/md";
import { TiWaves } from "react-icons/ti";
import { apiHost } from "config";
import { headers } from "helpers";
import moment from "moment";

import {
  GridContainer,
  GridItem,
  Card,
  CardIcon,
  CardHeader,
  CardFooter,
} from "material-dashboard-react/components";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { handleResponse } from "components/notifications/handleResponse";

class MeterReadings extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      big_meter: {
        last_24_hours: 0,
        reading: {
          absolute: 0,
          created_at: null,
        },
      },
      small_meter: {
        last_24_hours: 0,
        reading: {
          absolute: 0,
          created_at: null,
        },
      },
    };
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  autoRefresh = () => {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }

    this.fetchWater();
    this.timer = setTimeout(this.autoRefresh, 1000 * 60);
  };

  componentDidMount = () => {
    this.autoRefresh();
  };

  fetchWater = async () => {
    fetch(`${apiHost}/api/v1/water_meters/tiles`, {
      method: "GET",
      headers: headers(),
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        const errorMessage = [response.status, await response.json()];
        throw errorMessage;
      })
      .then(({ big_meter, small_meter }) => {
        this.setState({
          big_meter: big_meter,
          small_meter: small_meter,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    const { classes } = this.props;
    const { big_meter, small_meter } = this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={6} md={3} style={{ paddingRight: "15px" }}>
          <Card style={{ marginBottom: "10px", marginTop: "25px" }}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <TiWaves />
              </CardIcon>
              <p className={classes.cardCategory}>Big Meter</p>
              <h2 className={classes.cardTitle}>
                {big_meter["last_24_hours"]} <small>Units</small>
              </h2>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <MdDateRange />
                Last 24 Hours Consumption
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} style={{ paddingRight: "15px" }}>
          <Card style={{ marginBottom: "10px", marginTop: "25px" }}>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <MdOpacity />
              </CardIcon>
              <p className={classes.cardCategory}>Big Meter Reading</p>
              <h2 className={classes.cardTitle}>
                {big_meter["reading"] && big_meter["reading"]["absolute"]}
              </h2>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <MdUpdate />
                Updated{" "}
                {moment
                  .duration(
                    moment(
                      big_meter["reading"] && big_meter["reading"]["created_at"]
                    ).diff(moment())
                  )
                  .humanize(true)}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} style={{ paddingRight: "15px" }}>
          <Card style={{ marginBottom: "10px", marginTop: "25px" }}>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <TiWaves />
              </CardIcon>
              <p className={classes.cardCategory}>Small Meter</p>
              <h2 className={classes.cardTitle}>
                {small_meter["last_24_hours"]} <small>Units</small>
              </h2>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <MdDateRange />
                Last 24 Hours Consumption
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3} style={{ paddingRight: "15px" }}>
          <Card style={{ marginBottom: "10px", marginTop: "25px" }}>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <MdOpacity />
              </CardIcon>
              <p className={classes.cardCategory}>Small Meter Reading</p>
              <h2 className={classes.cardTitle}>
                {small_meter["reading"] && small_meter["reading"]["absolute"]}
              </h2>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <MdUpdate />
                Updated{" "}
                {moment
                  .duration(
                    moment(
                      small_meter["reading"] &&
                        small_meter["reading"]["created_at"]
                    ).diff(moment())
                  )
                  .humanize(true)}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
MeterReadings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(MeterReadings);
