import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Button, Paper } from "@material-ui/core";
import { MdKeyboardArrowDown } from "react-icons/md";
import { shuffleArray, kickUser, getCookie } from "helpers";
import male_icon from "assets/img/male.png";
// import female_icon from "assets/img/female.png";

const styles = (theme) => ({
  margin: {
    margin: theme.spacing(2),
  },
  root: {
    position: "relative",
  },
  paper: {
    top: 64,
    position: "absolute",
    right: 0,
    width: 225,
    backgroundColor: "white",
    color: "grey",
    zIndex: 1,
    borderRadius: 0,
  },
  profileIcon: {
    border: "2px solid white",
    borderRadius: "50px",
    padding: "5px",
    color: "white",
    minWidth: "50px",
    fontSize: "20px",
    height: "50px",
  },
  button: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    color: "grey",
    backgroundColor: "white",
    margin: "0px",
    padding: "10px",
    "&:hover": {
      color: "white",
      backgroundColor: "rgb(99, 110, 114)",
    },
  },
});

class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.first_name = getCookie("first_name");
    this.last_name = getCookie("last_name");
    this.email = getCookie("email");
    this.state = {
      open: false,
    };
  }

  handleClick = (value) => {
    this.setState({
      open: value,
    });
  };

  logout = async () => {
    await kickUser();
  };

  render() {
    const { classes } = this.props;
    const { open } = this.state;
    const { first_name, last_name, email } = this;
    const greeting = shuffleArray([
      "Hello",
      "Hi",
      "Howdy!",
      "Hey!",
      "G’day!",
      "Welcome",
    ])[0];

    return (
      <div className={classes.root}>
        <ClickAwayListener onClickAway={() => this.handleClick(false)}>
          <div
            style={{
              padding: "0px 0px 0px 25px",
              height: "70px",
              display: "flex",
              alignItems: "center",
              borderLeft: "1px solid lightgray",
            }}
          >
            <Button
              onClick={() => this.handleClick(!this.state.open)}
              className={classes.profileIcon}
            >
              <img
                src={male_icon}
                style={{ maxWidth: 45, marginLeft: -10 }}
                alt="profile-icon"
              />
              <span>
                <MdKeyboardArrowDown
                  style={{
                    margin: "12px 0 0 10px",
                    color: "rgb(108, 92, 231)",
                    fontSize: "25px",
                  }}
                />
              </span>
            </Button>
            {open && (
              <Paper className={classes.paper}>
                <div
                  style={{
                    backgroundColor: "rgb(108, 92, 231)",
                    padding: "10px 15px",
                    color: "#fff",
                  }}
                >
                  <span
                    style={{
                      top: -5,
                      width: 10,
                      right: "8%",
                      height: 10,
                      position: "absolute",
                      transform: "rotate(45deg)",
                      backgroundColor: "rgb(108, 92, 231)",
                    }}
                  />
                  <h3 style={{ margin: "0px", color: "white" }}>
                    {greeting}, {first_name} {last_name}
                  </h3>
                  <p style={{ margin: "2px 5px 10px 0px", fontSize: "14px" }}>
                    {email && email.length > 20
                      ? email.substring(0, 20) + "..."
                      : email}
                  </p>
                </div>
                <div style={{ padding: 10 }}>
                  <p
                    className={classes.button}
                    onClick={() => {
                      this.props.history.push("/user-profile");
                    }}
                  >
                    Profile
                  </p>
                  <p className={classes.button} onClick={this.logout}>
                    Log Out
                  </p>
                </div>
              </Paper>
            )}
          </div>
        </ClickAwayListener>
      </div>
    );
  }
}

MyProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

const MyComponent = withStyles(styles)(MyProfile);

export default withRouter(MyComponent);
