import AppBar from "components/AppBar";
import React, { Component } from "react";
import { Skeleton } from "antd";
import CompanyPages from "./CompanyPages";

class AboutCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      skeleton: false,
      loading: false,
    };
  }

  render() {
    const { skeleton } = this.state;
    const props_data = this.props;
    let companyData = this.props.location.state?.row;
    let companyId = companyData?.id;
    let url = window.location.href;
    let splitUrl = url.split("/");
    let blockUrl = splitUrl[4];
    let block = this.props.match.params.block;
    const formProps = {
      companyId,
      block,
      blockUrl,
      props_data,
    };

    return (
      <React.Fragment>
        <AppBar />
        {skeleton === false ? (
          <CompanyPages {...formProps} />
        ) : (
          <Skeleton
            style={{ width: "100%", padding: "10px 50px" }}
            active={true}
            title={false}
            paragraph={{
              rows: 10,
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

export default AboutCompany;
