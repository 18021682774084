import React, { Component } from "react";
import { Row, Col, Button } from "antd";
import { AlertResolveOptions } from "config";
import SimpleTextInput from "components/inputs/simpleTextInput";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

class AlertUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resolve_msg: "",
      remark_msg: "",
    };
  }

  handleChange = (e) => {
    this.setState({ remark_msg: e.target.value });
  };

  handleChangeSelect = (e) => {
    this.setState({ resolve_msg: e.target.value, remark_msg: "" });
  };
  handleSubmit = () => {
    const { resolve_msg, remark_msg } = this.state;
    const { handleResolved } = this.props;
    let params = `${resolve_msg}${remark_msg !== "" ? ":" : ""}${remark_msg}`;
    handleResolved(params);
  };

  render() {
    const { remark_msg, resolve_msg } = this.state;
    const { handleChangeSelect, handleChange, handleSubmit } = this;
    const req =
      resolve_msg === "Others" || resolve_msg === "Wrong Entry" ? true : false;
    return (
      <React.Fragment>
        <div style={{ flexGrow: 1 }}>
          <div id="alert-dialog-description">
            Comment the reason why this alert should be resolved
          </div>
          <Row style={{ display: "flex" }}>
            <Col xs={24} sm={24} md={24} style={{ padding: "20px 10px" }}>
              <FormControl style={{ width: "50%" }}>
                <InputLabel id="demo-customized-select-label">
                  Remarks
                </InputLabel>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={resolve_msg}
                  onChange={handleChangeSelect}
                >
                  {AlertResolveOptions.map((o, index) => (
                    <MenuItem key={index} value={o}>
                      {o}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div style={{ marginTop: "10px", width: "100%" }}>
                <SimpleTextInput
                  labelText="Remarks"
                  name="remark_msg"
                  value={remark_msg}
                  onChange={handleChange}
                  multi={true}
                  rows={2}
                  required={req}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "-10px",
            margin: "-10px 0px 2px 0px",
          }}
        >
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={resolve_msg === "" ? true : false}
            style={{
              color: "white",
              backgroundColor: "#27a243",
              borderRadius: "4px",
            }}
            autoFocus
          >
            Submit
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default AlertUpdate;
