import React, { Component } from "react";
import moment from "moment";
import { DatetimeRangePickerTrigger } from "rc-datetime-picker";
import { withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import "rc-datetime-picker/dist/picker.css";
import { MdDateRange } from "react-icons/md";

const styles = () => ({
  input: {
    padding: 10,
    color: "rgb(108, 92, 231)",
    fontSize: 15,
    textAlign: "right",
    width: "100%",
    border: "none",
  },
  picker: {
    marginTop: 10,
    position: "relative",
    borderTop: "1px solid lightgray",
    borderLeft: "1px solid lightgray",
    paddingRight: 10,
    paddingLeft: 10,
  },
  labelIcon: {
    position: "absolute",
    top: 10,
    left: 10,
    color: "gray",
  },
  labelText: {
    position: "absolute",
    top: -20,
  },
});

class DateTimePicker extends Component {
  handleChange = (moment) => {
    this.props.onChange(moment.start, moment.end);
  };

  shouldComponentUpdate(nextProps) {
    return (
      this.props.start.format("YYYY-MM-DD H:mm") !==
        nextProps.start.format("YYYY-MM-DD H:mm") ||
      this.props.end.format("YYYY-MM-DD H:mm") !==
        nextProps.end.format("YYYY-MM-DD H:mm")
    );
  }

  render() {
    const { shortcut, classes, start, end } = this.props;
    const shortcuts = shortcut || {
      Today: { start: moment().startOf("day"), end: moment().endOf("day") },
      Yesterday: {
        start: moment()
          .subtract(1, "days")
          .startOf("day"),
        end: moment()
          .subtract(1, "days")
          .endOf("day"),
      },
      "This Week": {
        start: moment().startOf("week"),
        end: moment().endOf("week"),
      },
    };
    const momentTime = {
      start: start,
      end: end,
    };
    return (
      <form
        className={classes.container}
        onSubmit={(e) => e.preventDefault()}
        noValidate
      >
        <DatetimeRangePickerTrigger
          showCalendarPicker
          showTimePicker
          shortcuts={shortcuts}
          moment={momentTime}
          confirmButtonText="Search"
          onChange={this.handleChange}
        >
          <div
            style={{
              display: "flex",
              marginTop: 20,
              color: "#000",
              width: 370,
            }}
          >
            <div className={classes.picker}>
              <label className={classes.labelText}>From</label>
              <span className={classes.labelIcon}>
                <MdDateRange style={{ fontSize: "25px" }} />
              </span>
              <input
                type="text"
                value={momentTime.start.format("DD-MMM-YY HH:mm")}
                className={classes.input}
                readOnly
              />
            </div>

            <div className={classes.picker}>
              <label className={classes.labelText}>To</label>
              <span className={classes.labelIcon}>
                <MdDateRange style={{ fontSize: "25px" }} />
              </span>
              <input
                type="text"
                value={momentTime.end.format("DD-MMM-YY HH:mm")}
                className={classes.input}
                readOnly
              />
            </div>
          </div>
        </DatetimeRangePickerTrigger>
      </form>
    );
  }
}

DateTimePicker.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DateTimePicker);
