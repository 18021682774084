import React, { Component } from "react";
import { columns } from "data/reports";
import AppBar from "components/AppBar";
import { fetchReports } from "api/reports";
import PaginationTable from "components/paginationTables/withAddEdit";
import CRUDReports from "components/form/reports/CRUD";
import { handleResponse } from "components/notifications/handleResponse";
import { allReportTypes, allReportStatus } from "config";
import { createAccess, updateAccess, debounce } from "helpers";

class Reports extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      rowData: undefined,
      formOpen: false,
      skeleton: true,
      loader: false,
    };
  }

  initialRequestParams = () => {
    return {
      perPage: 25,
      page: 1,
      search: "",
      report_type: Object.values(allReportTypes),
      status: allReportStatus,
    };
  };

  componentDidMount = () => {
    this.fetchTableData();
  };

  fetchTableData = () => {
    fetchReports(this.requestParams)
      .then(({ data }) => {
        this.setState({
          data: data,
          skeleton: false,
          loader: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  handleSubmitSuccess = () => {
    this.handleClose();
    this.timer = setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  handleClose = () => {
    this.setState({
      formOpen: false,
      rowData: undefined,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination, filters) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.requestParams.report_type =
      filters.report_type && filters.report_type.length > 0
        ? filters.report_type
        : Object.values(allReportTypes);
    this.requestParams.status =
      filters.status && filters.status.length > 0
        ? filters.status
        : allReportStatus;
    this.fetchTableData();
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  createAction = () => {
    return createAccess("reports");
  };

  updateAction = () => {
    return updateAccess("reports");
  };

  onRowClick = (row) => {
    let access = this.updateAction();
    if (access) {
      this.setState({ formOpen: true, rowData: row });
    }
  };

  render() {
    const { data, rowData, formOpen, skeleton, loader } = this.state;
    const { handleClose, handleSubmitSuccess, onChange } = this;

    const CRUDProps = {
      data: rowData,
      onCancel: handleClose,
      open: formOpen,
      handleSubmitSuccess,
      tabColor: "success",
    };
    const tableProps = {
      tabColor: "success",
      title: "Reports",
      subTitle: "Reports of Users here",
      columns: columns,
      data: data,
      skeleton,
      loader,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      onRowClick: (row) => {
        this.setState({ formOpen: true, rowData: row });
      },
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      dataValue: "add reports",
      createAction: this.createAction(),
    };

    const appBarProps = {
      search: this.requestParams.search,
      searchOpen: true,
      onChange,
    };
    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <PaginationTable {...tableProps} />
        <CRUDReports {...CRUDProps} />
      </React.Fragment>
    );
  }
}

export default Reports;
