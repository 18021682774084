import React from "react";
import { presence, formatTime, titleize } from "helpers";
import { Button } from "antd";
import { StatusComp } from "data/index";

export const columns = (editVehicle, vehicleHistory, updateAction) => [
  {
    title: "Name",
    align: "center",
    render: (_, row) => {
      return `${presence(row?.name)}`;
    },
  },
  {
    title: "Vehicle",
    align: "center",
    render: (_, row) => {
      return `${presence(row.vehicle?.number_plate)}`;
    },
  },
  // {
  //   title: "Slot",
  //   align: "center",
  //   dataIndex: "slot",
  //   render: (_, row) => {
  //     return presence(row.slot);
  //   },
  // },
  {
    title: "Last Entry At",
    align: "center",
    render: (_, row) => {
      return `${formatTime(row.vehicle?.latest_entry_at)}`;
    },
  },

  {
    title: "Status",
    align: "center",
    dataIndex: "status",
    render: (status) => {
      return <StatusComp value={titleize(status)} />;
    },
  },
  {
    title: "Remarks",
    align: "center",
    dataIndex: "remarks",
    render: (_, row) => {
      return presence(row.remarks);
    },
  },

  {
    title: "Manage",
    render: (row) => {
      return (
        <React.Fragment>
          {updateAction && (
            <Button
              onClick={() => editVehicle(row)}
              style={{
                margin: "0px 10px 5px 10px",
              }}
            >
              Edit
            </Button>
          )}
          <Button
            onClick={() => vehicleHistory(row)}
            style={{
              margin: "5px 10px 0px 10px",
            }}
          >
            History
          </Button>
        </React.Fragment>
      );
    },
    align: "center",
  },
];
