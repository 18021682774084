import React, { Component } from "react";
import AppBar from "components/AppBar";
import PaginationTable from "components/paginationTables/withAddEdit";
import { fetchPeopleData } from "api/peoples";
import CRUDUser from "components/form/user/CRUD";
import { Row } from "antd";
import { handleResponse } from "components/notifications/handleResponse";
import { titleize, debounce } from "helpers";

class Peoples extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      total_entries: 0,
      formOpen: false,
      rowData: undefined,
    };
  }

  initialRequestParams = () => {
    return {
      search: "",
      perPage: 25,
      page: 1,
    };
  };
  componentDidMount() {
    this.fetchTableData();
  }

  flushTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  componentWillUnmount = () => {
    this.flushTimer();
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = null;
    this.setState(this.state);
    this.debounceSearch();
  };

  fetchTableData = () => {
    fetchPeopleData(this.requestParams, this.props.peopleType)
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  handleSubmitSuccess = () => {
    this.onCancel();
    setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  onCancel = () => {
    this.setState({
      formOpen: false,
      rowData: undefined,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination, filters) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.requestParams.status = filters.status;
    this.fetchTableData();
  };

  render() {
    const { peopleType } = this.props;
    const { data, formOpen, rowData } = this.state;
    const { onChange, onCancel, handleSubmitSuccess } = this;

    const appBarProps = {
      search: this.requestParams.search,
      searchOpen: true,
      onChange,
    };

    let childProps = this.props.childProps;
    const tableProps = {
      ...childProps,
      data: data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      onRowClick: (row) => {
        this.setState({ formOpen: true, rowData: row });
      },
      dataValue: `ADD ${this.props.peopleType}`,
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
    };

    const CRUDUserProps = {
      titleText: titleize(this.props.peopleType),
      open: formOpen,
      tabColor: this.props.peopleType === "residents" ? "info" : "danger",
      peopleType,
      data: rowData,
      handleSubmitSuccess,
      onCancel,
      showDelete: true,
      dateFilter: true,
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        <CRUDUser {...CRUDUserProps} />
      </React.Fragment>
    );
  }
}
export default Peoples;
