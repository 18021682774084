import React, { Component } from "react";
import VisitorDetails from "./visitorDetails";
import CameraDetails from "./cameraDetails";

export class AlertInfo extends Component {
  render() {
    let { entity, numberPlateOnclick, visitorOnclick } = this.props;

    return (
      <div>
        <VisitorDetails
          entity={entity}
          visitorOnclick={visitorOnclick}
          numberPlateOnclick={numberPlateOnclick}
        />
        {entity?.vehicle_entry?.vehicle_trackings?.length > 0 && (
          <CameraDetails entity={entity} />
        )}
      </div>
    );
  }
}

export default AlertInfo;
