import React from "react";
import { Tooltip, Tag, Button } from "antd";
import { FaRobot } from "react-icons/fa";

import { titleize, presence, timeDiff, formatTime, keyByValue } from "helpers";
import moment from "moment";
import { purpose } from "config";
import { copy } from "components/notifications/handleResponse";
import { MdContentCopy } from "react-icons/md";

export const BotIcon = (bot) => {
  if (bot) {
    return <FaRobot style={{ width: "auto", height: "auto" }} />;
  }
};

const copyPath = (path) => {
  let textField = document.createElement("textarea");
  textField.innerText = path;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
  copy("ID Copied");
};

export const cameraColumns = (editDevice, aboutDevice, selectedCam) => {
  return [
    {
      title: "",
      align: "center",
      width: "7%",
      render: (row) => BotIcon(row.bot_id),
    },
    {
      title: "Name / MAC Address",
      align: "center",
      render: (row) => {
        if (row.name && row.mac_address)
          return (
            <React.Fragment>
              <p style={{ margin: 0 }}>{presence(titleize(row.name))}</p>
              <p style={{ margin: 0, fontWeight: 600 }}>
                {presence(titleize(row.mac_address))}
              </p>
            </React.Fragment>
          );
        else
          return (
            <p style={{ margin: 0 }}>
              {presence(titleize(row.name || row.mac_address))}
            </p>
          );
      },
    },
    {
      title: "Camera Type",
      dataIndex: "type",
      align: "center",
      render: (type) => `${titleize(purpose && keyByValue(purpose, type))}`,
      filters:
        purpose &&
        Object.keys(purpose).map((key) => ({
          text: titleize(key),
          value: purpose[key],
        })),
      filteredValue: selectedCam,
    },
    {
      title: "Last Active",
      dataIndex: "last_data_at",
      align: "center",
      render: (last_data_at) => {
        return (
          <React.Fragment>
            {last_data_at ? (
              <Tooltip title={formatTime(last_data_at)}>
                {timeDiff(moment(), last_data_at)} ago
              </Tooltip>
            ) : (
              " - "
            )}
          </React.Fragment>
        );
      },
    },
    {
      title: "Status",
      align: "center",
      render: (row) => {
        if (row.status === "active") return statusStyle(row.state);
        else return statusStyle(row.status);
      },
    },
    {
      title: "ID",
      dataIndex: "id",
      align: "center",
      render: (id) => {
        return (
          <React.Fragment>
            <Button onClick={() => copyPath(`${id}`)} value={id} size="large">
              <MdContentCopy />
            </Button>
          </React.Fragment>
        );
      },
    },
    {
      title: "Manage",
      render: (row) => {
        return (
          <React.Fragment>
            <Button
              onClick={() => editDevice(row)}
              // disabled={!update_permission}
              style={{
                margin: "0px 10px 5px 10px",
              }}
            >
              Edit
            </Button>

            <Button
              type="primary"
              onClick={() => aboutDevice(row)}
              // disabled={!show_permission}
              style={{
                margin: "0px 10px 5px 10px",
              }}
            >
              Details
            </Button>
          </React.Fragment>
        );
      },
      align: "center",
    },
  ];
};

function statusStyle(state) {
  const style =
    state === "lost_connectivity"
      ? {
          color: "#ffa705",
          borderColor: "#ffa500",
          background: "#fff5e9",
        }
      : state === "working"
      ? {
          color: "#5acf13",
          borderColor: "#47b111",
          background: "#f3fff0",
        }
      : state === "inactive"
      ? {
          color: "#ff0000",
          borderColor: "#ff0000",
          background: "#fff5e9",
        }
      : state === "stopped"
      ? {
          color: "#cf1322",
          borderColor: "#cf1322",
          background: "#fff1f0",
        }
      : {
          color: "#00B7D1 ",
          borderColor: "#36BCCE ",
          background: "#EEF8FA ",
        };
  return (
    <Tag style={{ ...style }}>{`${titleize(state ? state : "Created")}`}</Tag>
  );
}
