import React, { Component } from "react";
import Form from "./Form";
import { updateNotifications, createNotifications } from "api/notifications";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";
import { allNotificationTypes, allNotificationstatus } from "config";
import { fetchListingData } from "api/users";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      open: false,
      data: {
        name: "",
        status: allNotificationstatus[0],
        notification_type: Object.values(allNotificationTypes)[0],
        sms_users: [],
        whatsapp_users: [],
        mail_users: [],
      },
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    let { data } = this.state;
    data[name] = value;
    this.setState({ data });
  };

  requestParams = () => {
    const {
      sms_users,
      whatsapp_users,
      mail_users,
      ...restData
    } = this.state.data;
    let users = {};

    sms_users &&
      sms_users.forEach(({ value }) => {
        users[value] = users[value] || {};
        users[value] = { ...users[value], sms: true };
      });

    whatsapp_users &&
      whatsapp_users.forEach(({ value }) => {
        users[value] = users[value] || {};
        users[value] = { ...users[value], whatsapp: true };
      });

    mail_users &&
      mail_users.forEach(({ value }) => {
        users[value] = users[value] || {};
        users[value] = { ...users[value], mail: true };
      });

    return {
      notifications: {
        ...restData,
        users,
      },
    };
  };

  onSubmit = () => {
    const { id, props, requestParams } = this;
    props.submitClick();
    const method = id ? updateNotifications : createNotifications;
    method(requestParams(), id)
      .then((data) => {
        success(data.message);
        props.handleSubmitSuccess();
        props.submitClick();
      })
      .catch((error) => {
        props.submitClick();
        handleResponse(error);
      });
  };

  componentDidMount = async () => {
    if (this.props.data) {
      const {
        id,
        name,
        status,
        notification_type,
        users_json,
      } = this.props.data;
      let sms_users = [],
        whatsapp_users = [],
        mail_users = [];

      users_json.forEach((user) => {
        if (user.sms) {
          sms_users.push({ label: user.first_name, value: user.id });
        }

        if (user.whatsapp) {
          whatsapp_users.push({ label: user.first_name, value: user.id });
        }

        if (user.mail) {
          mail_users.push({ label: user.first_name, value: user.id });
        }
      });

      this.id = id;
      this.setState({
        data: {
          name,
          status,
          notification_type,
          sms_users,
          whatsapp_users,
          mail_users,
        },
      });
    }
    fetchListingData({ page: 1, per_page: 50, query: "" })
      .then(({ data }) => {
        const users = [];
        data.forEach((value) => {
          if (value.type === "User") {
            users.push({ label: value.first_name, value: value.id });
          }
        });
        this.setState({ data: { ...this.state.data, users } });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    const {
      id,
      handleChange,
      state: { data },
    } = this;
    const formProps = { id, ...data, handleChange };
    return (
      <React.Fragment>
        <Form {...formProps} />
      </React.Fragment>
    );
  }
}
export default DialogForm("Notification")(CRUD);
