import makeRequest from ".";

function generateParams(params = {}, totalEntries = null) {
  let cardStatus = "";
  if (params.status) {
    cardStatus = params.status.map((value) => `&status[]=${value}`).join("");
  }

  return `${cardStatus}&query=${params.search}&page=${params.page ||
    1}&per_page=${totalEntries || params.perPage}`;
}

export function fetchListingData(requestParams) {
  return makeRequest({
    uri: `/api/v1/contractor_cards?${generateParams(requestParams)}`,
  });
}
export function createContractorCard(requestBody) {
  return makeRequest({
    uri: `/api/v1/contractor_cards`,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updateContractorCard(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/contractor_cards/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

export function uploadBulkContractorCard(body) {
  return makeRequest({
    uri: `/api/v1/contractor_cards/import`,
    method: "POST",
    body,
  });
}
