import React from "react";
import { Row, Col, Tabs } from "antd";
import SimpleTextInput from "components/inputs/simpleTextInput";
import { allAlertSettingStatus, allAlertSettingNature } from "config";
import RadioButton from "components/inputs/radioButton.jsx";
import Singleselect from "components/inputs/Singleselect";

export default function Form(props) {
  const {
    name,
    nature,
    status,
    alias_name,
    auto_resolve,
    sms_content,
    whatsapp_content,
    weight,
    handleChange,
    alert_group,
    notification,
    alertGroups,
    notifications,
    id,
    whatsApp,
    emergency,
    description,
  } = props;
  const { TabPane } = Tabs;
  const statusProps = {
    labelText: "Status",
    value: status,
    data: allAlertSettingStatus,
    onChange: handleChange,
    name: "status",
    padding: "0px 6px",
    color: true,
    width: "34%",
  };
  const natureProps = {
    labelText: "Nature",
    value: nature,
    data: allAlertSettingNature,
    onChange: handleChange,
    name: "nature",
    padding: "0px 6px",
    width: "31%",
  };

  const autoResolves = [
    { label: "Disable", value: "disable" },
    { label: "After 3 Hours", value: "after_3_hours" },
    { label: "After 6 Hours", value: "after_6_hours" },
    { label: "After 12 Hours", value: "after_12_hours" },
    { label: "After 24 Hours", value: "after_24_hours" },
  ];

  return (
    <Row style={{ minWidth: "100%", minHeight: !id ? 360 : 415 }}>
      <Tabs
        type="card"
        defaultActiveKey="Details"
        style={{ overflow: "visible" }}
      >
        <TabPane tab="Details" key="Details">
          <Col xs={24} sm={12} style={{ padding: "0px 10px" }}>
            <SimpleTextInput
              labelText="Name"
              name="name"
              required
              value={name}
              onChange={handleChange}
            />
          </Col>
          <Col xs={24} sm={12} style={{ padding: "0px 10px" }}>
            <SimpleTextInput
              readOnly={id ? true : false}
              labelText="Alias Name"
              name="alias_name"
              required
              value={alias_name}
              onChange={handleChange}
            />
          </Col>
          {id && (
            <Col xs={24} sm={24} style={{ padding: "0px 10px" }}>
              <SimpleTextInput readOnly labelText="id" name="id" value={id} />
            </Col>
          )}
          <Col xs={24} sm={24} style={{ padding: "10px" }}>
            <Singleselect
              heading="Alerts Group"
              options={alertGroups}
              selected={alert_group}
              name="alert_group"
              handleChange={handleChange}
            />
          </Col>
          <Col xs={24} sm={12} style={{ padding: "10px" }}>
            <Singleselect
              heading="Notification"
              options={notifications}
              selected={notification}
              name="notification"
              handleChange={handleChange}
            />
          </Col>
          <Col xs={24} sm={12} style={{ padding: "10px" }}>
            <Singleselect
              heading="Auto Resolve"
              options={autoResolves}
              selected={auto_resolve}
              name="auto_resolve"
              handleChange={handleChange}
            />
          </Col>
          <Col
            xs={24}
            sm={12}
            style={{
              padding: "20px 0px",
              textAlign: "center",
              zIndex: 0,
            }}
          >
            <RadioButton {...statusProps} />
          </Col>
          <Col
            xs={24}
            sm={12}
            style={{
              padding: "20px 0px",
              textAlign: "center",
              zIndex: 0,
            }}
          >
            <RadioButton {...natureProps} />
          </Col>
        </TabPane>
        <TabPane tab="Config" key="Config">
          <div>
            <p style={{ fontSize: "17px" }}>Priority</p>
            <Col xs={24} sm={8} style={{ padding: "0px 10px" }}>
              <SimpleTextInput
                labelText="Raised"
                name="weight.raised"
                required
                value={weight.raised}
                onChange={handleChange}
              />
            </Col>
            <Col xs={24} sm={8} style={{ padding: "0px 10px" }}>
              <SimpleTextInput
                labelText="Resolved"
                name="weight.resolved"
                required
                value={weight.resolved}
                onChange={handleChange}
              />
            </Col>
            <Col xs={24} sm={8} style={{ padding: "0px 10px" }}>
              <SimpleTextInput
                labelText="False Alert"
                name="weight.false_alert"
                required
                value={weight.false_alert}
                onChange={handleChange}
              />
            </Col>
          </div>
          <Col xs={24} sm={24}>
            <SimpleTextInput
              labelText="Description"
              name="description"
              value={description}
              onChange={handleChange}
              rows={3}
              multiline={true}
            />
          </Col>
          <Col
            xs={24}
            sm={12}
            style={{
              padding: "20px 10px",
              textAlign: "center",
              zIndex: 0,
            }}
          >
            <RadioButton
              labelText="Emergency"
              value={emergency || false}
              data={{ No: false, yes: true }}
              onChange={handleChange}
              name="emergency"
              color={true}
            />
          </Col>
        </TabPane>
        <TabPane tab="WhatsApp" key="WhatsApp">
          <Col xs={24} sm={24} style={{ paddingTop: "10px" }}>
            <SimpleTextInput
              readOnly={id && whatsApp.raised ? true : false}
              labelText="Raised"
              name="whatsapp_content.raised"
              value={whatsapp_content.raised}
              onChange={handleChange}
              rows={3}
              multi
            />
          </Col>
          <Col xs={24} sm={24}>
            <SimpleTextInput
              readOnly={id && whatsApp.resolved ? true : false}
              labelText="Resolved"
              name="whatsapp_content.resolved"
              value={whatsapp_content.resolved}
              onChange={handleChange}
              rows={3}
              multi
            />
          </Col>
          <Col xs={24} sm={24}>
            <SimpleTextInput
              readOnly={id && whatsApp.false_alert ? true : false}
              labelText="False Alert"
              name="whatsapp_content.false_alert"
              value={whatsapp_content.false_alert}
              onChange={handleChange}
              rows={3}
              multi
            />
          </Col>
        </TabPane>
        <TabPane tab="SMS" key="SMS">
          <Col xs={24} sm={24} style={{ paddingTop: "10px" }}>
            <SimpleTextInput
              labelText="Raised"
              name="sms_content.raised"
              value={sms_content.raised}
              onChange={handleChange}
              rows={3}
              multi
            />
          </Col>
          <Col xs={24} sm={24}>
            <SimpleTextInput
              labelText="Resolved"
              name="sms_content.resolved"
              value={sms_content.resolved}
              onChange={handleChange}
              rows={3}
              multi
            />
          </Col>
          <Col xs={24} sm={24}>
            <SimpleTextInput
              labelText="False Alert"
              name="sms_content.false_alert"
              value={sms_content.false_alert}
              onChange={handleChange}
              rows={3}
              multi
            />
          </Col>
        </TabPane>
      </Tabs>
    </Row>
  );
}
