import React, { Component } from "react";
import EditProfile from "./Sections/EditProfile";
import HomePage from "./Sections/Homepage";
import ChangePassword from "./Sections/ChangePassword";
import AppBar from "components/AppBar";
import { setCookie } from "helpers";
import { Row, Col } from "antd";
import { profile, submitProfile } from "api/userProfile";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      // reports: []
    };
  }

  handleChange = (key, value) => {
    this.setState({ [key]: value });
  };

  requestParams = () => {
    const { first_name, last_name, email, phone } = this.state;
    return {
      first_name,
      last_name,
      email,
      phone,
    };
  };

  handleSubmit = async () => {
    await submitProfile(this.requestParams())
      .then(({ data: { first_name, last_name, email, auth_token } }) => {
        success("Updated Successfully.!!!");
        first_name && setCookie("first_name", first_name, 60);
        last_name && setCookie("last_name", last_name, 60);
        email && setCookie("email", email, 60);
        auth_token && setCookie("token", auth_token);
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  handleCheckBoxChange = (type, id, event) => {
    var index = this.state[type].indexOf(id);
    var updated = this.state[type];

    if (event.target.checked) {
      if (index === -1) {
        updated.push(id);
      }
    } else {
      if (index !== -1) {
        updated.splice(index, 1);
      }
    }
    this.setState({ [type]: updated });
  };

  componentDidMount = async () => {
    await profile()
      .then(
        ({
          data: { first_name, last_name, email, phone, home_page, reports },
        }) => {
          setCookie("home", home_page);
          this.setState({
            first_name,
            last_name,
            email,
            phone,
            home: home_page,
          });
        }
      )
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    const { first_name, last_name, email, phone } = this.state;
    const { handleSubmit, handleChange, handleRef } = this;
    const profileProps = {
      first_name,
      last_name,
      email,
      phone,
      handleSubmit,
      handleChange,
      handleRef,
    };
    return (
      <React.Fragment>
        <AppBar />
        <Row>
          <Col xs={24} lg={12} md={24} style={{ paddingTop: "10px" }}>
            <Row xs={24} lg={24} md={24}>
              <EditProfile {...profileProps} />
              <HomePage />
              <ChangePassword />
            </Row>
          </Col>
          {/* <Col xs={24} lg={12} md={24} style={{ paddingTop: "10px" }}>
            <Row xs={24} lg={24} md={24}>
              <Reports handleChange={handleCheckBoxChange} selected={reports} />
            </Row>
          </Col> */}
        </Row>
      </React.Fragment>
    );
  }
}

export default UserProfile;
