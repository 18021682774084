import React, { Component } from "react";
import Forms from "./Form";
import { updateAlertScreen, fetchAlerts } from "api/alertScreen";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      data: {
        alert_types: [],
        sound: "",
        title: "",
        subtitle: "",
        header: "",
        footer: "",
        disabled: "",
        visibility: "",
        url: "",
        icon_url: "",
      },
      alias_name: [],
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    let { data } = this.state;
    data[name] = value;
    this.setState({ data });
  };

  requestParams = () => {
    const {
      alert_types,
      sound,
      title,
      subtitle,
      header,
      footer,
      disabled,
      visibility,
      url,
      icon_url,
    } = this.state.data;

    return {
      presentations: {
        alert_types: alert_types && alert_types.map((i) => i.value),
        sound,
        title,
        subtitle,
        header,
        footer,
        disabled,
        visibility,
        url,
        icon_url,
      },
    };
  };
  fetchAlerts = () => {
    fetchAlerts(this.requestParams)
      .then(({ data }) => {
        let alertData = data.map((name) => {
          return { value: name.alias_name, label: name.alias_name };
        });
        this.setState({
          alias_name: alertData,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    this.fetchAlerts();
    if (this.props.data) {
      const {
        alert_types,
        sound,
        title,
        subtitle,
        header,
        footer,
        disabled,
        visibility,
        url,
        icon_url,
      } = this.props.data;
      this.id = this.props.data.id;
      this.setState({
        data: {
          alert_types:
            alert_types &&
            alert_types.map((alert) => {
              return { value: alert, label: alert };
            }),
          sound,
          title,
          subtitle,
          header,
          footer,
          disabled,
          visibility,
          url,
          icon_url,
        },
      });
    }
  };

  onSubmit = () => {
    const { id, props, requestParams } = this;
    props.submitClick();
    if (id) {
      updateAlertScreen(requestParams(), id)
        .then((data) => {
          success(data.message);
          this.props.handleSubmitSuccess();
          props.submitClick();
        })
        .catch((error) => {
          props.submitClick();
          handleResponse(error);
        });
    }
  };

  render() {
    const { handleChange, id } = this;
    const { data, alias_name } = this.state;
    const formProps = { id, data, handleChange, alias_name };
    return <Forms {...formProps} />;
  }
}
export default DialogForm("Alert Screen", "40%")(CRUD);
