import React, { Component } from "react";
import Form from "./Form";
import { updateReports, createReports } from "api/reports";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";
import { allReportTypes, allReportStatus } from "config";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      open: false,
      data: {
        name: "",
        status: allReportStatus[0],
        report_type: Object.values(allReportTypes)[0],
        users: [],
      },
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    let { data } = this.state;
    data[name] = value;
    this.setState({ data });
  };

  requestParams = () => {
    const { users, ...reportData } = this.state.data;
    let user_ids = users && users.map((person) => person.value);

    return {
      ...reportData,
      user_ids,
    };
  };

  onSubmit = () => {
    const { id, props } = this;
    props.submitClick();
    const action = !id ? createReports : updateReports;
    action({ reports: this.requestParams() }, id)
      .then((data) => {
        success(data.message);
        this.props.handleSubmitSuccess();
        props.submitClick();
      })
      .catch((error) => {
        props.submitClick();
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    if (this.props.data) {
      let { id, name, status, report_type, users } = this.props.data;

      users = users.map((user_ids) => {
        return { value: user_ids.id, label: user_ids.first_name };
      });

      this.id = id;
      this.setState({
        data: { ...this.state.data, name, status, report_type, users },
      });
    }
  };
  render() {
    const { id, handleChange } = this;
    const { data } = this.state;
    const formProps = { id, ...data, handleChange };
    return (
      <React.Fragment>
        <Form {...formProps} />
      </React.Fragment>
    );
  }
}
export default DialogForm("Reports")(CRUD);
