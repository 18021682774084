import React, { Component } from "react";
import { Col, Tooltip, Button, Modal, Select } from "antd";
import Header from "./sections/Header";
import { siteName, enableAnnouncement } from "config";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import warning from "assets/img/warning.png";
import noWarning from "assets/img/noWarning.png";
import polygon from "assets/img/poly-1.png";
import polygonError from "assets/img/poly-2.png";
import Tick from "assets/img/warning_pic.png";
import { MdClose } from "react-icons/md";
import { refreshAPI } from "helpers";
import { menu } from "./data/alert-menus";
import {
  updateFalseAlert,
  updateResolved,
  fetchAlertTypes,
  alertAggregation,
  getEmergencyAlertList,
} from "api/alerts";
import Announcement from "./sections/Announcement";
import Duplicate from "./Duplicate";
import SimpleTextInput from "components/inputs/simpleTextInput";
import { AlertResolveOptions } from "config";

const { Option } = Select;

class Alert extends Component {
  constructor(props) {
    super(props);
    this.id = "";
    this.refreshAPI = refreshAPI.bind(this);
    this.alertMenus = [];
    this.state = {
      alert_ids: [],
      alarm: false,
      alerts: {},
      loading: false,
      visible: false,
      sos: "",
      doc_count: "",
      sosAlert: [],
      id: "",
      alertMenus: [],
      alias_name: [],
      resolve_msg: AlertResolveOptions[0],
      remark_msg: "",
    };
  }

  handleChange = (e) => {
    this.setState({ remark_msg: e.target.value });
  };

  handleChangeSelect = (value) => {
    this.setState({ resolve_msg: value, remark_msg: "" });
  };

  async componentDidMount() {
    this.alertMenus = await menu();
    await this.fetchEmergencyAlertTypes();
    await this.fetchEmergencyAlerts();
    await this.fetchAlerts();
    this.refreshAPI(this.fetchAlerts, 10 * 1000, "alerts", 30000);
    document.getElementsByClassName(
      "content-inside"
    )[0].style.paddingBottom = 0;
    var footer = document.getElementById("footer");
    if (footer) {
      footer.remove();
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  fetchAlerts = async () => {
    await alertAggregation()
      .then(({ data }) => {
        let visible = false;
        let alerts = {};
        let alert_ids = [];
        const { alertMenus } = this;
        data.forEach(({ key, doc_count }) => {
          let alertIdList = this.state.alias_name.filter((val) => key === val);
          if (alertIdList.length !== 0) {
            visible = true;
            this.fetchEmergencyAlerts();
          }
          alertMenus.forEach((menu) => {
            if (
              !menu.disabled &&
              menu.alert_types &&
              menu.alert_types.some((alert) => alert === key)
            ) {
              alerts[menu.id] = alerts[menu.id]
                ? alerts[menu.id] + doc_count
                : doc_count;

              if (menu.sound) {
                alert_ids.push({ key: key, doc_count, sound: true });
              }
            }
          });
        });

        let alarm = false;
        if (alert_ids.length === 1 && this.state.alert_ids.length === 0) {
          alarm = true;
        } else {
          if (this.state.alert_ids.length > 0) {
            if (alert_ids.length > this.state.alert_ids.length) {
              alarm = true;
            } else {
              for (let i = 0; i < alert_ids.length; i++) {
                for (let j = 0; j < this.state.alert_ids.length; j++) {
                  if (
                    alert_ids[i].key === this.state.alert_ids[j].key &&
                    alert_ids[i].doc_count > this.state.alert_ids[j].doc_count
                  ) {
                    alarm = true;
                  }
                }
              }
            }
          }
        }

        this.setState({
          alerts,
          alert_ids,
          alarm,
          visible,
        });
      })
      .catch(handleResponse);
  };

  fetchEmergencyAlertTypes = async () => {
    await fetchAlertTypes()
      .then(({ data }) => {
        let emergency_alert_name = data.filter(
          (i) => i.emergency === true && i.status === "active"
        );
        this.setState({
          alias_name: emergency_alert_name.map((val) => val.alias_name),
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  fetchEmergencyAlerts = async () => {
    const { alias_name } = this.state;
    await getEmergencyAlertList(alias_name)
      .then(({ data }) => {
        this.setState({ sosAlert: data });
      })
      .catch(handleResponse);
  };

  goAlerts = () => {
    this.props.history.push("/alerts/emergency_alerts");
  };

  handleFalseAlertData = () => {
    if (this.state.sosAlert.some((sos) => sos.type === this.state.sos)) {
      updateFalseAlert(this.state.sosAlert[0].id)
        .then(() => {
          success("Updated Successfully..!");
          this.fetchAlerts();
        })
        .catch((error) => {
          handleResponse(error);
        });
    }
    this.setState({ visible: false });
  };

  handleResolvedData = (id) => {
    const { resolve_msg, sosAlert, remark_msg } = this.state;
    const remark_message =
      remark_msg.length === 0 ? resolve_msg : `${resolve_msg} : ${remark_msg}`;
    updateResolved(id, remark_message)
      .then(() => {
        success("Updated Successfully..!");
        this.setState({
          resolve_msg: AlertResolveOptions[0],
          sosAlert: sosAlert.shift(),
          remark_msg: "",
        });
        this.fetchAlerts();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    const {
      visible,
      sosAlert,
      resolve_msg,
      remark_msg,
      alarm,
      loading,
      alerts,
    } = this.state;
    const circleDiameter = (window.innerWidth / 24) * 4;
    const isAlertPresent = !!Object.keys(alerts).length;
    const alertMsg = !isAlertPresent
      ? "All systems are normal"
      : `${Object.keys(alerts).length} System has alerts`;
    const { goAlerts, handleChange, handleChangeSelect } = this;
    const req =
      resolve_msg === "Others" || resolve_msg === "Wrong Entry" ? true : false;
    return (
      <React.Fragment>
        {visible && sosAlert && sosAlert.length >= 1 && (
          <Modal
            className="Modal-1"
            visible={visible}
            closable={false}
            width="30%"
            footer={false}
            style={{
              maxHeight: "70%",
              fontWeight: "bolder",
              fontSize: "24px",
              minHeight: "50%",
            }}
          >
            <div
              style={{
                padding: "0px",
                backgroundColor: "#f03449",
                display: "inline-block",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <img
                alt={"Tick"}
                src={Tick}
                width="75px"
                height="70px"
                style={{ display: "block", margin: "7px auto" }}
              />
            </div>
            <div style={{ padding: "15px 30px" }}>
              <div
                style={{
                  display: "inline-block",
                  width: "100%",
                  textAlign: "center",
                  padding: "0px 0px 15px",
                }}
              >
                <h1
                  style={{
                    textAlign: "center",
                    color: "#f03449",
                    fontWeight: "bolder",
                  }}
                >{`${sosAlert[0].alert_type_name || ""}`}</h1>
                <p style={{ fontSize: "18px", fontWeight: "normal" }}>
                  {sosAlert[0].description}
                </p>
                <Select
                  value={resolve_msg}
                  defaultValue={AlertResolveOptions[0]}
                  onChange={(e) => handleChangeSelect(e)}
                  style={{ width: "80%" }}
                >
                  {AlertResolveOptions.map((o, i) => {
                    return (
                      <Option key={i} value={o}>
                        {o}
                      </Option>
                    );
                  })}
                </Select>
                <div style={{ marginTop: "10px" }}>
                  <SimpleTextInput
                    labelText="Remarks"
                    name="remark_msg"
                    value={remark_msg}
                    onChange={handleChange}
                    multi={true}
                    rows={2}
                    required={req}
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "0px 0px 10px",
                }}
              >
                <Button
                  key="submit"
                  type="primary"
                  disabled={
                    req ? (remark_msg.length === 0 ? true : false) : false
                  }
                  loading={loading}
                  onClick={() => this.handleResolvedData(sosAlert[0].id)}
                  style={{
                    background: "#4ac851",
                    color: "white",
                    borderColor: "#4ac851",
                    height: "40px",
                    fontSize: "16px",
                  }}
                >
                  Resolve
                </Button>
              </div>
            </div>
            {sosAlert.length > 1 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px",
                  borderTop: "1px solid lightgray",
                  fontSize: "16px",
                }}
              >
                <p
                  style={{ color: "#f03449", fontWeight: "bold" }}
                >{`${sosAlert.length - 1} `}</p>
                <p style={{ fontWeight: "normal" }}>
                  {" "}
                  {` - More Emergency Alert`}
                </p>
              </div>
            )}
          </Modal>
        )}
        {alarm && (
          <audio
            width="0px"
            height="0px"
            src="/alert.mp3"
            onEnded={() => this.setState({ alarm: false })}
            autoPlay
          />
        )}

        <Header />
        {enableAnnouncement && <Announcement />}
        <Col
          style={{
            display: "flex",
            flexWrap: "wrap",
            minHeight: window.innerHeight - 92,
            paddingTop: 8,
            paddinngBottom: 5,
          }}
        >
          <ListAlerts
            align="left"
            history={this.props.history}
            alerts={this.state.alerts}
            alertMenus={this.alertMenus}
          />

          <Col
            lg={4}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                minWidth: 400,
                minHeight: 400,
                width: circleDiameter,
                height: circleDiameter,
                borderRadius: Math.max(circleDiameter, 400) / 2,
                border: `8px solid ${isAlertPresent ? "#e63221" : "#1C18E5"}`,
                padding: 10,
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#DFE1F3",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              }}
            >
              <h1>{siteName}</h1>
              <img
                width="50%"
                height="50%"
                src={isAlertPresent ? warning : noWarning}
                alt="warning"
              />
              <h3 style={{ marginTop: 10 }}>{alertMsg}</h3>
            </div>
          </Col>
          <ListAlerts
            align="right"
            history={this.props.history}
            alerts={this.state.alerts}
            alertMenus={this.alertMenus}
          />
        </Col>
        <div
          style={{
            width: 50,
            position: "fixed",
            bottom: 50,
            left: 60,
            cursor: "pointer",
          }}
        >
          <Tooltip title={"Exit Presentation"}>
            <Button
              shape="circle"
              style={{ border: "2px solid #245de5", width: 45, height: 45 }}
              onClick={goAlerts}
            >
              <MdClose
                style={{ color: "#245de5", fontSize: "22px", margin: "9px" }}
              />
            </Button>
          </Tooltip>
        </div>
      </React.Fragment>
    );
  }
}

export default Alert;

class ListAlerts extends Component {
  footerMsg = (count, msg) => {
    const alert = count === 1 ? "alert" : "alerts";
    return count ? `${count} ${alert}` : msg;
  };
  render() {
    const { align, alertMenus } = this.props;
    return (
      <Col
        lg={10}
        style={{
          padding: "20px 0px",
          display: alertMenus.length === 0 ? "block" : "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          alignItems: align === "left" ? "flex-end" : "flex-start",
          justifyContent: "space-between",
          direction:
            alertMenus.length === 0 ? (align === "left" ? "rtl" : "") : "",
        }}
      >
        {alertMenus.length !== 0 ? (
          alertMenus
            .filter((o) => o.align === align)
            .map((menu, index) => {
              const marR = {
                0: "0px",
                1: "100px",
                2: "210px",
                3: "210px",
                4: "100px",
                5: "0px",
              };
              const marRL = {
                0: "0px",
                1: "0px",
                2: "0px",
                3: "35px",
                4: "25px",
                5: "0px",
              };
              const padding =
                align === "left"
                  ? `${marRL[index]} ${marR[index]} 0px 0px`
                  : `${marRL[index]} 0px 0px ${marR[index]}`;
              return (
                <div
                  key={index}
                  style={{
                    width: "100%",
                    height: 110,
                    padding,
                    marginTop: index === 0 ? 0 : 30,
                    display: "flex",
                    justifyContent:
                      align === "left" ? "flex-end" : "flex-start",
                    alignItems: "center",
                  }}
                >
                  {
                    {
                      left: (
                        <React.Fragment>
                          <RectangularBox
                            align={align}
                            {...menu}
                            footer={this.footerMsg(
                              this.props.alerts[menu.id],
                              menu.footer
                            )}
                            history={this.props.history}
                            error={!!this.props.alerts[menu.id]}
                          />
                          <Hexagon
                            {...menu}
                            history={this.props.history}
                            error={!!this.props.alerts[menu.id]}
                          />
                        </React.Fragment>
                      ),
                      right: (
                        <React.Fragment>
                          <Hexagon
                            {...menu}
                            history={this.props.history}
                            error={!!this.props.alerts[menu.id]}
                          />
                          <RectangularBox
                            align={align}
                            {...menu}
                            footer={this.footerMsg(
                              this.props.alerts[menu.id],
                              menu.footer
                            )}
                            history={this.props.history}
                            error={!!this.props.alerts[menu.id]}
                          />
                        </React.Fragment>
                      ),
                    }[align]
                  }
                </div>
              );
            })
        ) : (
          <Duplicate align={align} />
        )}
      </Col>
    );
  }
}

const Hexagon = ({ icon, error, disabled, url, history, visibility }) => {
  const bgImg = error ? polygonError : polygon;
  return (
    <div
      onClick={() => !disabled && history.push(url())}
      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        width: 120,
        height: 150,
        padding: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: disabled ? "default" : "pointer",
        opacity: disabled ? 0.3 : 1,
        visibility: visibility ? "visible" : "hidden",
      }}
    >
      <img width="100%" src={icon} alt="" />
    </div>
  );
};

const RectangularBox = ({
  align,
  header,
  footer,
  disabled,
  url,
  history,
  visibility,
}) => {
  let style;
  switch (align) {
    case "left":
      style = {
        borderRadius: "10px 0px 0px 10px",
        marginRight: -2,
      };
      break;
    case "right":
      style = {
        borderRadius: "0px 10px 10px 0px",
        marginLeft: -2,
      };
      break;
    default:
      break;
  }
  return (
    <div
      onClick={() => !disabled && history.push(url())}
      style={{
        width: 320,
        height: 75,
        backgroundColor: "#DFE1F3",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        boxShadow: "2px 4px 4px rgba(0, 0, 0, 0.25)",
        opacity: disabled ? 0.3 : 1,
        cursor: disabled ? "default" : "pointer",
        visibility: visibility ? "visible" : "hidden",
        ...style,
      }}
    >
      <h3 style={{ margin: "5px 0px", fontWeight: "bolder" }}>{header}</h3>
      <div
        style={{
          height: 1,
          width: "80%",
          background: "rgba(28, 24, 229, 0.5)",
        }}
      />
      <h5 style={{ paddingTop: 5, margin: 0 }}>{footer}</h5>
    </div>
  );
};
