import React from "react";
import { Modal } from "antd";
import { Slider, InputNumber, Row, Col, Switch, Button } from "antd";

import MicRecorder from "mic-recorder-to-mp3";
import Record from "assets/img/preview.png";
import Recording from "assets/img/recording.png";
import { announcement } from "api/wemas";
import {
  handleResponse,
  success,
  error,
} from "components/notifications/handleResponse";

class VoiceMsg extends React.Component {
  constructor(props) {
    super(props);
    this.file = null;
    this.micAction = "Start";
    this.recorder = new MicRecorder({
      bitRate: 128,
    });
    this.state = {
      micAction: "Start",
      recording: false,
      sec: 0,
      message: "",
      volume: 93,
      repeat: 1,
      syren: true,
      duration: 3,
    };
    this.timer = null;
    this.timeInterval = null;
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.timeInterval) {
      clearTimeout(this.timeInterval);
    }
  }

  startRecording = () => {
    this.recorder
      .start()
      .then(() => {
        this.setState({ recording: true, micAction: "Stop" });
        this.timeInterval = setInterval(
          () => this.setState({ sec: this.state.sec + 1 }),
          1000
        );
        this.timer = setTimeout(this.stopRecording, 1000 * 31);
      })
      .catch((e) => {
        console.error(e);
        if (e.name === "NotAllowedError") {
          this.setState({ micAction: "Not Allowed" });
          error("Please Allow the mike");
        }
      });
  };

  stopRecording = () => {
    clearTimeout(this.timeInterval);
    this.setState({ micAction: "processing..." });
    this.recorder
      .stop()
      .getMp3()
      .then(([buffer, blob]) => {
        this.setState({ recording: false, micAction: "Done" });
        this.file = new File(buffer, Date.now() + "_cmd.mp3", {
          type: blob.type,
          lastModified: Date.now(),
        });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  handleSubmit = () => {
    if (this.state.micAction !== "Done") {
      return;
    }
    const { volume, repeat, syren, duration } = this.state;
    const formData = new FormData();
    formData.append("file", this.file);
    formData.append("volume", volume);
    formData.append("repeat", repeat);
    formData.append("syren", syren);
    formData.append("duration", duration);
    announcement(this.props.mac_address, formData)
      .then(() => {
        success("Successfully Sent...");
        this.props.onFinish();
      })
      .catch((err) => {
        handleResponse(err);
        console.log(err);
      });
  };

  handleCancel = () => {
    this.recorder.stop();
    this.props.onFinish();
  };

  handleClick = () => {
    switch (this.state.micAction) {
      case "Start":
        this.startRecording();
        break;
      case "Stop":
        this.stopRecording();
        break;
      default:
        break;
    }
  };

  render() {
    const { volume, repeat, syren, duration } = this.state;
    return (
      <Modal
        title={this.props.title || "Control System"}
        visible={true}
        okButtonProps={{
          disabled: this.state.micAction !== "Done",
        }}
        onOk={this.handleSubmit}
        onCancel={this.handleCancel}
        okText="Send"
      >
        <Row>
          <Col
            span={24}
            style={{
              marginBottom: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={this.state.recording ? Recording : Record}
              style={{ width: 60, margin: 0 }}
              alt=""
            />
            <h3>
              {this.state.sec < 10
                ? `00:0${this.state.sec}`
                : `00:${this.state.sec}`}
            </h3>
            <Button style={{ width: "100%" }} onClick={this.handleClick}>
              {this.state.micAction}
            </Button>
          </Col>
          <Col span={24} style={{ marginBottom: 20 }}>
            <Row>
              <Col span={4}>
                <h4>Syren </h4>
                <Switch
                  checked={syren}
                  onChange={(val) => this.setState({ syren: val })}
                />
              </Col>
              <Col span={6}>
                <h4>Duration</h4>
                <InputNumber
                  disabled={!syren}
                  min={1}
                  max={30}
                  value={duration}
                  onChange={(val) => this.setState({ duration: val })}
                />
              </Col>
              <Col span={6}>
                <h4>Repeat</h4>
                <InputNumber
                  min={1}
                  max={10}
                  value={repeat}
                  onChange={(val) => this.setState({ repeat: val })}
                />
              </Col>
              <Col span={8}>
                <h4>Volume</h4>
                <Slider
                  min={10}
                  max={100}
                  onChange={(val) => this.setState({ volume: val })}
                  value={volume}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default VoiceMsg;
