import React from "react";
import SimpleTextInput from "components/inputs/simpleTextInput";
import { Row, Col } from "antd";
import MultiSelect from "components/inputs/Multiselect";
import { fetchVehicleData } from "api/vehicles";
import { handleResponse } from "components/notifications/handleResponse";

function Form({ name, phone, vehicles, handleChange, handleCreate }) {
  const vehicleData = async (search) =>
    await fetchVehicleData({ perPage: 5, search })
      .then(({ data }) =>
        data.map((value) => ({ label: value.number_plate, value: value.id }))
      )
      .catch((error) => {
        handleResponse(error);
      });
  return (
    <React.Fragment>
      <Row style={{ padding: "10px" }}>
        <Row>
          <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
            <SimpleTextInput
              labelText="Name"
              name="name"
              value={name}
              type="text"
              onChange={handleChange}
            />
          </Col>
          <Col item xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
            <SimpleTextInput
              labelText="Phone"
              name="phone"
              value={phone}
              onChange={handleChange}
              type="text"
            />
          </Col>
        </Row>
        <Col xs={24} sm={24} style={{ padding: "10px 10px" }}>
          <MultiSelect
            async
            allowCreate
            name="vehicles"
            selected={vehicles}
            heading={"Number Plate"}
            dataPromise={vehicleData}
            handleChange={handleChange}
            handleCreate={handleCreate}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
}
export default Form;
