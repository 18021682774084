import makeRequest from ".";

function generateParams(params = {}, totalEntries = null) {
  return `query=${params.search}&page=${params.page ||
    1}&per_page=${totalEntries || params.perPage}`;
}

export function companyData(requestParams) {
  if (requestParams) {
    return makeRequest({
      uri: `/api/v1/companies?${generateParams(requestParams)}`,
    });
  } else {
    return makeRequest({
      uri: `/api/v1/companies/list`,
    });
  }
}

export function getCompanyData(id) {
  return makeRequest({
    uri: `/api/v1/companies/${id}`,
  });
}

export function createCompany(requestBody) {
  return makeRequest({
    uri: "/api/v1/companies",
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updateCompany(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/companies/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

export function getVisits(requestParams, id) {
  return makeRequest({
    uri: `/api/v1/visits?company_id=${id}&from_time=${
      requestParams.fromTime
    }&to_time=${requestParams.toTime}&page=${requestParams.page ||
      1}&per_page=${requestParams.perPage}`,
  });
}
