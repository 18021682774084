import React from "react";
import { MdUpdate } from "react-icons/md";
import {
  Button,
  Card,
  CardIcon,
  CardHeader,
  CardBody,
  CardFooter,
} from "material-dashboard-react/components/index";
import { withStyles } from "@material-ui/core/styles";
import { Row, Col } from "antd";
import SimpleTextInput from "components/inputs/simpleTextInput";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.79)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const Input = ({ onChange, labelText, name, value }) => {
  return (
    <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px " }}>
      <SimpleTextInput
        required
        {...{
          labelText,
          name,
          value,
          onChange,
        }}
      />
    </Col>
  );
};

const EditProfile = ({
  first_name,
  last_name,
  email,
  phone,
  handleChange,
  handleSubmit,
}) => {
  const onChange = (e) => {
    const { name, value } = e.target;
    handleChange(name, value);
  };

  return (
    <div>
      <Row xs={24} sm={24} md={24} style={{ padding: "0px 15px" }}>
        <Card>
          <CardHeader color="warning" stats icon>
            <CardIcon
              color="primary"
              style={{ padding: "6px", borderRadius: "6px" }}
            >
              <MdUpdate />
            </CardIcon>
            <p
              style={{
                textAlign: "left",
                color: "#9b34b2",
                fontSize: "18px",
                marginTop: "10px",
              }}
            >
              Update Profile
            </p>
          </CardHeader>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <CardBody>
              <Row>
                <Input
                  labelText="First Name"
                  name="first_name"
                  value={first_name}
                  onChange={onChange}
                />
                <Input
                  labelText="Last Name"
                  name="last_name"
                  value={last_name}
                  onChange={onChange}
                />
              </Row>
              <Row>
                <Input
                  labelText="Email"
                  name="email"
                  value={email}
                  onChange={onChange}
                />
                <Input
                  labelText="Mobile"
                  name="phone"
                  value={phone}
                  onChange={onChange}
                />
              </Row>
            </CardBody>
            <CardFooter
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingBottom: "20px",
                paddingRight: "15px",
              }}
            >
              <Button
                type="submit"
                style={{
                  backgroundColor: "white",
                  color: "green",
                  border: "1px solid green",
                }}
              >
                Update Profile
              </Button>
            </CardFooter>
          </form>
        </Card>
      </Row>
    </div>
  );
};

export default withStyles(styles)(EditProfile);
