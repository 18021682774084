import React from "react";
import { titleize, presence } from "helpers";
import { StatusComp } from "data/index";

const renderTypeTitle = (type) => {
  type = type.replace(/\./g, " ");
  type = type.replace(/_/g, "");
  type = type.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
  return type;
};

export const columns = [
  {
    title: "Position",
    dataIndex: "position",
    width: "10%",
    align: "center",
    render: (pos) => presence(pos),
  },
  {
    title: "Title",
    dataIndex: "title",
    width: "30%",
    align: "center",
    render: (name) => presence(titleize(name)),
  },
  {
    title: "Alert Types",
    dataIndex: "alert_types",
    width: "30%",
    align: "center",
    render: (alert_group) => {
      if (alert_group.length > 0)
        return alert_group.map((alert_type, i) => (
          <p key={{ i }}>{titleize(renderTypeTitle(alert_type))}</p>
        ));
      else return <p>-</p>;
    },
  },
  {
    title: "Align",
    dataIndex: "align",
    align: "center",
    render: (_, row) => {
      const backgroundColor = row.align === "left" ? "#2196f3" : "#673ab7";
      return (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              color: "#fff",
              backgroundColor,
              padding: "6px 15px 5px",
              borderRadius: 50,
              marginLeft: 5,
              whiteSpace: "nowrap",
              marginTop: "4px",
              marginBottom: "4px",
            }}
          >
            {titleize(row.align)}
          </span>
        </div>
      );
    },
  },
  {
    title: "Status",
    align: "center",
    render: (row) => {
      if (row.visibility && row.disabled)
        return <StatusComp value={"normal"} />;
      else if (row.visibility && !row.disabled)
        return <StatusComp value={true} />;
      else return <StatusComp value={false} />;
    },
  },
];
