import React from "react";
import SimpleTextInput from "components/inputs/simpleTextInput";
import { Row, Col } from "antd";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import { FormControl, Select, InputLabel } from "@material-ui/core";
import { MdDelete, MdAdd } from "react-icons/md";

function Form({
  name,
  phone,
  vehicles,
  handleChange,
  handleAddNew,
  handleDelete,
  peopleType,
}) {
  return (
    <React.Fragment>
      <Row style={{ padding: "10px" }}>
        <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="Name"
            name="name"
            value={name}
            onChange={handleChange}
          />
        </Col>
        <Col item xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="Phone"
            name="phone"
            value={phone}
            onChange={handleChange}
          />
        </Col>
      </Row>
      <Row spacing={3} style={{ padding: 10 }}>
        <Col
          item
          sm={24}
          style={{
            flexDirection: "row",
            display: "flex",
            height: "100px",
          }}
        >
          <h2 style={{ margin: "10px" }}>Vehicles</h2>
          <Fab
            color="primary"
            aria-label="add"
            style={{ marginLeft: 8 }}
            onClick={handleAddNew}
          >
            <MdAdd style={{ fontSize: "25px" }} />
          </Fab>
        </Col>

        {vehicles &&
          vehicles.map((vehicle, i) => (
            <Row
              key={i}
              spacing={3}
              style={{
                padding: 1,
                maxHeight: 250,
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Col xs={7} sm={7} md={7} style={{ padding: "10px 10px" }}>
                <SimpleTextInput
                  labelText="Number Plate"
                  name="number_plate"
                  required
                  value={vehicle.number_plate}
                  onChange={(e) => {
                    handleChange(e, i, "number_plate");
                  }}
                />
              </Col>
              {peopleType === "visitors" && (
                <Col xs={7} sm={7} md={7} style={{ padding: "10px 10px" }}>
                  <FormControl required style={{ minWidth: "100%" }}>
                    <InputLabel
                      style={{ fontSize: "14px" }}
                      htmlFor="vehicle_type"
                    >
                      Vehicle Type
                    </InputLabel>
                    <Select
                      style={{
                        borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
                      }}
                      native
                      value={vehicle.vehicle_type}
                      onChange={(e) => {
                        handleChange(e, i, "vehicle_type");
                      }}
                      inputProps={{
                        name: "vehicle_type",
                        required: true,
                        id: "vehicle_type",
                      }}
                    >
                      <option value="" disabled />
                      <option value={"normal"}>Normal</option>
                      <option value={"taxi"}>Taxi</option>
                    </Select>
                  </FormControl>
                </Col>
              )}
              <Col
                xs={3}
                sm={3}
                md={3}
                style={{ marginTop: "20px", textAlign: "right" }}
              >
                <IconButton onClick={() => handleDelete(i)} aria-label="Delete">
                  <MdDelete />
                </IconButton>
              </Col>
            </Row>
          ))}
      </Row>
    </React.Fragment>
  );
}
export default Form;
