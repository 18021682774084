import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { FormControl, InputLabel, Input } from "@material-ui/core/index";
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.jsx";

function SimpleTextInput(props) {
  let {
    classes,
    labelText,
    name,
    id,
    required,
    value,
    type,
    onChange,
    readOnly,
    disabled,
    multi,
    rows,
    width,
  } = props;
  if (required) {
    labelText = `${labelText} *`;
  }
  const underlineClasses = classNames({
    [classes.underline]: true,
  });
  const marginTop = classNames({
    [classes.marginTop]: labelText === undefined,
  });

  return (
    <FormControl
      fullWidth
      className={classes.formControl}
      style={{ margin: 0, width: width ? width : "100%" }}
    >
      {labelText !== undefined && (
        <InputLabel className={classes.labelRoot} htmlFor={id}>
          {labelText}
        </InputLabel>
      )}
      <Input
        classes={{
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses,
        }}
        multiline={multi}
        rows={multi ? rows : 0}
        name={name}
        id={id || name}
        required={required}
        value={value}
        onChange={onChange}
        type={type}
        readOnly={readOnly}
        disabled={disabled}
      />
    </FormControl>
  );
}

SimpleTextInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  multi: PropTypes.bool,
  rows: PropTypes.number,
};

SimpleTextInput.defaultProps = {
  type: "text",
  readOnly: false,
  disabled: false,
  multi: false,
  rows: 3,
};

export default withStyles(customInputStyle)(SimpleTextInput);
