import React from "react";
import { TableContext } from "context/ContextProvider";
import Button from "@material-ui/core/Button";
import { ButtonGroup } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";

const BootstrapButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "15px 0px",
    border: "1px solid",
    lineHeight: 1.5,
    borderColor: "#007bff",
    transition: "0.4s",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#fff",
      color: "#000",
      borderColor: "#0062cc",
    },
  },
})(Button);

const useStyle = makeStyles(() => ({
  active: {
    backgroundColor: "#0062cc",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#0062cc !important",
      color: "#fff !important",
    },
  },
}));

export default function GroupedButtons(props) {
  const { style } = props;
  const classes = useStyle();
  return (
    <TableContext.Consumer>
      {({ handleGroupButtonChange, state: { meterSelected, meters } }) => (
        <ButtonGroup fullWidth style={{ ...style }}>
          {Object.keys(meters).map((name, i) => {
            return (
              <BootstrapButton
                className={meterSelected === name && classes.active}
                onClick={() => handleGroupButtonChange(name)}
                key={i}
              >
                {name}
              </BootstrapButton>
            );
          })}
        </ButtonGroup>
      )}
    </TableContext.Consumer>
  );
}
