import React from "react";
import { Button } from "antd";
import { presence, noValue } from "helpers";

export const columns = (editVehicle, vehicleHistory, updateAction) => [
  {
    title: "Number Plate",
    dataIndex: "number_plate",
    align: "center",
    render: (number_plate) => `${presence(number_plate)}`,
  },
  {
    title: "Owner's Name / Phone ",
    dataIndex: "owner",
    align: "center",
    render: (owner) => {
      return (
        <React.Fragment>
          {owner && owner.phone && owner.full_name ? (
            <React.Fragment>
              <p style={{ margin: 0 }}>{presence(owner.full_name)}</p>
              <p style={{ margin: 0, fontWeight: 600 }}>
                {presence(owner.phone)}
              </p>
            </React.Fragment>
          ) : (
            presence((owner && owner.full_name) || (owner && owner.phone))
          )}
        </React.Fragment>
      );
    },
  },
  {
    title: "Owner Type",
    align: "center",
    render: (row) => {
      return (
        <React.Fragment>
          <p>{presence(row.owner_type)}</p>
        </React.Fragment>
      );
    },
  },
  {
    title: "Previous Visits",
    dataIndex: "no_of_visits",
    render: (no_of_visits) => `${noValue(no_of_visits)}`,
    align: "center",
    sorter: true,
  },
  {
    title: "Manage",
    render: (row) => {
      return (
        <React.Fragment>
          {updateAction() && (
            <Button
              onClick={() => editVehicle(row)}
              style={{
                margin: "0px 10px 5px 10px",
              }}
            >
              Edit
            </Button>
          )}
          <Button
            onClick={() => vehicleHistory(row)}
            style={{
              margin: "5px 10px 0px 10px",
            }}
          >
            History
          </Button>
        </React.Fragment>
      );
    },
    align: "center",
  },
];
