import React, { Component } from "react";
import { debounce } from "lodash";
import AppBar from "components/AppBar";
import { Row } from "antd";
import PaginationTable from "components/paginationTables/withAddEdit";
import CRUDAlertSettings from "components/form/alertSettings/CRUD";
import { columns } from "data/alertSettings";
import { fetchAlertType } from "api/alertSettings";
import { handleResponse } from "components/notifications/handleResponse";
import { createAccess, updateAccess, getCookie, titleize } from "helpers";

class AlertGroup extends Component {
  constructor(props) {
    super(props);
    this.requestParams = this.initialRequestParams();
    this.location_id = window.location.pathname;
    this.state = {
      alertGroup: "",
      alertType: "",
      formOpen: false,
      total_entries: 0,
      rowData: undefined,
      data: [],
      skeleton: false,
    };
  }

  initialRequestParams = () => {
    return {
      perPage: 25,
      page: 1,
      search: "",
    };
  };

  componentDidMount = async () => {
    await this.fetchTableData();
  };

  fetchTableData = () => {
    this.setState({
      skeleton: true,
    });
    fetchAlertType(this.requestParams)
      .then(({ data, total_entries }) => {
        setTimeout(() => {
          this.setState({
            data: data,
            total_entries: total_entries,
            skeleton: false,
          });
        }, 500);
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.fetchTableData();
  };

  handleSubmitSuccess = () => {
    this.handleClose();
    this.timer = setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  handleClose = () => {
    this.setState({
      formOpen: false,
      rowData: undefined,
    });
  };

  createAction = () => {
    return createAccess("alert_types");
  };

  updateAction = () => {
    return updateAccess("alert_types");
  };

  onRowClick = (row) => {
    let access = this.updateAction();
    if (access) {
      this.setState({ formOpen: true, rowData: row });
    }
  };
  render() {
    const { formOpen, rowData, data, skeleton } = this.state;
    const { onChange, handleSubmitSuccess, handleClose, onRowClick } = this;
    const appBarProps = {
      search: this.requestParams.search,
      searchOpen: true,
      onChange,
      condo: { name: titleize(getCookie("alias_name")) },
    };
    const tableProps = {
      tabColor: "danger",
      title: "Alert Types",
      subTitle: "Alert Types of Admin here",
      dataValue: "Add Alerttypes",
      columns: columns,
      data: data,
      skeleton,
      handleTableChange: this.handleTableChange,
      onRowClick: onRowClick,
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      pagination: this.pagination(),
      createAction: this.createAction(),
    };
    const crudAlertProps = {
      onCancel: handleClose,
      open: formOpen,
      data: rowData,
      handleSubmitSuccess,
      tabColor: "danger",
    };
    return (
      <React.Fragment>
        <AppBar {...appBarProps}></AppBar>
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        <CRUDAlertSettings {...crudAlertProps} />
      </React.Fragment>
    );
  }
}
export default AlertGroup;
