import React from "react";
import { Skeleton } from "antd";

const Duplicate = ({ align }) => {
  return (
    <div>
      {[0, 1, 2, 3, 4, 5].map((index) => {
        const marR = {
          0: "0px",
          1: "100px",
          2: "210px",
          3: "210px",
          4: "100px",
          5: "0px",
        };
        const marRL = {
          0: "0px",
          1: "0px",
          2: "0px",
          3: "35px",
          4: "25px",
          5: "0px",
        };
        const padding =
          align === "left"
            ? `${marRL[index]} ${marR[index]} 0px 0px`
            : `${marRL[index]} 0px 0px ${marR[index]}`;
        return (
          <div
            key={index}
            style={{
              width: "100%",
              height: 110,
              padding,
              marginTop: index === 0 ? 0 : 30,
              display: "flex",
              justifyContent: align === "left" ? "flex-end" : "flex-start",
              alignItems: "center",
            }}
          >
            <Skeleton active />
          </div>
        );
      })}
    </div>
  );
};

export default Duplicate;
