import React from "react";
import c3 from "c3";
import "c3/c3.css";
import {
  Card,
  CardHeader,
  CardIcon,
} from "material-dashboard-react/components";
import { MdShowChart } from "react-icons/md";
import moment from "moment";
import { TableContext, AppBarContext } from "context/ContextProvider";

class Graph extends React.Component {
  constructor(props) {
    super(props);
    this.chart = null;
    this.state = {
      data: [],
      menuOpen: false,
    };
  }

  generateChart = () => {
    this.chart = c3.generate({
      bindto: "#big-meter-chart",
      data: {
        xFormat: "%Y-%m-%dT%H:%M:%S%Z",
        json: [],
      },
      color: {
        pattern: ["#f27876", "#11a0f8"],
      },
      axis: {
        x: {
          type: "timeseries",
          tick: {
            fit: true,
          },
        },
        y: {
          min: 0,
          padding: { top: 10, bottom: 0 },
        },
      },
      grid: {
        y: {
          show: true,
        },
      },
      legend: {
        show: false,
      },
      size: { height: 400 },
      tooltip: {
        format: {
          title: function(d) {
            return moment(d).format("DD-MM-YYYY h:mm A");
          },
          value: function(value) {
            return value;
          },
        },
      },
    });
  };

  reloadChart = (meters, meterSelected, data) => {
    if (!data || data.length === 0) {
      return;
    }
    let names = {};
    Object.keys(meters).forEach((key) => {
      if (key !== "All Meters") {
        names[meters[key][0]] = key;
      }
    });

    let format = "";
    if (this.props.granularity <= 4) {
      format = "%I:%M %p";
    } else if (this.props.granularity > 4 && this.props.granularity < 12) {
      format = "%e %b, %H:%M";
    } else {
      format = "%e %b";
    }
    this.chart.internal.config.axis_x_tick_format = format;
    this.chart.flush();

    this.chart.load({
      xFormat: "%Y-%m-%dT%H:%M:%S%Z",
      json: data,
      unload: meters["All Meters"].filter(
        (x) => !meters[meterSelected].includes(x)
      ),
      names: names,
      keys: {
        x: "datetime",
        value: meters[meterSelected],
      },
    });
  };

  componentDidMount = () => {
    this.generateChart();
  };

  render() {
    return (
      <Card>
        <CardHeader color="warning" stats icon>
          <CardIcon
            color="warning"
            style={{ padding: "6px", borderRadius: "6px" }}
          >
            <MdShowChart />
          </CardIcon>
          <p
            style={{
              textAlign: "left",
              color: "#fe9f1b",
              fontSize: "18px",
              marginTop: "15px",
            }}
          >
            Periodic Consumptions
          </p>
        </CardHeader>
        <TableContext.Consumer>
          {({ state: { meters, meterSelected, data } }) => {
            this.reloadChart(meters, meterSelected, data);
            return (
              <AppBarContext.Consumer>
                {({ state: { menuOpen } }) => {
                  if (menuOpen !== this.state.menuOpen) {
                    setTimeout(
                      function() {
                        this.generateChart();
                        this.reloadChart(meters, meterSelected, data);
                      }.bind(this),
                      100 * 3.5
                    );
                    this.setState({ menuOpen });
                  }
                }}
              </AppBarContext.Consumer>
            );
          }}
        </TableContext.Consumer>

        <div id="big-meter-chart" style={{ margin: 20 }} />
      </Card>
    );
  }
}

export default Graph;
