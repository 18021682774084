import React from "react";

const NoChartData = () => {
  return (
    <div
      style={{
        width: "100%",
        height: 300,
        fontSize: "14px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      No Data to display
    </div>
  );
};

export default NoChartData;
