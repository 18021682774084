import makeRequest from ".";

function generateParams(params = {}) {
  return `query=${params.search}&page=${params.page || 1}&per_page=${
    params.perPage
  }`;
}
export function fetchPermissions(requestParams) {
  return makeRequest({
    uri: `/api/v1/permissions?${generateParams(requestParams)}`,
  });
}

export function createPermissions(requestBody) {
  return makeRequest({
    uri: `/api/v1/permissions`,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updatePermissions(id, requestBody) {
  return makeRequest({
    uri: `/api/v1/permissions/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

export function fetchRolesSubPermissions(id) {
  return makeRequest({
    uri: `/api/v1/permissions/${id}/roles_sub_permissions`,
  });
}
