import React, { Component } from "react";
import { columns } from "data/blacklist";
import AppBar from "components/AppBar";
import { fetchListingData } from "api/blackList";
import PaginationTable from "components/paginationTables/withAddEdit";
import CRUDBlackLists from "components/form/blacklist/CRUD";
import { Row } from "antd";
import { handleResponse } from "components/notifications/handleResponse";
import { allBlackListsStatus } from "config";
import { createAccess, updateAccess, debounce } from "helpers";

class BlackLists extends Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.requestParams = this.initialRequestParams();
    this.state = {
      data: [],
      total_entries: 0,
      rowData: undefined,
      formOpen: false,
      skeleton: true,
    };
  }

  initialRequestParams = () => {
    return {
      perPage: 25,
      page: 1,
      search: "",
      status: allBlackListsStatus,
    };
  };

  componentDidMount = () => {
    this.fetchTableData();
  };

  fetchTableData = () => {
    fetchListingData(this.requestParams)
      .then(({ data, total_entries }) => {
        this.setState({
          data: data,
          total_entries: total_entries,
          skeleton: false,
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  flushTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };

  handleSubmitSuccess = () => {
    this.handleClose();
    this.flushTimer();
    this.timer = setTimeout(() => {
      this.fetchTableData();
    }, 1000);
  };

  handleClose = () => {
    this.setState({
      formOpen: false,
      rowData: undefined,
    });
  };

  pagination = () => {
    const { requestParams, state } = this;
    return {
      total: state.total_entries,
      current: requestParams.page,
      pageSize: requestParams.perPage,
    };
  };

  handleTableChange = (pagination, filters) => {
    this.requestParams.page = pagination.current;
    this.requestParams.perPage = pagination.pageSize;
    this.requestParams.status =
      filters.status && filters.status.length > 0
        ? filters.status
        : allBlackListsStatus;
    this.fetchTableData();
  };

  debounceSearch = debounce(() => {
    this.fetchTableData();
    this.setState(this.state);
  }, 1000);

  onChange = (name, value) => {
    this.requestParams[name] = value;
    this.requestParams.page = 1;
    this.setState(this.state);
    this.debounceSearch();
  };

  createAction = () => {
    return createAccess("blacklists");
  };

  updateAction = () => {
    return updateAccess("blacklists");
  };

  render() {
    const { data, rowData, formOpen, skeleton } = this.state;
    const { handleClose, handleSubmitSuccess, onChange } = this;
    const CRUDProps = {
      data: rowData,
      onCancel: handleClose,
      handleSubmitSuccess,
      open: formOpen,
      tabColor: "info",
      onChange,
    };

    const tableProps = {
      tabColor: "info",
      title: "Blacklists",
      subTitle: "Blacklist a vehicle or a person",
      columns: columns,
      data: data,
      pagination: this.pagination(),
      handleTableChange: this.handleTableChange,
      onRowClick: (row) => {
        this.setState({ formOpen: true, rowData: row });
      },
      handleOnClick: () => {
        this.setState({ formOpen: true });
      },
      dataValue: "ADD Blacklist",
      skeleton,
      createAction: this.createAction(),
      updateAction: this.updateAction(),
    };

    const appBarProps = {
      search: this.requestParams.search,
      // searchOpen: true,
      onChange,
    };

    return (
      <React.Fragment>
        <AppBar {...appBarProps} />
        <Row>
          <PaginationTable {...tableProps} />
        </Row>
        <CRUDBlackLists {...CRUDProps} />
      </React.Fragment>
    );
  }
}

export default BlackLists;
