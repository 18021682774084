import React from "react";
import { presence, formatTime } from "helpers";
import { StatusTile } from "./users";

export const columns = [
  {
    title: "Invited By",
    align: "center",
    render: (row) => {
      if (row.invitor)
        if (row.invitor.name && row.invitor.phone)
          return (
            <React.Fragment>
              <p style={{ margin: 0 }}>{presence(row.invitor.name)}</p>
              <p style={{ margin: 0, fontWeight: 600 }}>
                {presence(row.invitor.phone)}
              </p>
            </React.Fragment>
          );
        else
          return (
            <p style={{ margin: 0 }}>
              {presence(row.invitor.name || row.invitor.phone)}
            </p>
          );
      else return "-";
    },
  },
  {
    title: "Visitor",
    align: "center",
    render: (row) => {
      if (row.invitees.length)
        if (row.invitees[0].name && row.invitees[0].phone)
          return (
            <React.Fragment>
              <p style={{ margin: 0 }}>{presence(row.invitees[0].name)}</p>
              <p style={{ margin: 0, fontWeight: 600 }}>
                {row.invitees[0].phone}
              </p>
              {row.invitees.length > 1 && (
                <p style={{ margin: 0, fontSize: 10 }}>
                  {`${row.invitees && row.invitees.length - 1} More Invitees`}
                </p>
              )}
            </React.Fragment>
          );
        else
          return (
            <p style={{ margin: 0 }}>
              {presence(row.invitees[0].name || row.invitees[0].phone)}
            </p>
          );
      else return "-";
    },
  },
  {
    title: "Visiting Time",
    dataIndex: "visiting_time",
    render: (visiting_time) => `${formatTime(visiting_time)}`,
    align: "center",
  },
  {
    title: "Purpose Of Visit",
    dataIndex: "purpose",
    render: (purpose) => (
      <StatusTile role_group={purpose && presence(purpose)} />
    ),
    align: "center",
  },
  {
    title: "Current Status",
    dataIndex: "status",
    render: (status) => <StatusTile role_group={status && presence(status)} />,
    filters: [
      { text: "Created", value: "Created" },
      { text: "Invited", value: "Invited" },
      { text: "Visited", value: "Visited" },
      { text: "Expired", value: "Expired" },
      { text: "Cancelled", value: "Cancelled" },
      { text: "Blocked", value: "Blocked" },
    ],
    align: "center",
  },
];
