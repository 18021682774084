import React from "react";
import { Row, Col } from "antd";
import SimpleTextInput from "components/inputs/simpleTextInput";
import RadioButton from "components/inputs/radioButton";

function Form(props) {
  const { handleChange, card_id, status, id } = props;
  const contractType = {
    labelText: "Status",
    value: status,
    data: ["assigned", "available", "lost"],
    onChange: handleChange,
    name: "status",
    width: "32%",
  };
  return (
    <React.Fragment>
      <Row>
        <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="Card Number"
            name="card_id"
            required
            value={card_id}
            onChange={handleChange}
            readOnly={!!id}
          />
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          style={{ padding: "20px 10px", textAlign: "center" }}
        >
          <RadioButton {...contractType} />
        </Col>
      </Row>
    </React.Fragment>
  );
}
export default Form;
