import React, { Component } from "react";
import DialogForm from "hoc/dialogForm";
import Form from "../smvehicles/Form";
import {
  success,
  handleResponse,
} from "components/notifications/handleResponse";
import {
  createSpecialVehicle,
  updateSpecialVehicle,
} from "api/specialvehicles";
import { createVehicle } from "api/vehicles";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = null;
    this.state = {
      submitted: false,
      open: false,
      number_plate: "",
      vehicle: null,
      status: "active",
      slot: "",
      name: "",
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  requestParams = () => {
    const { status, vehicle, remarks, slot, name } = this.state;

    let vehicle_id = { label: vehicle?.label, value: vehicle?.value };
    return {
      vip_vehicles: {
        vehicle_id: vehicle_id?.value,
        status,
        remarks,
        slot,
        name,
      },
    };
  };

  handleCreateVehicles = (value) => {
    createVehicle({ vehicles: { number_plate: value } })
      .then(({ data }) => {
        this.setState({
          vehicle: { label: data.number_plate, value: data.id },
        });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  onSubmit = () => {
    const { id } = this;
    const method = !id ? createSpecialVehicle : updateSpecialVehicle;
    method(this.requestParams(), id)
      .then((data) => {
        success(data.message);
        this.props.handleSubmitSuccess();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    if (this.props.data) {
      const { id, vehicle, status, remarks, slot, name } = this.props.data;
      this.id = id;
      let vehicles = { label: vehicle.number_plate, value: vehicle.id };
      this.setState({
        status,
        remarks,
        vehicle: vehicles,
        slot,
        name,
      });
    }
  };

  render() {
    const { handleChange, handleCreateVehicles, id } = this;
    const { status, remarks, vehicle, slot, name } = this.state;
    const props = {
      id,
      status,
      remarks,
      vehicle,
      slot,
      name,
    };
    return (
      <Form
        handleChange={handleChange}
        {...props}
        handleCreateVehicles={handleCreateVehicles}
      />
    );
  }
}
export default DialogForm("VIP Vehicle")(CRUD);
