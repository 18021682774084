import React from "react";
import c3 from "c3";
import "c3/c3.css";
import { purple } from "@material-ui/core/colors";
import { Card, CardHeader } from "material-dashboard-react/components/index";
import { AppBarContext } from "context/ContextProvider";
import moment from "moment";
import { trafficData } from "api/dashboard";
import { handleResponse } from "components/notifications/handleResponse";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

class Traffic extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.chart = null;
    this.graph_data = [];
    this.state = {
      camera_ids: [],
      title: "Vehicle Count Pattern in Last 7 days",
      granularity: 1,
      menuOpen: false,
      duration: 8 * 24,
    };
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.chart = null;
  };

  componentDidMount = () => {
    this.generateChart();
    this.autoRefresh();
  };

  autoRefresh = () => {
    if (this.timer !== null) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.fetchTrafficFlow();
    this.timer = setTimeout(this.autoRefresh, 1000 * 60 * 5);
  };

  generateChart = () => {
    this.chart = c3.generate({
      bindto: "#traffic-chart",
      data: {
        xFormat: "%Y-%m-%d %H:%M:%S%Z",
        json: [],
      },
      size: {
        height: this.props.graphHeight,
      },
      axis: {
        x: {
          type: "timeseries",
          show: true,
          tick: {
            fit: true,
            format: "%H:%M-%m",
          },
        },
        y: {
          min: 0,
          padding: { top: 10, bottom: 0 },
        },
      },
      tooltip: {
        format: {
          title: function(d) {
            return moment(d).format("DD-MMM h:a");
          },
          value: function(value) {
            return value;
          },
        },
      },
    });
  };

  reloadChart = () => {
    let data = this.graph_data;
    if (!data || data?.length === 0) {
      return;
    }
    let value = this.state.camera_ids.map((obj) => {
      return obj[Object.keys(obj)[0]];
    });
    // let  names= this.state.camera_ids.map(obj => Object.keys(obj)[0]);
    this.chart.internal.config.axis_x_tick_format = "%e-%b";
    this.chart.flush();
    this.chart.load({
      xFormat: "%Y-%m-%d %H:%M:%S%Z",
      json: data,
      type: "spline",
      // names
      keys: {
        x: "datetime",
        value,
      },
    });
  };

  fetchTrafficFlow = async () => {
    await trafficData(this.state.duration)
      .then(({ data }) => {
        if (data?.length !== 0) {
          this.setState({ data }, async () => {
            if (this.state.camera_ids && this.state.camera_ids?.length) {
              await this.processed_data();
              this.reloadChart();
            }
          });
        }
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  processed_data = () => {
    let { data, camera_ids } = this.state;
    camera_ids = camera_ids.map((obj) => {
      return Object.keys(obj)[0];
    });
    if (!data || !camera_ids || !camera_ids?.length || !data?.length) {
      return;
    }

    let length = data?.length / 25 || 0;
    let granularity = 48;
    [48, 24, 12, 8, 6, 4, 2, 1].forEach((x) => {
      return x >= length && (granularity = x);
    });

    this.setState({ granularity });
    let object = [];
    let offset = moment().utcOffset();
    let json = {};
    camera_ids.map((camera_id) => {
      return (json[camera_id] = 0);
    });

    data.reverse().forEach((obj) => {
      camera_ids.forEach((camera_id) => (json[camera_id] += obj[camera_id]));

      let current_time = moment(obj.datetime).utcOffset(offset);
      if (
        current_time.minute() === 0 &&
        current_time.hour() % (granularity / 2) === 0
      ) {
        let result = { datetime: obj.datetime };
        camera_ids.forEach((camera_id) => {
          let a = this.state.camera_ids.filter(
            (camera) => camera_id === Object.keys(camera)[0]
          );
          result[a[0][Object.keys(a[0])]] = json[camera_id];
        });
        object.push(result);
        camera_ids.forEach((camera_id) => (json[camera_id] = 0));
      }
    });
    this.graph_data = object.reverse().slice(0, -1);
    return;
  };

  componentDidUpdate = () => {
    if (this.props.cameras !== this.state.cameras) {
      this.setState(
        {
          cameras: this.props.cameras,
          camera_ids: this.props.cameras.map((camera) => ({
            [camera.id]: camera.name,
          })),
        },
        async () => {
          await this.processed_data();
          this.reloadChart();
        }
      );
    }
  };

  handleChange = () => (event) => {
    if (event.target.value === 7) {
      this.setState(
        {
          title: "Vehicle Count Pattern in Last 7 days",
          duration: 8 * 24,
        },
        () => this.fetchTrafficFlow()
      );
    } else if (event.target.value === 15) {
      this.setState(
        {
          title: "Vehicle Count Pattern in Last 15 days",
          duration: 16 * 24,
        },
        () => this.fetchTrafficFlow()
      );
    } else {
      this.setState(
        {
          title: "Vehicle Count Pattern in Last 30 days",
          duration: 31 * 24,
        },
        () => this.fetchTrafficFlow()
      );
    }
  };

  render() {
    const { cardHeight } = this.props;
    return (
      <Card style={{ marginBottom: "0px", height: cardHeight }}>
        <CardHeader
          style={{
            marginTop: " -20px",
            marginRight: " 20px",
            marginLeft: "20px",
            borderRadius: "3px",
            boxShadow: "#db8cea -1px 7px 8px -5px",
            background: `linear-gradient(60deg, ${purple[600]}, ${purple[400]})`,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div>
              <h4
                style={{
                  margin: "0px 0px 3px",
                  color: "white",
                  fontWeight: "300",
                  fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                }}
              >
                {this.state.title}
              </h4>
              <p
                style={{
                  color: "rgba(255,255,255,.62)",
                  fontWeight: "300",
                  margin: "initial",
                }}
              >
                {/* Vehicle Count Pattern segmented by each cameras */}
              </p>
            </div>
            <div
              style={{
                alignItems: "center",
                display: "flex",
              }}
            >
              <FormControl
                style={{
                  margin: "theme.spacing(1)",
                  minWidth: "30%",
                  color: "white",
                }}
              >
                <Select
                  style={{ background: "#9a00a2c7", color: "white" }}
                  defaultValue={7}
                  onChange={this.handleChange()}
                >
                  <MenuItem value={7}>Last 7 days</MenuItem>
                  <MenuItem value={15}>Last 15 days</MenuItem>
                  <MenuItem value={30}>Last 30 days</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </CardHeader>
        <AppBarContext.Consumer>
          {({ state: { menuOpen } }) => {
            if (menuOpen !== this.state.menuOpen) {
              setTimeout(
                function() {
                  this.generateChart();
                  this.reloadChart();
                }.bind(this),
                100 * 10
              );
              this.setState({ menuOpen });
            }
          }}
        </AppBarContext.Consumer>
        <div id="traffic-chart" style={{ margin: 20 }} />
      </Card>
    );
  }
}

export default Traffic;
//rgba(255, 255, 255, 0.15)
