import makeRequest from ".";

function generateParams(params = {}) {
  let notificationTypes = "";
  let notificationStatus = "";
  if (params.notification_type) {
    notificationTypes = params.notification_type
      .map((value) => `&notification_type[]=${value}`)
      .join("");
  }
  if (params.status) {
    notificationStatus = params.status
      .map((value) => `&status[]=${value}`)
      .join("");
  }
  return `query=${params.search ||
    ""}${notificationTypes}${notificationStatus}&page=${params.page ||
    1}&per_page=${params.perPage || 5}`;
}

export function fetchNotifications(requestParams) {
  return makeRequest({
    uri: `/api/v1/notifications?${generateParams(requestParams)}`,
  });
}

export function createNotifications(requestBody) {
  return makeRequest({
    uri: `/api/v1/notifications`,
    body: JSON.stringify(requestBody),
    method: "POST",
  });
}

export function updateNotifications(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/notifications/${id}`,
    body: JSON.stringify(requestBody),
    method: "PUT",
  });
}
