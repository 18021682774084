import React from "react";
import { Col, Row, Tabs } from "antd";
import SimpleTextInput from "components/inputs/simpleTextInput";
import RadioButton from "components/inputs/radioButton.jsx";
import Multiselect from "components/inputs/Multiselect";

const Forms = ({
  data: {
    alert_types,
    sound,
    title,
    subtitle,
    header,
    footer,
    disabled,
    visibility,
    url,
    icon_url,
  },
  alias_name,
  handleChange,
}) => {
  const { TabPane } = Tabs;
  return (
    <React.Fragment>
      <Row>
        <Tabs
          type="card"
          defaultActiveKey="Controls"
          style={{ overflow: "visible" }}
        >
          <TabPane tab="Controls" key="Controls">
            <Row xs={24} sm={24} style={{ padding: "10px" }}>
              <Multiselect
                name="alert_types"
                selected={alert_types}
                heading={"Alert Type"}
                options={alias_name}
                handleChange={handleChange}
              />
            </Row>

            <Row xs={24} sm={24} style={{ padding: "10px" }}>
              <SimpleTextInput
                labelText="URL"
                name="url"
                value={url}
                onChange={handleChange}
                required={visibility === true ? true : false}
              />
            </Row>
            <Col>
              <Col
                xs={24}
                sm={8}
                md={8}
                style={{
                  textAlign: "center",
                  zIndex: 0,
                  width: "30%",
                  padding: "10px",
                }}
              >
                <RadioButton
                  labelText="Visibility"
                  value={visibility}
                  data={{ Yes: true, No: false }}
                  onChange={handleChange}
                  name="visibility"
                  color={true}
                />
              </Col>
              <Col
                xs={24}
                sm={8}
                md={8}
                style={{
                  textAlign: "center",
                  zIndex: 0,
                  width: "30%",
                  padding: "10px",
                }}
              >
                <RadioButton
                  labelText="Sound"
                  value={sound}
                  data={{ Yes: true, No: false }}
                  onChange={handleChange}
                  name="sound"
                  color={true}
                />
              </Col>
              <Col
                xs={24}
                sm={8}
                md={8}
                style={{
                  textAlign: "center",
                  zIndex: 0,
                  width: "30%",
                  padding: "10px",
                }}
              >
                <RadioButton
                  labelText="Disable"
                  value={disabled}
                  data={{ Yes: true, No: false }}
                  onChange={handleChange}
                  name="disabled"
                  color={true}
                />
              </Col>
            </Col>
          </TabPane>
          <TabPane tab="Display" key="Display">
            <Col xs={24} sm={24} style={{ padding: "0px 10px" }}>
              <SimpleTextInput
                labelText="Title"
                name="title"
                value={title}
                onChange={handleChange}
              />
            </Col>
            <Col xs={24} sm={24} style={{ padding: "0px 10px" }}>
              <SimpleTextInput
                labelText="Subtitle"
                name="subtitle"
                value={subtitle}
                onChange={handleChange}
              />
            </Col>
            <Col xs={24} sm={12} style={{ padding: "10px" }}>
              <SimpleTextInput
                labelText="Header"
                name="header"
                value={header}
                onChange={handleChange}
              />
            </Col>
            <Col xs={24} sm={12} style={{ padding: "10px" }}>
              <SimpleTextInput
                labelText="Footer"
                name="footer"
                value={footer}
                onChange={handleChange}
              />
            </Col>
            <Col xs={24} sm={24} style={{ padding: "10px" }}>
              <SimpleTextInput
                labelText="Icon URL"
                name="icon_url"
                value={icon_url}
                onChange={handleChange}
              />
            </Col>
          </TabPane>
        </Tabs>
      </Row>
    </React.Fragment>
  );
};

export default Forms;
