import makeRequest from ".";
import { headers, getCookie } from "helpers";
import { apiHost } from "config";

export function fetchKioskWorkflows(id) {
  return makeRequest({
    uri: `/api/v1/kiosks/${id}/workflows`,
  });
}

export function createKioskWorkflow(id, requestBody) {
  let reqHeaders = {
    Authorization: getCookie("token"),
  };
  return fetch(apiHost + `/api/v1/kiosks/${id}/workflow`, {
    method: "PUT",
    body: requestBody,
    headers: reqHeaders,
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    }
    const errorMessage = [response.status, await response.json()];
    throw errorMessage;
  });
}

export function updateKioskWorkflow(id, requestBody) {
  return makeRequest({
    uri: `/api/v1/kiosks/${id}/current_workflow`,
    method: "PUT",
    headers: headers(),
    body: JSON.stringify({ workflow_id: requestBody }),
  });
}

export function fetchVizitinWorkflows(id) {
  return makeRequest({
    uri: `/api/v1/vizitins/${id}/workflows`,
  });
}

export function createVizitinWorkflow(id, requestBody) {
  let reqHeaders = {
    Authorization: getCookie("token"),
  };
  return fetch(apiHost + `/api/v1/vizitins/${id}/workflow`, {
    method: "PUT",
    body: requestBody,
    headers: reqHeaders,
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    }
    const errorMessage = [response.status, await response.json()];
    throw errorMessage;
  });
}

export function updateVizitinWorkflow(id, requestBody) {
  return makeRequest({
    uri: `/api/v1/vizitins/${id}/current_workflow`,
    method: "PUT",
    headers: headers(),
    body: JSON.stringify({ workflow_id: requestBody }),
  });
}
