import makeRequest from ".";

function generateParams(params) {
  let category =
    params?.category?.map((value) => `&category[]=${value}`).join("") || "";
  return `${category}&page=${params.page || 1}&per_page=${params.perPage ||
    25}`;
}

export function fetchSoftwares(params) {
  return makeRequest({
    uri: `/api/v1/softwares?${generateParams(params)}`,
  });
}

export function createSoftware(formData) {
  return makeRequest({
    uri: `/api/v1/softwares`,
    method: "POST",
    body: formData,
  });
}

export function updateSoftware(status, id) {
  return makeRequest({
    uri: `/api/v1/softwares/${id}/status?status=${status}`,
    method: "PUT",
  });
}
