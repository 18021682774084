import React, { Component } from "react";
import { Table } from "antd";
import Skel from "components/Skeleton";

class TabBody extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.setState({ loading: this.props.loading });
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState({ loading: this.props.loading });
    this.props.handleTableChange(pagination, filters, sorter);
  };

  render() {
    const {
      columns,
      cameradata,
      kioskdata,
      vizitindata,
      pirdata,
      nlsdata,
      doordata,
      skeleton,
      data,
      pagination,
      handleTableChange,
      loading,
    } = this.props;
    let total =
      cameradata ||
      kioskdata ||
      vizitindata ||
      data ||
      pirdata ||
      nlsdata ||
      doordata;
    return (
      <>
        {skeleton ? (
          <Skel />
        ) : (
          <Table
            style={{
              width: "100%",
              display: "table",
              borderSpacing: "2px",
              borderColor: "grey",
              borderCollapse: "collapse",
              verticalAlign: "middle",
              marginBottom: "1rem",
              color: "#212529",
            }}
            columns={columns}
            rowKey={(record) => record.id}
            dataSource={total}
            loading={loading}
            onChange={handleTableChange}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} results`,
              showSizeChanger: true,
              pageSizeOptions: ["10", "25", "50", "100"],
              ...pagination,
            }}
          ></Table>
        )}
      </>
    );
  }
}
export default TabBody;
