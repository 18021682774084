import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import {
  VisitorType,
  NatureOfVisitor,
  RaisedAlerts,
  JustVisitedPickupDrop,
  JustVisitedDelivery,
  JustVisitedContractor,
  JustEnteredResident,
  JustEnteredVisitor,
  JustEntered,
  JustExited,
  TypeOfEntry,
} from "./dashboardCharts";
import { dashboardGraph } from "config";

/** Dashboard Chart items */
export const graphs = {
  visitor_type: {
    title: "visitor_type",
    child: <VisitorType />,
  },
  visitor_nature: {
    title: "visitor_nature",
    child: <NatureOfVisitor />,
  },
  raised_alerts: {
    title: "raised_alerts",
    child: <RaisedAlerts />,
  },
  just_pickdrop: {
    title: "just_pickdrop",
    child: <JustVisitedPickupDrop />,
  },
  just_delivery: {
    title: "just_delivery",
    child: <JustVisitedDelivery />,
  },
  just_contractor: {
    title: "just_contractor",
    child: <JustVisitedContractor />,
  },
  just_resident: {
    title: "just_resident",
    child: <JustEnteredResident />,
  },
  just_visitor: {
    title: "just_visitor",
    child: <JustEnteredVisitor />,
  },
  just_entered: {
    title: "just_entered",
    child: <JustEntered />,
  },
  just_exited: {
    title: "just_exited",
    child: <JustExited />,
  },
  entry_type: {
    title: "entry_type",
    child: <TypeOfEntry />,
  },
};

const render_card = ({ title, child }) => {
  return (
    <Col
      xs={24}
      md={12}
      lg={8}
      style={{ paddingRight: "15px" }}
      data-json={title}
    >
      {child}
    </Col>
  );
};

/** Render Graph */
const filterGraph = (graph) => {
  let item = graphs[graph];
  return render_card(item);
};

export const GraphDashboard = () => {
  const [cards, setCards] = useState(dashboardGraph);
  useEffect(() => {
    let graph_tiles = localStorage.getItem("graph_tiles");
    if (graph_tiles) {
      setCards(JSON.parse(graph_tiles));
    }
  }, []);
  return (
    <Row>
      {Array.isArray(cards) && cards.map((graph) => filterGraph(graph))}
    </Row>
  );
};
