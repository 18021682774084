import React from "react";
import SimpleTextInput from "components/inputs/simpleTextInput";
import { Row, Col } from "antd";
import RadioButton from "components/inputs/radioButton";
import Singleselect from "components/inputs/Singleselect";
import { fetchVehicleData } from "api/vehicles";

export default function Form(props) {
  const {
    id,
    status,
    remarks,
    vehicle,
    handleChange,
    handleCreateVehicles,
    // slot,
    name,
  } = props;

  const vehicleData = async (search) =>
    await fetchVehicleData({ perPage: 5, search }).then(({ data }) =>
      data.map((value) => ({ label: value.number_plate, value: value.id }))
    );

  const vehicleStatus = {
    labelText: "Status",
    value: status,
    data: ["active", "inactive"],
    onChange: handleChange,
    name: "status",
    color: true,
  };

  return (
    <React.Fragment>
      <Row spacing={3} style={{ padding: 10 }}>
        <>
          <Col xs={24} sm={24}></Col>
          <Col xs={24} sm={12} style={{ padding: "0px 10px" }}>
            <SimpleTextInput
              labelText="Name"
              name="name"
              value={name}
              onChange={handleChange}
            />
          </Col>
        </>
        <Col xs={24} sm={12} style={{ padding: "0px 10px" }}>
          <Singleselect
            async
            allowCreate
            name="vehicle"
            selected={vehicle}
            heading={"Number Plate"}
            dataPromise={vehicleData}
            handleChange={handleChange}
            handleCreate={handleCreateVehicles}
            isDisabled={id ? true : false}
          />
          {!props.disabled && (
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{ opacity: 0, height: 0 }}
              value={vehicle}
              required={true}
            />
          )}
        </Col>
        <>
          <Col xs={24} sm={24}></Col>
          <Col
            xs={24}
            sm={12}
            style={{ padding: "0px 10px", textAlign: "center" }}
          >
            <RadioButton {...vehicleStatus} />
          </Col>
          {/* <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }} >
            <SimpleTextInput
              labelText="Slot"
              name="slot"
              value={slot}
              onChange={handleChange}
            />
          </Col> */}
        </>

        <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="Remarks"
            name="remarks"
            value={remarks}
            onChange={handleChange}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
}
