import makeRequest from ".";
import { apiHost } from "config";
import { headers } from "helpers";

function generateParams(params = {}, totalEntries = null) {
  let types = "";
  let status = "";
  let time = "";
  let alert_group = "";
  let not_alert_group = "";
  let not_alert_type = "";
  if (params?.alert_type?.length) {
    types = params.alert_type?.map((value) => `&type[]=${value}`).join("");
  }
  if (params?.alert_group?.length) {
    alert_group = params.alert_group
      ?.map((value) => `&alert_group[]=${value}`)
      .join("");
  }

  if (params?.not_alert_group?.length) {
    not_alert_group = params.not_alert_group
      ?.map((value) => `&not_alert_group[]=${value}`)
      .join("");
  }
  if (params?.not_alert_type?.length) {
    not_alert_type = params.not_alert_type
      ?.map((value) => `&not_alert_type[]=${value}`)
      .join("");
  }
  if (params.status) {
    status = Array.isArray(params.status)
      ? params.status.map((value) => `&status[]=${value}`).join("")
      : `&status[]=${params.status}`;
  }

  if (params.fromTime || params.toTime) {
    time = `from_time=${params.fromTime || ""}&to_time=${params.toTime || ""}`;
  }

  return `${time || ""}${types ||
    ""}${alert_group}${not_alert_group}${not_alert_type}${status}&query=${params.search ||
    ""}&page=${params.page || 1}&per_page=${totalEntries ||
    params.perPage ||
    25}&sort_by=${params.sort_by || "weight"}`;
}
export function fetchListingData(requestParams) {
  return makeRequest({
    uri: `/api/v1/alerts?${generateParams(requestParams)}`,
  });
}

export function triggerReportDownload(requestParams, totalEntries) {
  return makeRequest({
    uri: `/api/v1/alerts/generate_report?${generateParams(
      requestParams,
      totalEntries
    )} `,
  });
}

export function alertAggregation() {
  return makeRequest({
    uri: "/api/v1/alerts/aggs",
  });
}

export function getEmergencyAlertList(alertTypes) {
  let status = `&status[]=Raised`;
  let types = alertTypes.map((value) => `&type[]=${value}`).join("");
  return makeRequest({
    uri: `/api/v1/alerts?${types}${status}`,
  });
}

export function updateFalseAlert(id, body) {
  return fetch(apiHost + `/api/v1/alerts/${id}/false_alert`, {
    method: "PUT",
    headers: headers(),
    body,
  }).then(async (response) => {
    if (response.ok) {
      return true;
    }
    const errorMessage = [response.status, await response.json()];
    throw errorMessage;
  });
}

export function updateResolved(id, msg) {
  return makeRequest({
    uri: `/api/v1/alerts/${id}/resolved?remarks=${
      msg ? msg : "Alert Resolved"
    }`,
    method: "PUT",
  });
}

export function showData(id) {
  return makeRequest({
    uri: `/api/v1/alerts/${id}`,
  });
}

export function fetchAlertGroup() {
  return makeRequest({
    uri: `/api/v1/alert_groups?${generateParams()}`,
  });
}
export function alertsTiles() {
  return makeRequest({
    uri: `/api/v1/alerts/tiles`,
  });
}

export function fetchAlertTypes() {
  return makeRequest({
    uri: `/api/v1/alert_types`,
  });
}

export function multiResolved(requestBody) {
  return makeRequest({
    uri: `/api/v1/alerts/multi_resolve`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}
