import React from "react";
import ReactDOM from "react-dom";
import App from "App";
import Footer from "Footer";
import * as serviceWorker from "serviceWorker";
// import { firebaseServiceWorker } from "./register-sw";

// firebaseServiceWorker();

ReactDOM.render(<App />, document.getElementById("root"));
if (document.getElementById("footer")) {
  ReactDOM.render(<Footer />, document.getElementById("footer"));
}

serviceWorker.unregister();
