import makeRequest from ".";

function generateParams(params = {}, totalEntries = null) {
  return `query=${params.search}&page=${params.page ||
    1}&per_page=${totalEntries || params.perPage}`;
}

export function uploadBulkCompanies(body) {
  return makeRequest({
    uri: `/api/v1/companies/import`,
    method: "POST",
    body,
  });
}

export function companyData(requestParams) {
  return makeRequest({
    uri: `/api/v1/companies/list?${generateParams(requestParams)}`,
  });
}
