import React from "react";

export default function ColIcons({ icon_path, count, width, height }) {
  return (
    <p style={{ marginBottom: "-1em" }}>
      <span style={{ position: "relative", marginTop: 10 }}>
        {count > 1 && (
          <span
            style={{
              position: "absolute",
              top: "-75%",
              right: "-75%",
              backgroundColor: "gray",
              fontSize: 10,
              borderRadius: "50%",
              width: 15,
              height: 15,
              color: "#fff",
              zIndex: 2,
            }}
          >
            {count}
          </span>
        )}
        <img
          src={icon_path}
          alt="icon"
          width={width || "20"}
          height={height || "20"}
          color="#fff"
        />
      </span>
    </p>
  );
}
