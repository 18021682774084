import React from "react";
import { formatTime, presence, varHumanize } from "helpers";
import { Tooltip } from "antd";

export const columns = [
  {
    title: "Time",
    dataIndex: "from_time",
    render: (from_time) => `${formatTime(from_time)}`,
    align: "center",
  },
  {
    title: "Type",
    dataIndex: "type",
    render: (type) => `${varHumanize(type)}`,
    align: "center",
  },
  {
    title: "Name",
    dataIndex: "entity",
    align: "center",
    render: (entity) => {
      return (
        <Tooltip
          title={
            entity &&
            (entity?.state ||
              entity?.visitor_details?.map((obj) => obj.phone)?.join(",") ||
              "-")
          }
        >
          {presence(
            entity &&
              (entity?.name ||
                entity?.visitor_details?.map((obj) => obj.name)?.join(",") ||
                "-")
          )}
        </Tooltip>
      );
    },
  },
];
