import React, { Component } from "react";
import DialogForm from "hoc/dialogForm";
import Form from "./Form";
import {
  success,
  handleResponse,
} from "components/notifications/handleResponse";
import { createContractors, updateContractors } from "api/cardRegister";
import moment from "moment";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = null;
    this.state = {
      open: false,
      name: "",
      fin_number: "",
      nricPrier: "XXXXX",
      employer: "",
      phone: "",
      status: "active",
      expire_at: moment.utc(moment().endOf("day")),
      send_welcome: false,
      // sub_visitor: 'contractor',
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChangeNric = (value) => {
    let { nricPrier, fin_number } = this.state;
    let fin = fin_number
      ? value.slice(0, 9)
      : `${nricPrier}${value.slice(0, 4)}`;
    this.setState({ fin_number: fin });
  };

  onDateChange = (expire_at) => {
    this.setState({ expire_at });
  };

  requestParams = () => {
    const {
      name,
      fin_number,
      employer,
      phone,
      status,
      expire_at,
      send_welcome,
      // sub_visitor,
    } = this.state;

    const finNumber = fin_number.substr(5);

    return {
      contractors: {
        name,
        fin_number: finNumber,
        employer,
        phone,
        status,
        expire_at,
        send_welcome,
        // sub_visitor,
      },
    };
  };

  onSubmit = () => {
    const { id } = this;
    const method = !id ? createContractors : updateContractors;
    method(this.requestParams(), id)
      .then((data) => {
        success(data.message);
        this.props.handleSubmitSuccess();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    const { data } = this.props;
    const { nricPrier } = this.state;
    if (data) {
      this.id = data.id;
      this.setState({
        name: data.name,
        fin_number: `${nricPrier}${data.fin_number}`,
        employer: data.employer,
        phone: data.phone,
        status: data.status,
        expire_at: data.expire_at,
        // sub_visitor: data.sub_visitor,
        send_welcome: false,
      });
    }
  };

  render() {
    const { handleChange, onDateChange, id, handleChangeNric } = this;
    const {
      name,
      fin_number,
      employer,
      phone,
      status,
      expire_at,
      send_welcome,
      // sub_visitor
    } = this.state;

    const props = {
      id,
      name,
      fin_number,
      employer,
      phone,
      status,
      expire_at,
      send_welcome,
      // sub_visitor
    };
    return (
      <Form
        handleChangeNric={handleChangeNric}
        handleChange={handleChange}
        {...props}
        onDateChange={onDateChange}
      />
    );
  }
}
export default DialogForm("Contractor")(CRUD);
