import makeRequest from ".";
import { headers } from "helpers";

function generateParams(params = {}) {
  let kioskTypes = "";
  if (params.kiosk_type && params.kiosk_type.length >= 1) {
    kioskTypes = params.kiosk_type.map((value) => `&type[]=${value}`).join("");
  }
  return `${kioskTypes}&page=${params.page || 1}&per_page=${params.perPage ||
    25}`;
}

export function fetchKiosk(requestParams) {
  return makeRequest({
    uri: `/api/v1/kiosks?${generateParams(requestParams)}`,
  });
}

export function createKiosk(requestBody) {
  return makeRequest({
    uri: `/api/v1/kiosks`,
    method: "POST",
    headers: headers(),
    body: JSON.stringify(requestBody),
  });
}

export function updateKiosk(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/kiosks/${id}`,
    method: "PUT",
    headers: headers(),
    body: JSON.stringify(requestBody),
  });
}
