import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardIcon,
} from "material-dashboard-react/components";
import { MdBorderAll } from "react-icons/md";
import { TableContext } from "context/ContextProvider";
import moment from "moment";

const style = () => ({
  tableWrapper: {
    height: 598,
    overflowY: "auto",
  },
});

const invertData = (meters) => {
  let names = {};
  Object.keys(meters).forEach((key) => {
    if (key !== "All Meters") {
      names[meters[key][0]] = key;
    }
  });
  return names;
};

const Header = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left">Date</TableCell>
        {
          <TableContext.Consumer>
            {({ state: { meterSelected, meters } }) => {
              var names = invertData(meters);
              return meters[meterSelected].map((id, i) => {
                return (
                  <TableCell key={i} align="center">
                    {names[id]} (Units)
                  </TableCell>
                );
              });
            }}
          </TableContext.Consumer>
        }
      </TableRow>
    </TableHead>
  );
};

const Body = () => {
  return (
    <React.Fragment>
      <TableContext.Consumer>
        {({ state: { data, meterSelected, meters } }) => {
          return (
            <TableBody>
              {data.length !== 0 ? (
                data.map(({ datetime, ...options }, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell align="left">
                        {moment(datetime).format("DD-MM-YYYY h:mm A")}
                      </TableCell>
                      {meters[meterSelected].map((id, i) => {
                        return (
                          <TableCell key={i} align="center">
                            {options[id]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow style={{ height: 250 }}>
                  <TableCell align="center" colSpan={3}>
                    No Data To Display
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          );
        }}
      </TableContext.Consumer>
    </React.Fragment>
  );
};

const MyTableCardHeader = () => {
  return (
    <React.Fragment>
      <CardHeader color="warning" stats icon style={{ marginBottom: "20px" }}>
        <CardIcon
          color="warning"
          style={{ padding: "15px", borderRadius: "6px" }}
        >
          <MdBorderAll />
        </CardIcon>
        <p
          style={{
            textAlign: "left",
            color: "#fe9f1b",
            fontSize: "18px",
            marginTop: "15px",
          }}
        >
          Consumptions Table
        </p>
      </CardHeader>
      <Divider />
    </React.Fragment>
  );
};

class MyTable extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Card>
        <MyTableCardHeader />
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <Header />
            <Body />
          </Table>
        </div>
      </Card>
    );
  }
}

export default withStyles(style)(MyTable);
