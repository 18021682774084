import makeRequest from ".";
import { getCookie } from "helpers";

export function index() {
  return makeRequest({
    uri: `/api/v1/wemas`,
  });
}

export function announcement(mac_address, data) {
  return makeRequest({
    uri: `/api/v1/wemas/${mac_address}/announcement`,
    method: "PUT",
    body: data,
    header:
      data instanceof FormData ? { Authorization: getCookie("token") } : "",
  });
}
