import React from "react";
import SimpleTextInput from "components/inputs/simpleTextInput";
import { Row, Col } from "antd";
import RadioButton from "components/inputs/radioButton";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

export default function Form(props) {
  const { handleChange, onDateChange, handleChangeNric } = props;
  const {
    name,
    fin_number,
    employer,
    phone,
    status,
    expire_at,
    send_welcome,
    // sub_visitor
  } = props;

  const statusProps = {
    labelText: "Status",
    value: status,
    data: ["active", "inactive"],
    onChange: handleChange,
    name: "status",
    color: true,
  };
  return (
    <React.Fragment>
      <Row spacing={3} style={{ padding: 10 }}>
        <Col xs={24} sm={12} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="Name"
            name="name"
            required
            value={name}
            onChange={handleChange}
          />
        </Col>
        <Col xs={24} sm={12} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="NRIC/FIN (Last 4 digits)"
            name="fin_number"
            required
            value={fin_number}
            onChange={(e) => handleChangeNric(e.target.value)}
          />
        </Col>
        <Col xs={24} sm={24} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="Employer"
            name="employer"
            required
            value={employer}
            onChange={handleChange}
          />
        </Col>
        <Col xs={24} sm={12} style={{ padding: "0px 10px" }}>
          <SimpleTextInput
            labelText="Phone"
            name="phone"
            required
            value={phone}
            onChange={handleChange}
          />
        </Col>
        <Col
          xs={24}
          sm={12}
          style={{ padding: "0px 10px", margin: "-14px 0px 0px 0px" }}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label=" Expire On"
              value={moment(expire_at)}
              onChange={onDateChange}
              minDate={moment()}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col xs={24} sm={12} style={{ padding: "5px 12px", width: "50%" }}>
          <RadioButton {...statusProps} />
        </Col>

        <Col
          xs={24}
          sm={12}
          style={{ padding: "6px 15px 6px 10px", width: "50%" }}
        >
          <RadioButton
            labelText="Welcome Message"
            value={send_welcome}
            data={{ Yes: true, No: false }}
            onChange={handleChange}
            name="send_welcome"
            color={true}
          />
        </Col>
        {/* <Col xs={24} sm={12} style={{ padding: "10px 8px 10px 0px" }}>
          <div
            style={{
              color: "#969292",
              paddingLeft: "11px",
              margin: "0px",
              fontSize: "10px"
            }}
          >
            Sub Visitor
          </div>
          <Select
            value={sub_visitor}
            onChange={e => handleChange(e)}
            name="sub_visitor"
            style={{
              width: "100%",
              marginLeft: "14px",
              height: "40px"
            }}
          >
            <MenuItem value="guest">Plumping</MenuItem>
            <MenuItem value="contractor">Electrical</MenuItem>
            <MenuItem value="airconservices">Air Con Services</MenuItem>
            <MenuItem value="others">others</MenuItem>
          </Select>
        </Col> */}
      </Row>
    </React.Fragment>
  );
}
