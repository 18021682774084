import React from "react";
import PropTypes from "prop-types";

import { Button } from "@material-ui/core";

const styles = {
  default: {
    color: "#323232",
    padding: "8px 20px",
    marginLeft: "15px",
    backgroundColor: "white",
    border: "1px solid #5e72e4",
    boxShadow: "0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08)",
    "&:hover": {
      backgroundColor: "white",
      borderColor: "#3CC140",
      boxShadow: " 0px 5px 5px -4px",
    },
    "&:active": {
      borderColor: "#323250",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
  success: {
    color: "green",
    border: "1px solid green",
    "&:hover": {
      borderColor: "green  ",
    },
    "&:active": {
      borderColor: "green",
    },
  },
  danger: {
    color: "red",
    border: "1px solid red",
    "&:hover": {
      borderColor: "red",
    },
    "&:active": {
      borderColor: "red",
    },
  },
};

const CustomButton = (props) => {
  let style = styles.default;
  const { color, text, disabled } = props;
  switch (color) {
    case "success":
      style = { ...style, ...styles.success };
      break;
    case "danger":
      style = { ...style, ...styles.danger };
      break;
    default:
      break;
  }
  return (
    <Button style={style} {...props} disabled={disabled}>
      {text}
    </Button>
  );
};

CustomButton.prototype = {
  color: PropTypes.oneOf(["success", "secondary", "danger"]),
  text: PropTypes.string.isRequired,
};

export default CustomButton;
