import React from "react";
import propTypes from "prop-types";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";

const MultiSelect = ({
  heading,
  async,
  allowCreate,
  dataPromise,
  selected,
  options,
  handleChange,
  handleCreate,
  isLoading,
  name,
  single,
  isDisabled,
  required,
  noOptionsMessage,
}) => {
  const loadOptions = (input) =>
    new Promise((resolve) => {
      resolve(dataPromise(input));
    });
  return (
    <React.Fragment>
      <p
        style={{
          fontSize: 12,
          margin: "-0.1em 0 0.3em",
          color: "#5a5757",
        }}
      >
        {heading}
      </p>
      {async ? (
        allowCreate ? (
          <AsyncCreatableSelect
            isMulti
            cacheOptions
            defaultOptions
            value={selected}
            loadOptions={loadOptions}
            onChange={(value) => handleChange({ target: { value, name } })}
            onCreateOption={handleCreate}
            isLoading={isLoading}
            closeMenuOnSelect={false}
            isClearable={false}
          />
        ) : (
          <AsyncSelect
            isMulti
            cacheOptions
            defaultOptions
            value={selected}
            loadOptions={loadOptions}
            onChange={(value) => handleChange({ target: { value, name } })}
            closeMenuOnSelect={false}
            isClearable={false}
          />
        )
      ) : single ? (
        <Select
          defaultValue
          options={options || []}
          value={selected}
          onChange={(value) => handleChange({ target: { value, name } })}
          isDisabled={isDisabled}
          required={required}
          noOptionsMessage={() => noOptionsMessage || "No Options"}
        />
      ) : allowCreate ? (
        <CreatableSelect
          isMulti
          onChange={(value) => handleChange({ target: { value, name } })}
          onCreateOption={handleCreate}
          isLoading={isLoading}
          options={options}
          value={selected}
        />
      ) : (
        <Select
          isMulti
          value={selected}
          options={options}
          onChange={(value) => handleChange({ target: { value, name } })}
          closeMenuOnSelect={false}
          isClearable={false}
        />
      )}
    </React.Fragment>
  );
};

MultiSelect.propTypes = {
  async: propTypes.bool,
  allowCreate: propTypes.bool,
  selected: propTypes.array,
  options: propTypes.object,
  dataPromise: propTypes.func,
  handleChange: propTypes.func.isRequired,
  heading: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
};

MultiSelect.defaultProps = {
  async: false,
  allowCreate: false,
  selected: [],
};

export default MultiSelect;
