import React, { Component } from "react";
import { Table } from "antd";
import { withStyles } from "@material-ui/styles";

const styles = {
  row: {
    background: "orange",
  },
};
class TableBody extends Component {
  render() {
    const { columns, data, onRowClick } = this.props;
    return (
      <div
        style={{
          overflowX: "auto",
          width: "100%",
        }}
      >
        <Table
          columns={columns}
          onRow={(record, i) => {
            return {
              onClick: () => {
                onRowClick(record, i);
              },
            };
          }}
          rowClassName={(record) => {
            return record.incident ? this.props.classes.row : "";
          }}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={false}
        />
      </div>
    );
  }
}

export default withStyles(styles)(TableBody);
