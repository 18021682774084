import React, { useState } from "react";
import { Modal, Input, Radio } from "antd";
import { Slider, InputNumber, Row, Col, Switch } from "antd";
import { announcement } from "api/wemas";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";

const { TextArea } = Input;

const TextMsg = (props) => {
  const [message, setMessagr] = useState("");
  const [volume, setVolume] = useState(93);
  const [repeat, setRepeat] = useState(1);
  const [speed, setSpeed] = useState("fast");
  const [syren, setSyren] = useState(true);
  const [duration, setDuration] = useState(3);

  const handleSubmit = () => {
    const data = JSON.stringify({
      message,
      volume,
      repeat,
      speed,
      syren,
      duration,
    });
    announcement(props.mac_address, data)
      .then(() => {
        success("Successfully Sent...");
        props.onFinish();
      })
      .catch((err) => {
        handleResponse(err);
        console.log(err);
      });
  };

  return (
    <Modal
      title={props.title || "Control System"}
      visible={true}
      onOk={handleSubmit}
      onCancel={props.onFinish}
      okText="Send"
      // width={650}
    >
      <Row>
        <Col span={24} style={{ marginBottom: 20 }}>
          <Row>
            <Col span={4}>
              <h4>Syren </h4>
              <Switch checked={syren} onChange={(val) => setSyren(val)} />
            </Col>
            {
              <Col span={4}>
                <h4>Duration</h4>
                <InputNumber
                  disabled={!syren}
                  min={1}
                  max={30}
                  value={duration}
                  onChange={(val) => setDuration(val)}
                />
              </Col>
            }
          </Row>
        </Col>
        <Col span={6}>
          <h4>Repeat</h4>
          <InputNumber
            min={1}
            max={10}
            value={repeat}
            onChange={(val) => setRepeat(val)}
          />
        </Col>
        <Col span={8}>
          <h4>Speed</h4>
          <Radio.Group value={speed} onChange={(e) => setSpeed(e.target.value)}>
            <Radio.Button value="slow">Slow</Radio.Button>
            <Radio.Button value="fast">Normal</Radio.Button>
          </Radio.Group>
        </Col>
        <Col span={8}>
          <h4>Volume</h4>
          <Slider
            min={10}
            max={100}
            onChange={(val) => setVolume(val)}
            value={volume}
          />
        </Col>
      </Row>

      <TextArea
        value={message}
        style={{ margin: "16px 0px" }}
        onChange={(e) => setMessagr(e.target.value)}
        placeholder="Write the text to announce"
        autoSize={{ minRows: 5, maxRows: 10 }}
        maxLength={300}
      />
    </Modal>
  );
};

export default TextMsg;
