import React from "react";
import chroma from "chroma-js";
import Select, { components } from "react-select";

const colourStyles = {
  container: (styles) => {
    return {
      ...styles,
      minWidth: 750,
    };
  },
  control: (styles) => {
    return {
      ...styles,
      maxHeight: 250,
      overflow: "scroll",
    };
  },
  multiValue: (styles, { data }) => {
    const color = chroma(data.color);
    return {
      ...styles,
      margin: 4,
      backgroundColor: color.alpha(0.1).css(),
    };
  },
  group: (styles, data) => {
    return {
      ...styles,
      backgroundColor: data.options[0].color,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    padding: 6,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ":hover": {
      backgroundColor: data.color,
      color: "white",
    },
  }),
};

const groupStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
};
const groupBadgeStyles = {
  backgroundColor: "#EBECF0",
  borderRadius: "2em",
  color: "#172B4D",
  display: "inline-block",
  fontSize: 12,
  fontWeight: "normal",
  lineHeight: "1",
  minWidth: 1,
  padding: "0.16666666666667em 0.5em",
  textAlign: "center",
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const MultiValueLabel = (props) => {
  return (
    <components.MultiValueLabel {...props}>
      {props.data.group} - {props.data.label}
    </components.MultiValueLabel>
  );
};

const filterOptions = (option, input) => {
  if (input) {
    const { group, label } = option.data;
    return [group, label]
      .join(" ")
      .toLowerCase()
      .includes(input.toLowerCase());
  }
  return true;
};

const MultiSelect = ({ options, selected, handleChange }) => {
  return (
    <Select
      isMulti
      isClearable={false}
      formatGroupLabel={formatGroupLabel}
      closeMenuOnSelect={false}
      components={{ MultiValueLabel }}
      filterOption={filterOptions}
      options={options}
      styles={colourStyles}
      value={selected}
      onChange={(e) => {
        handleChange({
          target: { name: "subPermissions", value: e },
        });
      }}
    />
  );
};

export default MultiSelect;
