import React, { Component } from "react";
import { AppBarContext } from "./ContextProvider";
import { showExpandedMenu } from "config";

class MyProvider extends Component {
  state = { menuOpen: showExpandedMenu };
  render() {
    return (
      <AppBarContext.Provider
        value={{
          state: this.state,
          handleDrawer: () =>
            this.setState(({ menuOpen }) => ({
              menuOpen: !menuOpen,
            })),
        }}
      >
        {this.props.children}
      </AppBarContext.Provider>
    );
  }
}
export default MyProvider;
