import makeRequest from ".";

export function peopleCountTiles() {
  return makeRequest({
    uri: `/api/v1/people_counts/tiles`,
  });
}

export function peopleCountGraph(params) {
  return makeRequest({
    uri: `/api/v1/people_counts/recent_records?duration=${params}`,
  });
}

export function peopleCount() {
  return makeRequest({
    uri: `/api/v1/cameras?&type=PeopleCountCamera`,
  });
}
