import React, { Component } from "react";
import AppBar from "components/AppBar";
import Tiles from "./Home/Sections/Tiles";
import { Row } from "antd";
import { GraphDashboard } from "sections/dashboard/GraphDashboard";
class Home extends Component {
  render() {
    return (
      <div style={{ minWidth: "100%" }}>
        <AppBar />
        <Row>
          <Tiles />
        </Row>
        <GraphDashboard />
      </div>
    );
  }
}

export default Home;
