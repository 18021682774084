import React, { Component } from "react";
import Form from "./Form";
import { updateCamera, createCamera } from "api/camera";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";
import { fetchListingData } from "api/users";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.timer = null;
    this.role_group = "LNPR-Bot";
    this.state = {
      data: {
        name: "",
        mac_address: "",
        type: "LnprCamera",
        camera_type: "",
        status: "",
        bot_data: [],
        selected_bots: "",
        timeout: "",
      },
      open: false,
    };
  }

  componentDidMount = () => {
    this.fetchRolesData();
    if (this.props.data) {
      const {
        id,
        name,
        camera_type,
        mac_address,
        type,
        status,
        timeout,
        // bot
      } = this.props.data;
      this.id = id;
      this.setState({
        data: {
          ...this.state.data,
          camera_type,
          name,
          mac_address,
          type,
          status,
          timeout,
          // selected_bots: { label: bot.first_name, value: bot.id }
        },
        id,
      });
    }
  };

  fetchRolesData = () => {
    fetchListingData({ page: 1, per_page: 50, query: "", type: "Bot" })
      .then(({ data }) => {
        this.setState({ bot_data: data });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  handleChange = (e) => {
    const { data } = this.state;
    data[e.target.id] = e.target.value;
    this.setState({ data });
  };

  multiselectChange = (e) => {
    const { data } = this.state;
    data[e.target.name] = e.target.value;
    this.setState({ data });
  };

  handleChangeSelect = (e) => {
    const { data } = this.state;
    data[e.target.name] = e.target.value;
    this.setState({ data });
  };

  tabChange = (e) => {
    const { data } = this.state;
    data[e.target.name] = e.target.value;
    this.setState({ data });
  };

  requestParams = () => {
    const {
      data: { selected_bots, ...rest },
    } = this.state;
    const { id } = this;

    return {
      cameras: {
        id,
        ...rest,
        bot_id: selected_bots?.value,
      },
    };
  };

  onSubmit = () => {
    const { id, requestParams, props } = this;
    let requestValue = requestParams();
    const action = id ? updateCamera : createCamera;
    action(requestValue, id)
      .then((data) => {
        success(data.message);
        props.handleSubmitSuccess();
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    const {
      id,
      handleChange,
      multiselectChange,
      handleChangeSelect,
      tabChange,
    } = this;
    const { device } = this.props;
    const { data } = this.state;
    const props = {
      id,
      data,
      device,
      handleChangeSelect,
      handleChange,
      multiselectChange,
      tabChange,
    };
    return (
      <>
        <Form {...props} />
      </>
    );
  }
}
export default DialogForm("Camera", 640)(CRUD);
