import React from "react";
import { Row, Col } from "antd";
import { withStyles } from "@material-ui/core/styles";
import SimpleTextInput from "components/inputs/simpleTextInput";

const style = () => ({
  tableWrapper: {
    height: 350,
    overflowY: "auto",
    overflowX: "hidden",
  },
  field: {
    width: "inherit",
    borderColor: "Transparent",
    minHeight: "200px",
    fontSize: "16px",
    marginLeft: "2%",
  },
});

function SmsPopup(props) {
  const { from, recipient, content, status, msg_id } = props;
  return (
    <Row style={{ width: "100%", minHeight: "300px" }}>
      <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
        <SimpleTextInput readOnly labelText="From" value={`${from || ""}`} />
      </Col>
      <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
        <SimpleTextInput
          readOnly
          labelText="Recipient"
          value={`${
            recipient
              ? `${recipient.full_name || ""} ( ${recipient.phone || ""} )`
              : ""
          }`}
        />
      </Col>
      <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
        <SimpleTextInput
          readOnly
          labelText="Message ID"
          value={`${msg_id || ""}`}
        />
      </Col>
      <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px" }}>
        <SimpleTextInput
          readOnly
          labelText="Status"
          value={`${status || ""}`}
        />
      </Col>
      <Col xs={24} sm={24} md={24} style={{ padding: "0px 10px" }}>
        <SimpleTextInput
          readOnly
          labelText="Message"
          multiline
          value={`${content || ""}`}
          rows={2}
          rowsMax={7}
        />
      </Col>
    </Row>
  );
}
export default withStyles(style)(SmsPopup);
