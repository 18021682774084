import { CircularProgress, Fab } from "@material-ui/core";
import {
  green,
  purple,
  orange,
  red,
  brown,
  cyan,
} from "@material-ui/core/colors";
import React from "react";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";

const styles = () => ({
  animatedBtn: {
    justifyContent: "left",
    paddingLeft: "15px",
    transition: "all 0.4s ease 0s",
    overflow: "hidden",
    "&:hover": {
      width: 180,
      borderRadius: 50,
      "& span:before": {
        display: "block",
      },
    },
  },
  btnSpan: {
    color: "#fff",
    "&:before": {
      content: "attr(data-val)",
      position: "absolute",
      top: 17,
      width: 150,
      textAlign: "center",
      display: "none",
      right: 0,
    },
  },
});

const CustomButton = (props) => {
  const {
    classes,
    normalIcon,
    onClickIcon,
    onClick,
    loader,
    color,
    dataValue,
    progressStyles,
  } = props;
  const [loading, setLoading] = React.useState(false);
  let download_timer = null;

  function handleOnClick() {
    if (loading) {
      return;
    }
    setLoading(true);
    if (download_timer !== null) {
      clearTimeout(download_timer);
      download_timer = null;
    }

    onClick();
    download_timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
  }
  const colors = {
    primary: [purple[400], purple[600]],
    secondary: [brown[400], brown[600]],
    success: [green[400], green[600]],
    warning: [orange[400], orange[600]],
    danger: [red[400], red[600]],
    info: [cyan[600], cyan[800]],
  };

  return (
    <span>
      <Fab
        color="primary"
        className={classes.animatedBtn}
        style={{
          backgroundColor: loader
            ? loading
              ? colors[color][1]
              : colors[color][0]
            : colors[color][0],
          marginRight: 10,
        }}
        onClick={handleOnClick}
      >
        <span data-val={dataValue} className={classes.btnSpan} />
        {loader ? (loading ? onClickIcon : normalIcon) : normalIcon}
      </Fab>

      {loader && loading && (
        <CircularProgress
          size={68}
          style={{
            color: "#fff",
            position: "absolute",
            zIndex: 1,
            ...progressStyles,
          }}
        />
      )}
    </span>
  );
};

CustomButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  dataValue: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    "warning",
    "success",
    "danger",
    "primary",
    "brown",
    "info",
    "secondary",
  ]).isRequired,
};
CustomButton.defaultProps = {
  color: "primary",
};

export default withStyles(styles)(CustomButton);
