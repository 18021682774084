import React from "react";
import c3 from "c3";
import "c3/c3.css";
import { lightBlue } from "@material-ui/core/colors";
import { Card, CardHeader } from "material-dashboard-react/components";
import { AppBarContext } from "context/ContextProvider";
import moment from "moment";
import { formatDate, setHours, generateDateTime } from "helpers";
import { apiHost } from "config";
import { headers } from "helpers";

class StepChart extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.chart = null;
    this.state = {
      menuOpen: false,
      date: undefined,
    };
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.chart = null;
  };

  generateChart = () => {
    this.chart = c3.generate({
      bindto: "#" + this.props.chartID,
      data: {
        xFormat: "%Y-%m-%d %H:%M:%S%Z",
        json: [],
        type: "area-step",
        keys: {
          x: "datetime",
          value: ["state"],
        },
      },
      line: {
        connectNull: true,
        step: {
          type: "step-after",
        },
      },
      color: {
        pattern: ["#fea01b"],
      },
      axis: {
        x: {
          padding: { left: 0, right: 0 },
          type: "timeseries",
          tick: {
            fit: true,
            format: "%H:%M",
            count: 5,
            values: generateDateTime(),
          },
          min: formatDate(setHours(new Date(), "START")),
          max: formatDate(setHours(new Date(), "END")),
        },
        y: {
          min: 0,
          max: 1,
          padding: { top: 10, bottom: 0 },
          tick: {
            values: [1],
          },
        },
      },
      size: { height: window.innerHeight / 5 },
    });
  };

  reloadChart = () => {
    const { id, selectedDate } = this.props;
    fetch(
      `${apiHost}/api/v1/water_pumps/${id}/readings?from_time=${selectedDate}`,
      {
        method: "GET",
        headers: headers(),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error();
      })
      .then(async (data) => {
        this.graph_data = data.map((obj) => {
          obj["datetime"] =
            moment(obj.datetime)
              .utc()
              .format("YYYY-MM-DD H:mm:ss") + "+0000";
          return obj;
        });
        let start = setHours(selectedDate, "START");
        let end = setHours(selectedDate, "END");
        this.chart.internal.config.axis_x_min = formatDate(start);
        this.chart.internal.config.axis_x_max = formatDate(end);
        this.chart.internal.config.axis_x_tick_values = generateDateTime(
          selectedDate
        );
        this.chart.flush();

        this.chart.load({
          xFormat: "%Y-%m-%d %H:%M:%S%Z",
          json: this.graph_data,
          mimeType: "json",
          type: "spline",
          types: {
            state: "area-step", // ADD
          },
          unload: ["state"],
          names: {
            state: this.props.name,
          },
          keys: {
            x: "datetime",
            value: ["state"],
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidUpdate(prevState, nextProps) {
    if (prevState.selectedDate !== nextProps.selectedDate) {
      this.reloadChart();
    }
  }

  componentDidMount = () => {
    this.generateChart();
    this.reloadChart();
  };

  render() {
    return (
      <Card style={{ flexBasis: "35%" }}>
        <CardHeader
          style={{
            width: "40%",
            marginTop: " -20px",
            marginRight: " 20px",
            marginLeft: "20px",
            borderRadius: "3px",
            boxShadow: "rgb(80, 146, 223) -1px 7px 8px -5px",
            background: `linear-gradient(60deg, ${lightBlue[700]}, ${lightBlue[400]})`,
          }}
        >
          <h4
            style={{
              margin: "0px 0px 3px",
              color: "white",
              fontWeight: "300",
              fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            }}
          >
            {this.props.name}
          </h4>
          <p
            style={{
              color: "rgba(255,255,255,.62)",
              fontWeight: "300",
              margin: "initial",
            }}
          >
            {/* {this.props.subTitle || "xxxxxxxxxxxxxxxxxx"} */}
          </p>
        </CardHeader>
        <AppBarContext.Consumer>
          {({ state: { menuOpen } }) => {
            if (menuOpen !== this.state.menuOpen) {
              setTimeout(
                function() {
                  this.generateChart();
                  this.reloadChart();
                }.bind(this),
                100 * 3.5
              );
              this.setState({ menuOpen });
            }
          }}
        </AppBarContext.Consumer>
        <div id={this.props.chartID} style={{ margin: 20 }} />
      </Card>
    );
  }
}

export default StepChart;
