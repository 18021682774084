import React from "react";
import { titleize, keyByValue } from "helpers";
import { allReportTypes, allReportStatus } from "config";
import { StatusComp } from "data/index";

const ReportTiles = ({ users }) => {
  let color_array = [
    "#2196f3",
    "#673ab7",
    "#e91e63",
    "#0000FF",
    "#000080",
    "#800000",
    "#808080",
  ];

  const randomColor = () => {
    return Math.floor(Math.random() * color_array.length);
  };
  let bgColor = color_array[randomColor()];
  return (
    <React.Fragment>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {users &&
          users.map((user, i) => {
            return (
              <span
                key={i}
                style={{
                  color: "#fff",
                  backgroundColor: bgColor,
                  padding: "5px 15px 5px",
                  borderRadius: 50,
                  marginLeft: 5,
                  whiteSpace: "nowrap",
                  marginTop: "4px",
                  marginBottom: "4px",
                }}
              >
                {user.first_name}
              </span>
            );
          })}
      </div>
    </React.Fragment>
  );
};

export const columns = [
  {
    title: "Name",
    dataIndex: "name",
    align: "center",
    render: (name) => `${titleize(name)}`,
  },
  {
    title: "Report Type",
    dataIndex: "report_type",
    align: "center",
    render: (report_type) =>
      `${titleize(allReportTypes && keyByValue(allReportTypes, report_type))}`,
    filters:
      allReportTypes &&
      Object.keys(allReportTypes).map((key) => {
        return { text: titleize(key), value: allReportTypes[key] };
      }),
  },
  {
    title: "Users",
    dataIndex: "users",
    render: (users) => <ReportTiles users={users} />,
    align: "center",
    width: "30%",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    render: (status) => <StatusComp value={titleize(status)} />,
    filters:
      allReportStatus &&
      allReportStatus.map((status) => {
        return { text: titleize(status), value: status };
      }),
  },
];
