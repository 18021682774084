import makeRequest from ".";

function generateParams(params = {}) {
  let reportTypes = "";
  let reportStatus = "";
  if (params.report_type) {
    reportTypes = params.report_type
      .map((value) => `&report_type[]=${value}`)
      .join("");
  }
  if (params.status) {
    reportStatus = params.status.map((value) => `&status[]=${value}`).join("");
  }
  return `query=${
    params.search
  }${reportTypes}${reportStatus}&page=${params.page ||
    1}&per_page=${params.perPage || 5}`;
}

export function fetchReports(requestParams) {
  return makeRequest({
    uri: `/api/v1/reports?${generateParams(requestParams)}`,
  });
}

// ? Why no JSON.stringify?
export function createReports(requestBody) {
  return makeRequest({
    uri: "/api/v1/reports",
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

// ? Why no JSON.stringify?
export function updateReports(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/reports/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}
