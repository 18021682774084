import React from "react";
import { presence, titleize } from "helpers";
import moment from "moment";

export const empColumn = [
  {
    title: "Employee Name / Phone",
    align: "center",
    render: (_, row) => {
      return (
        <>
          <div>{presence(titleize(row.employee?.name))}</div>
          <strong>{presence(titleize(row.employee?.phone))}</strong>
        </>
      );
    },
  },
  {
    title: "Device Type",
    align: "center",
    render: (_, row) => {
      return <div>{presence(titleize(row.device_name))}</div>;
    },
  },
  {
    title: "In time",
    align: "center",
    render: (_, row) => {
      return (
        <div>
          {presence(moment(row.in_time).format("MMM Do YYYY, h:mm:ss a"))}
        </div>
      );
    },
  },
];

export const visitColumn = [
  {
    title: "Name / Phone",
    align: "center",
    render: (_, row) => {
      return (
        <>
          <div>{presence(titleize(row.visitor?.name))}</div>
          <strong>{presence(row.visitor?.phone)}</strong>
        </>
      );
    },
  },
  {
    title: "Employer",
    align: "center",
    render: (_, row) => {
      return (
        <>
          <div>{presence(row.visitor?.employer)}</div>
        </>
      );
    },
  },
  {
    title: "Number Plate",
    align: "center",
    render: (_, row) => {
      return (
        <>
          <div>{presence(row.number_plate)}</div>
        </>
      );
    },
  },
];

export const invitesColumn = [
  {
    title: "Name / Phone",
    align: "center",
    render: (_, row) => {
      return (
        <>
          <div>{presence(titleize(row.invitee?.name))}</div>
          <strong>{presence(row.invitee?.phone)}</strong>
        </>
      );
    },
  },
  {
    title: "Visitor Type",
    align: "center",
    render: (_, row) => {
      return (
        <>
          <div>{presence(row.visitor_type)}</div>
        </>
      );
    },
  },
  {
    title: "Invite Created",
    align: "center",
    render: (_, row) => {
      return (
        <>
          <div>
            {presence(moment(row.created_at).format("MMM Do YYYY, h:mm:ss a"))}
          </div>
        </>
      );
    },
  },
  {
    title: "Visiting time",
    align: "center",
    render: (_, row) => {
      return (
        <>
          <div>
            {presence(
              moment(row.visiting_time).format("MMMM Do YYYY, h:mm:ss a")
            )}
          </div>
        </>
      );
    },
  },

  {
    title: "Number Plate",
    align: "center",
    render: (_, row) => {
      return (
        <>
          <div>{presence(row.invitee?.number_plate)}</div>
        </>
      );
    },
  },

  {
    title: "Status",
    align: "center",
    render: (_, row) => {
      return (
        <>
          <div>{presence(titleize(row.invitee?.status))}</div>
        </>
      );
    },
  },
];
