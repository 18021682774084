import { apiHost } from "config";
import {
  success,
  handleResponse,
} from "components/notifications/handleResponse";
import { setCookie, getCookie, jsonHeaders } from "helpers";

export function logIn(user_name, password) {
  return fetch(`${apiHost}/login`, {
    method: "POST",
    headers: jsonHeaders,
    body: JSON.stringify({ user_name, password }),
  }).then(async (response) => {
    if (response.ok) {
      success("Login Successfully.!!!");
      return response.json();
    }
    const errorMessage = [response.status, await response.json()];
    throw errorMessage;
  });
}

export function forgotPassword(user_name) {
  return fetch(`${apiHost}/forgot-password`, {
    method: "PUT",
    headers: jsonHeaders,
    body: JSON.stringify({
      user_name,
    }),
  });
}

export function updatePassword(otp_code, user_name, password) {
  return fetch(`${apiHost}/reset-password`, {
    method: "PUT",
    headers: jsonHeaders,
    body: JSON.stringify({ otp_code, user_name, password }),
  }).then(async (response) => {
    if (response.ok) {
      return response.json();
    }
    const errorMessage = [response.status, await response.json()];
    throw errorMessage;
  });
}

export async function lastLogin() {
  const reqHeaders = {
    ...jsonHeaders,
    Authorization: getCookie("token"),
  };

  if (!reqHeaders.Authorization) {
    return;
  }

  return fetch(apiHost + "/last_login", {
    method: "PUT",
    headers: reqHeaders,
  })
    .then(() => {
      setCookie("active", "1", 0, 2);
    })
    .catch((error) => {
      handleResponse(error);
    });
}
