import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card } from "material-dashboard-react/components";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { Row, Col } from "antd";

function AlertCards(props) {
  const { classes, alertType } = props;

  const style = {
    cardTitle: {
      fontWeight: 500,
      textAlign: "center",
      minWidth: 60,
      marginRight: 10,
      marginBottom: 5,
      color: "#fff",
      fontSize: 18,
      borderRadius: 5,
      padding: "4px 0 2px",
      backgroundColor: "rgb(110, 129, 136)",
    },
    stats: {
      alignItems: "center",
      fontSize: 15,
      minWidth: 150,
      color: "#000",
      marginRight: "10px",
    },
  };
  let colors = {
    "Device Disconnected": "#b71c1c",
    "Person Detection": "#1976d2",
    Tamper: "#00796b",
    "Over Stay": "#f57c00",
    "Vehicle Traffic": "#d84315",
    Blacklist: "#424242",
    "Perimeter Crossing": "#3d5afe",
    "Person Loitering": "#e040fb",
    "Wrong Drive": "#c2185b",
    "Garbage Detection": "#9e9d24",
    SoS: "red",
  };
  return (
    <Card
      style={{
        margin: 0,
        marginTop: 15,
      }}
    >
      <Row style={{ height: "50px" }}>
        <h3 style={{ textAlign: "center", marginTop: "10px" }}>
          Types of Alerts
        </h3>
      </Row>
      <Row style={{ padding: "10px" }}>
        {alertType?.map((ele, i) => (
          <Col xs={24} sm={6} md={6}>
            <div key={i} style={{ display: "flex", margin: "5px" }}>
              <span
                className={classes.cardTitle}
                style={{
                  ...style.cardTitle,
                  backgroundColor: colors[Object.keys(ele)] || "brown",
                }}
              >
                {Object.values(ele)}
              </span>
              <span className={classes.stats} style={{ ...style.stats }}>
                {Object.keys(ele)}
              </span>
            </div>
          </Col>
        ))}
      </Row>
    </Card>
  );
}
export default withStyles(dashboardStyle)(AlertCards);
