import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card } from "material-dashboard-react/components";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

class Cards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      total_entries: 0,
      aggregation: [],
      aggregationStatus: [],
    };
  }

  render() {
    const { classes, cameras, statuses } = this.props;
    const style = {
      cardTitle: {
        fontWeight: 500,
        textAlign: "center",
        minWidth: 60,
        marginRight: 10,
        marginBottom: 5,
        color: "#fff",
        fontSize: 18,
        borderRadius: 5,
        padding: "4px 0 2px",
        backgroundColor: "rgb(110, 129, 136)",
      },
      stats: {
        alignItems: "center",
        fontSize: 15,
        minWidth: 150,
        color: "#000",
      },
    };

    let colors = {
      In: "#2196f3",
      Out: "#009688",
      Expired: "#e91e63",
      "Direct Out": "#ffc107",
      "Expired And Out": "#9c27b0",
    };

    return (
      <Card
        style={{
          display: "flex",
          flexDirection: "row",
          margin: 0,
          marginTop: 15,
        }}
      >
        <div
          style={{
            flex: 1,
            margin: "1% 1% 1% 2%",
            borderRight: "1px solid lightgray",
          }}
        >
          <h3 style={{ textAlign: "center" }}>Status</h3>
          {statuses &&
            statuses.map((ele, i) => (
              <div key={i} style={{ display: "flex" }}>
                <span
                  className={classes.cardTitle}
                  style={{
                    ...style.cardTitle,
                    backgroundColor: colors[ele && ele.key],
                  }}
                >
                  {ele && ele.doc_count}
                </span>
                <span className={classes.stats} style={{ ...style.stats }}>
                  {ele && ele.key}
                </span>
              </div>
            ))}
        </div>
        <div style={{ flex: 1, margin: "1% 1% 1% 2%" }}>
          <h3 style={{ textAlign: "center" }}>Last Location</h3>
          {cameras &&
            cameras.map((ele) => (
              <div style={{ display: "flex" }}>
                <span
                  className={classes.cardTitle}
                  style={{ ...style.cardTitle }}
                >
                  {ele && ele.doc_count}
                </span>
                <span className={classes.stats} style={{ ...style.stats }}>
                  {ele && ele.key}
                </span>
              </div>
            ))}
        </div>
      </Card>
    );
  }
}
export default withStyles(dashboardStyle)(Cards);
