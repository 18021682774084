import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import { AppBarContext } from "../context/ContextProvider";
import { withStyles } from "@material-ui/styles";
import { popCookie } from "helpers";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { filteredMenu } from "data/menus";

const drawerWidth = 245;

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    background: "#303233",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    background: "#303233",
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    marginBottom: 10,
    ...theme.mixins.toolbar,
  },
  bigAvatar: {
    margin: 10,
    width: 60,
    height: 60,
  },
  divider: {
    backgroundColor: "#8e8c8c1f",
  },
  nested: {
    paddingLeft: theme.spacing(3),
  },
});
const openToNewTab = (url) => window.open(`${window.location.origin}${url}`);

const MenuGroup = ({ history, group, path, classes, id }) => {
  return (
    <React.Fragment>
      {group.children.map((menu, i) => {
        const color = menu.match_url === path ? "rgb(108, 92, 231)" : "#fff";
        return (
          <ListItem
            key={i}
            button
            className={classes.nested}
            onClick={(e) => {
              if (e.ctrlKey) {
                openToNewTab(menu.match_url);
                window.localStorage.removeItem("alert_type_value");
              } else {
                menu.match_url !== path && popCookie("prevStateValues");
                history.push(menu.url(id));
                window.localStorage.removeItem("alert_type_value");
              }
            }}
            onMouseDown={(e) => {
              if (e.button === 1) {
                openToNewTab(menu.match_url);
              }
            }}
          >
            <ListItemIcon style={{ color }}>{menu.icon}</ListItemIcon>
            <ListItemText
              primary={menu.name}
              style={{ color, marginLeft: "-9px" }}
            />
          </ListItem>
        );
      })}
      <Divider className={classes.divider} />
    </React.Fragment>
  );
};
class Slider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuGroups: [],
      activeGroup: props.index,
    };
  }

  async componentDidMount() {
    let menuGroups = await filteredMenu();
    this.setState({ menuGroups });
  }

  handleClick = (i) => {
    this.setState({ activeGroup: i });
    window.localStorage.removeItem("alert_type_value");
  };

  render() {
    const { classes } = this.props;
    let { menuGroups, activeGroup } = this.state;
    let {
      params: { id },
      path,
    } = this.props.match;
    return (
      <AppBarContext.Consumer>
        {({ state: { menuOpen } }) => {
          return (
            <React.Fragment>
              <Drawer
                variant="permanent"
                style={{ paper: { background: "red" }, zIndex: 5 }}
                className={clsx(classes.drawer, {
                  [classes.drawerOpen]: menuOpen,
                  [classes.drawerClose]: !menuOpen,
                })}
                classes={{
                  paper: clsx({
                    [classes.drawerOpen]: menuOpen,
                    [classes.drawerClose]: !menuOpen,
                  }),
                }}
                open={menuOpen}
              >
                <div className={classes.toolbar} />
                <List>
                  {menuGroups && menuGroups.length === 1
                    ? menuGroups.map((menuGroup) =>
                        menuGroup.children.map((group, i) => (
                          <MenuGroup
                            key={i}
                            history={this.props.history}
                            {...{ group, path, classes, id }}
                          />
                        ))
                      )
                    : menuGroups.map((menuGroup, i) => {
                        let children = menuGroup.children;
                        let expand = children.some((obj) =>
                          obj.children.some(
                            (children) => children.index === activeGroup
                          )
                        );
                        const color = "#fff";
                        return (
                          <React.Fragment key={i}>
                            <ListItem
                              button
                              onClick={() => {
                                this.handleClick(children[0].children[0].index);
                              }}
                              style={{
                                paddingLeft: "16px",
                                paddingRight: "24px",
                              }}
                            >
                              <ListItemText
                                primary={menuOpen ? menuGroup.name : ""}
                                style={{ color }}
                              />
                              {expand ? (
                                <MdExpandLess
                                  style={{ color, fontSize: "25px" }}
                                />
                              ) : (
                                <MdExpandMore
                                  style={{ color, fontSize: "25px" }}
                                />
                              )}
                            </ListItem>
                            <Collapse in={expand}>
                              <List component="div" disablePadding>
                                {menuGroup.children.map((group, i) => (
                                  <MenuGroup
                                    key={i}
                                    history={this.props.history}
                                    {...{ group, path, classes, id }}
                                  />
                                ))}
                              </List>
                            </Collapse>
                            <Divider className={classes.divider} />
                          </React.Fragment>
                        );
                      })}
                </List>
              </Drawer>
            </React.Fragment>
          );
        }}
      </AppBarContext.Consumer>
    );
  }
}
const MySlider = withStyles(styles)(Slider);

export default withRouter(MySlider);
