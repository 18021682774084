import React from "react";
import { formatTime, presence, timeDiff, titleize } from "helpers";
import moment from "moment";

export const pickupDeliveryColumns = [
  {
    title: "Vehicle Number",
    dataIndex: "number_plate",
    align: "center",
    width: "40%",
    render: (number_plate) => `${presence(number_plate)}`,
  },
  {
    title: "In Time",
    dataIndex: "in_time",
    align: "center",
    render: (in_time) => (
      <React.Fragment>
        <span style={{ display: "block" }}>{formatTime(in_time)}</span>
        <span>{timeDiff(moment(), in_time)}</span>
      </React.Fragment>
    ),
  },
];

export const contractorColumns = [
  {
    title: "Vehicle Number",
    dataIndex: "number_plate",
    align: "center",
    width: "30%",
    render: (number_plate) => `${presence(number_plate)}`,
  },
  {
    title: "Purpose",
    dataIndex: "visitor_type",
    align: "center",
    width: "35%",
    render: (visitor_type) => `${presence(titleize(visitor_type))}`,
  },
  {
    title: "In Time",
    dataIndex: "in_time",
    align: "center",
    width: "35%",

    render: (in_time) => (
      <React.Fragment>
        <span style={{ display: "block" }}>{formatTime(in_time)}</span>
        <span>{timeDiff(moment(), in_time)}</span>
      </React.Fragment>
    ),
  },
];
