import React, { useState } from "react";
import { Button, ButtonGroup } from "@material-ui/core";
import { RiMicLine, RiPencilLine } from "react-icons/ri";
import { index as indexWemas } from "api/wemas";
import VoiceMsg from "./VoiceMsg";
import TextMsg from "./TextMsg";

const Announcement = (props) => {
  const [action, setAction] = useState("default");
  return (
    <div
      className="container"
      style={{
        position: "fixed",
        top: "110px",
        [props.align]: "10px",
        display: "flex",
        flexDirection: "column",
        zIndex: 10,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ButtonGroup style={{ cursor: "pointer", marginTop: 10 }}>
        <Button onClick={() => setAction("voice")}>
          <RiMicLine
            style={{
              width: 28,
              height: 28,
              color: "#cf3f37",
            }}
          />
        </Button>
        <Button onClick={() => setAction("text")}>
          <RiPencilLine
            style={{
              width: 28,
              height: 28,
              color: "rgb(13, 154, 13)",
            }}
          />
        </Button>
      </ButtonGroup>
      {
        {
          default: null,
          voice: (
            <VoiceMsg
              title={props.title}
              mac_address={props.mac_address}
              onFinish={() => {
                setAction("default");
              }}
            />
          ),
          text: (
            <TextMsg
              title={props.title}
              mac_address={props.mac_address}
              onFinish={() => setAction("default")}
            />
          ),
        }[action]
      }
    </div>
  );
};

const Home = () => {
  const [initial, setInitial] = useState(true);
  const [wemas, setWemas] = useState([]);

  React.useEffect(() => {
    if (initial) {
      setInitial(false);
      indexWemas()
        .then(({ data }) => {
          setWemas(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [initial]);
  return (
    <>
      {wemas.map((wema, index) => {
        return (
          <Announcement
            align={index === 0 ? "right" : "left"}
            mac_address={wema.mac_address}
            title={wema.name}
            key={index}
          />
        );
      })}
    </>
  );
};
export default Home;
