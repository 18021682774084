import React from "react";
import { StatusComp } from "data/index";
import { titleize } from "helpers";
import alert from "../assets/img/_alert.svg";
import noalert from "../assets/img/noalert.svg";

const EmergencyAlert = (emergency) => {
  if (emergency === null || false) {
    return <img src={noalert} alt="icon" style={{ width: "30%" }} />;
  } else if (emergency) {
    return <img src={alert} alt="icon" style={{ width: "30%" }} />;
  } else {
    return <img src={noalert} alt="icon" style={{ width: "30%" }} />;
  }
};

export const columns = [
  {
    title: "Emergency",
    dataIndex: "emergency",
    align: "center",
    width: "120px",
    render: (emergency) => EmergencyAlert(emergency),
  },
  {
    title: "Name",
    dataIndex: "name",
    align: "center",
    render: (name) => `${titleize(name)}`,
  },
  {
    title: "Alert Group",
    dataIndex: "alert_group",
    align: "center",
    render: (alert_group) => `${titleize(alert_group && alert_group.name)}`,
  },
  {
    title: "Nature",
    dataIndex: "nature",
    align: "center",
    render: (nature) => `${titleize(nature)}`,
  },
  {
    title: "Auto-Resolve",
    dataIndex: "auto_resolve",
    render: (auto_resolve) => `${titleize(auto_resolve)}`,
    align: "center",
  },
  {
    title: "Status",
    dataIndex: "status",
    align: "center",
    render: (status) => <StatusComp value={titleize(status)} />,
  },
];
