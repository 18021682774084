import React, { Component } from "react";
import { setCookie } from "helpers";
import { MdLock } from "react-icons/md";
import { withStyles } from "@material-ui/core/styles";
import { Row, Col } from "antd";
import { password } from "api/userProfile";
import {
  Button,
  Card,
  CardIcon,
  CardHeader,
  CardBody,
  CardFooter,
} from "material-dashboard-react/components/index";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import SimpleTextInput from "components/inputs/simpleTextInput";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.79)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_password: "",
      new_password: "",
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  submit = async () => {
    const { current_password, new_password } = this.state;
    await password(current_password, new_password)
      .then(({ data: { auth_token } }) => {
        success("Password updated Successfully");
        auth_token && setCookie("token", auth_token);
        this.setState({ current_password: "", new_password: "" });
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  render() {
    return (
      <Row xs={24} sm={24} md={24} style={{ padding: "0px 15px" }}>
        <Card>
          <CardHeader stats icon>
            <CardIcon
              color="primary"
              style={{ padding: "6px", borderRadius: "6px" }}
            >
              <MdLock />
            </CardIcon>
            <p
              style={{
                textAlign: "left",
                color: "#9b34b2",
                fontSize: "18px",
                marginTop: "10px",
              }}
            >
              Change Password
            </p>
          </CardHeader>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.submit();
            }}
          >
            <CardBody>
              <Row>
                <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px " }}>
                  <SimpleTextInput
                    labelText="Current Password"
                    id="current_password"
                    required
                    value={this.state.current_password}
                    onChange={this.handleChange}
                    type="password"
                  />
                </Col>
                <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px " }}>
                  <SimpleTextInput
                    labelText="New Password"
                    id="new_password"
                    required
                    value={this.state.new_password}
                    onChange={this.handleChange}
                    type="password"
                  />
                </Col>
              </Row>
            </CardBody>
            <CardFooter
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingBottom: "20px",
                paddingRight: "15px",
              }}
            >
              <Button
                type="submit"
                style={{
                  backgroundColor: "white",
                  color: "green",
                  border: "1px solid green",
                }}
              >
                Change Password
              </Button>
            </CardFooter>
          </form>
        </Card>
      </Row>
    );
  }
}

export default withStyles(styles)(ChangePassword);
