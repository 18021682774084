import React from "react";
import { FormControl, Select, MenuItem, InputLabel } from "@material-ui/core";
import SimpleTextInput from "components/inputs/simpleTextInput";
import { default as ReactSelect } from "react-select";
import { Row, Col } from "antd";
import RadioButton from "components/inputs/radioButton.jsx";
import { kioskType } from "config";
export default function Form(props) {
  const {
    id,
    data,
    handleChange,
    handleChangeSelect,
    handleDeviceChange,
    softwareData,
    type
  } = props;
  const deviceStatus = ["active", "inactive"];
  const { name, mac_address, status, device_type, timeout, software_id } = data;

  const timeOutStyle = {
    padding: "10px 20px",
    marginTop: "10px",
  };
  return (
    <React.Fragment>
      <Row style={{ padding: 10 }}>
        <Col xs={24} sm={12}>
          <Row>
            <Col xs={24} sm={24} style={{ padding: "10px 20px" }}>
              <SimpleTextInput
                labelText="Name"
                id="name"
                value={name}
                onChange={handleChange}
                disabled={id}
                required
              />
            </Col>
            <Col xs={24} sm={24} style={{ padding: "10px 20px" }}>
              <SimpleTextInput
                labelText="MAC Address"
                id="mac_address"
                value={mac_address}
                onChange={handleChange}
                required
                disabled={id}
              />
            </Col>
            {!id && (
              <Col xs={24} sm={24} style={{ padding: "10px 17px" }}>
                <RadioButton
                  labelText="Status"
                  value={status || "active"}
                  data={deviceStatus}
                  onChange={handleChangeSelect}
                  name="status"
                  color={true}
                />
              </Col>
            )}
          </Row>
        </Col>
        <Col xs={24} sm={12}>
          <Row>
            {!id && kioskType && device_type && (
              <Col xs={24} sm={24} style={{ padding: "10px 20px" }}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="age-simple">Device Type</InputLabel>
                  <Select
                    style={{ width: "100%", height: "84%" }}
                    name="device_type"
                    value={device_type}
                    onChange={handleDeviceChange}
                    required
                    disabled={id}
                  >
                    {kioskType.map((val, i) => {
                      return (
                        <MenuItem key={i} value={val.value}>
                          {val.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Col>
            )}
            <Col
              xs={24}
              sm={24}
              style={!id ? timeOutStyle : { padding: "10px 20px" }}
            >
              <SimpleTextInput
                labelText="TimeOut (in minutes)"
                id="timeout"
                value={timeout}
                onChange={handleChange}
                required
              />
            </Col>
            {id && (
              <Col
                xs={24}
                sm={24}
                style={!id ? timeOutStyle : { padding: "10px 20px" }}
              >
                <RadioButton
                  labelText="Status"
                  value={status || "active"}
                  data={deviceStatus}
                  onChange={handleChangeSelect}
                  name="status"
                  color={true}
                />
              </Col>
            )}
          </Row>
        </Col>
        {type !== 'webcheckin' && (
          <Col xs={24} sm={24} style={{ padding: "10px 20px" }}>
          <p
            style={{
              fontSize: 10,
              margin: "-0.1em 0 0.3em",
              color: "rgba(0, 0, 0, 0.54)",
            }}
            >
            {"Software*"}
          </p>
          <ReactSelect
            value={software_id}
            options={softwareData}
            onChange={(value) =>
              handleChangeSelect({
                target: { value: value || [], name: "software_id" },
              })
            }
            />

          <input
            required
            value={software_id}
            tabIndex={-1}
            autoComplete="off"
            style={{ opacity: 0, height: 0, position: "absolute" }}
            />
        </Col>
      )}
      </Row>
    </React.Fragment>
  );
}
