import makeRequest from ".";

function generateParams(params = {}, totalEntries = null) {
  if (params.sort_order && params.sort_field) {
    return `query=${params.search || ""}&sort_order=${
      params.sort_order
    }&sort_field=${params.sort_field}&page=${params.page ||
      1}&per_page=${totalEntries || params.perPage || 25}`;
  } else {
    return `query=${params.search || ""}&page=${params.page ||
      1}&per_page=${totalEntries || params.perPage || 25}`;
  }
}

export function fetchVehicleData(requestParams) {
  return makeRequest({
    uri: `/api/v1/vehicles?${generateParams(requestParams)}`,
  });
}

export function createVehicle(requestBody) {
  return makeRequest({
    uri: "/api/v1/vehicles",
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updateVehicle(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/vehicles/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

export function fetchVehiclenumber() {
  return makeRequest({
    uri: `/api/v1/vehicles/vehicle_number`,
  });
}

export function VehicleAnalytics(number_plate) {
  return makeRequest({
    uri: `/api/v1/vehicles/${number_plate}/analytics`,
  });
}

export function VisitorAnalytics(phone) {
  return makeRequest({
    uri: `/api/v1/visitors/${phone}/analytics`,
  });
}