import React from "react";
import AlertsScreen from "../AlertsScreen";
import { apiHost } from "config";
import { headers } from "helpers";
import { handleResponse } from "components/notifications/handleResponse";

export const menu = async () => {
  let menus = [];
  await fetch(`${apiHost}/api/v1/presentations`, {
    headers: headers(),
  })
    .then(async (response) => {
      if (response.ok) {
        return response.json();
      }
      const errorMessage = [response.status, await response.json()];
      throw errorMessage;
    })
    .then(({ data }) => {
      for (let i = 0; i < data.length; i++) {
        let val = data[i];
        let alertUrl = data[i].alert_types
          .map((value) => `&type[]=${value}`)
          .join("");
        let childProps = {
          title: data[i].title,
          subTitle: data[i].subtitle,
          alertUrl: alertUrl,
        };

        menus.push({
          ...val,
          match_url: data[i].url,
          url: () => data[i].url,
          icon: data[i].icon_url,
          render: (props) => (
            <AlertsScreen {...props} childProps={childProps} />
          ),
        });
      }
    })
    .catch((error) => {
      handleResponse(error);
    });
  return menus;
};
