import React, { Component } from "react";
import Form from "./Form";
import { updateCompany, createCompany } from "api/company";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import DialogForm from "hoc/dialogForm";

class CRUD extends Component {
  constructor(props) {
    super(props);
    this.id = undefined;
    this.state = {
      open: false,
      data: {
        name: "",
        block: "",
        status: "active",
      },
    };
  }

  handleChange = (e) => {
    let { data } = this.state;
    let { name, value } = e.target;
    data[name] = value;
    this.setState({ data });
  };

  requestParams = () => {
    const { name, block, status, id } = this.state.data;
    let params = {
      name,
      block,
      status,
      id,
    };
    let update_company = {
      ...params,
    };
    let create = {
      name,
      block,
      status,
    };
    let create_company = {
      ...create,
    };
    if (this.props?.data?.id) {
      return update_company;
    } else {
      return create_company;
    }
  };

  onSubmit = () => {
    const { id, props } = this;
    props.submitClick();
    const method = id ? updateCompany : createCompany;
    method(this.requestParams(), id)
      .then((data) => {
        success(data.message);
        props.handleSubmitSuccess();
        props.submitClick();
      })
      .catch((error) => {
        props.submitClick();
        handleResponse(error);
      });
  };

  componentDidMount = () => {
    if (this.props.data) {
      const { id, name, block, status } = this.props.data;
      this.id = id;
      this.setState({
        data: {
          ...this.state.data,
          name,
          block,
          status,
        },
      });
    }
  };

  render() {
    const { id, handleChange } = this;
    const { data } = this.state;
    return (
      <Form
        {...{
          ...data,
          handleChange,
          id,
        }}
      />
    );
  }
}
export default DialogForm("Company")(CRUD);
