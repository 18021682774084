import React from "react";
import { setCookie } from "helpers";
import { withStyles } from "@material-ui/styles";
import { ContextProvider } from "context/ContextProvider";
import Forget from "./Sections/Forget";
import Update from "./Sections/PasswordUpdate";
import { style } from "./style.jsx";
import { forgotPassword, updatePassword } from "api/login";
import {
  success,
  handleResponse,
} from "components/notifications/handleResponse";

class ForgetView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: true,
    };
  }

  componentDidMount = () => {
    document.getElementsByClassName(
      "content-inside"
    )[0].style.paddingBottom = 0;
    let footer = document.getElementById("footer");
    if (footer) {
      footer.remove();
    }
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  sendOTP = async () => {
    const { user_name } = this.state;
    await forgotPassword(user_name)
      .then(async (response) => {
        if (response.ok) {
          success("Successfully Sent OTP.!!!");
          return this.setState({ initial: false });
        }
        const errorMessage = [response.status, await response.json()];
        throw errorMessage;
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  update = async () => {
    const { otp_code, user_name, password } = this.state;
    await updatePassword(otp_code, user_name, password)
      .then(
        ({
          data: { auth_token, first_name, last_name, email, refresh_token },
        }) => {
          success("Password updated Successfully");
          first_name && setCookie("first_name", first_name, 60);
          last_name && setCookie("last_name", last_name, 60);
          email && setCookie("email", email, 60);
          setCookie("token", auth_token);
          setCookie("refresh_token", refresh_token);
          window.location.reload();
        }
      )
      .catch((error) => {
        handleResponse(error);
      });
  };

  goBack = () => {
    this.props.history.push("/");
  };

  render() {
    const { handleChange, sendOTP, update, state, goBack } = this;
    const { classes } = this.props;
    const { initial } = this.state;
    return (
      <ContextProvider.Provider
        value={{
          state,
          handleChange,
          sendOTP,
          update,
          goBack,
        }}
      >
        <div className={classes.backgroundWithImage}>
          <div className={classes.dimLayer}>
            {initial ? <Forget /> : <Update />}
          </div>
        </div>
      </ContextProvider.Provider>
    );
  }
}

export default withStyles(style)(ForgetView);
