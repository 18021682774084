import React, { Component } from "react";
import { MdPages } from "react-icons/md";
import { withStyles } from "@material-ui/core/styles";
import { Row, Col } from "antd";
import { userHomepage } from "api/userProfile";
import {
  Button,
  Card,
  CardIcon,
  CardHeader,
  CardBody,
  CardFooter,
} from "material-dashboard-react/components/index";
import {
  handleResponse,
  success,
} from "components/notifications/handleResponse";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { getCookie } from "helpers";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.79)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      home_page: getCookie("home_page"),
    };
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submit = async () => {
    const { home_page } = this.state;
    await userHomepage(home_page)
      .then(() => {
        success("Updated Successfully");
        this.setState({ home_page });
        document.cookie = `home_page=${home_page}`;
      })
      .catch((error) => {
        handleResponse(error);
      });
  };

  // componentDidUpdate = () => {
  //   if (this.props.default_page !== this.state.home_page) {
  //     this.setState({ home_page: this.props.default_page });
  //   }
  //   if else () {
  //     this.setState({ home_page: "" });
  //   }
  // }

  render() {
    const { handleChange } = this;
    return (
      <Row xs={24} sm={24} md={24} style={{ padding: "0px 15px" }}>
        <Card>
          <CardHeader stats icon>
            <CardIcon
              color="primary"
              style={{ padding: "6px", borderRadius: "6px" }}
            >
              <MdPages />
            </CardIcon>
            <p
              style={{
                textAlign: "left",
                color: "#9b34b2",
                fontSize: "18px",
                marginTop: "10px",
              }}
            >
              Update Preferences
            </p>
          </CardHeader>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.submit();
            }}
          >
            <CardBody>
              <Row>
                <Col xs={24} sm={12} md={12} style={{ padding: "0px 10px " }}>
                  <p
                    style={{
                      color: "#969292",
                      paddingLeft: "15px",
                      margin: "0px",
                    }}
                  >
                    Default Home Page
                  </p>
                  <Select
                    value={this.state.home_page}
                    onChange={(e) => handleChange(e)}
                    name="home_page"
                    style={{
                      width: "100%",
                      marginLeft: "15px",
                      marginTop: "16px",
                    }}
                  >
                    <MenuItem value="dashboard">Dashboard</MenuItem>
                    <MenuItem value="presentation">Alert Presentation</MenuItem>
                  </Select>
                </Col>
              </Row>
            </CardBody>
            <CardFooter
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingBottom: "20px",
                paddingRight: "15px",
                marginTop: "-60px",
              }}
            >
              <Button
                type="submit"
                style={{
                  backgroundColor: "white",
                  color: "green",
                  border: "1px solid green",
                }}
              >
                Update Preferences
              </Button>
            </CardFooter>
          </form>
        </Card>
      </Row>
    );
  }
}

export default withStyles(styles)(HomePage);
