import React, { PureComponent } from "react";
import { TableContext } from "context/ContextProvider";
import { CSVLink } from "react-csv";
import { Grid } from "@material-ui/core";
import { MdCached, MdHourglassEmpty, MdArrowDownward } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import CustomButton from "components/CustomButton";
import ButtonGroup from "./ButtonGroup";
import moment from "moment";

const invertData = (meters) => {
  let names = {};
  Object.keys(meters).forEach((key) => {
    if (key !== "All Meters") {
      names[meters[key][0]] = key;
    }
  });
  return names;
};

class Top extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.CSVData = [];
    this.CSVHeader = [];
  }

  setCSVData = (data, meters, meterSelected) => {
    if (data && data.length === 0) {
      return;
    }
    var names = invertData(meters);
    this.CSVHeader = [{ key: "datetime", label: "Date" }];

    meters[meterSelected].map((id) =>
      this.CSVHeader.push({
        key: names[id],
        label: names[id],
      })
    );

    this.CSVData = data.map((element) => {
      var obj = { datetime: moment(element.datetime).format("lll") };
      meters[meterSelected].forEach((id) => {
        obj[names[id]] = element[id];
      });
      return obj;
    });
  };

  render() {
    return (
      <Grid container alignItems="center">
        <Grid item xs={12} sm={8} md={5}>
          <ButtonGroup />
        </Grid>
        <TableContext.Consumer>
          {({ handleRefresh, state: { meters, meterSelected, data } }) => {
            this.setCSVData(data, meters, meterSelected);
            return (
              <Grid item xs={12} sm={4} md={7}>
                <Grid container justify="flex-end">
                  <CustomButton
                    loader
                    color="warning"
                    normalIcon={<MdCached />}
                    onClickIcon={<MdHourglassEmpty />}
                    onClick={handleRefresh}
                    dataValue="refresh"
                    progressStyles={{
                      top: 80,
                      right: 95,
                    }}
                  />
                  <CSVLink
                    data={this.CSVData}
                    headers={this.CSVHeader}
                    filename={`${meterSelected}-ConsumptionExport.csv`}
                    target="_blank"
                  >
                    <CustomButton
                      loader
                      normalIcon={<MdArrowDownward />}
                      onClickIcon={<IoMdArrowDropdown />}
                      onClick={() => {}}
                      color="warning"
                      dataValue="download"
                      progressStyles={{
                        top: 80,
                        right: 28,
                      }}
                    />
                  </CSVLink>
                </Grid>
              </Grid>
            );
          }}
        </TableContext.Consumer>
      </Grid>
    );
  }
}

export default Top;
