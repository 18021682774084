import React from "react";
import c3 from "c3";
import "c3/c3.css";
import { lightBlue } from "@material-ui/core/colors";
import { Card, CardHeader } from "material-dashboard-react/components";
import { AppBarContext } from "context/ContextProvider";
import {
  capitalizeFirstLetter,
  formatDate,
  setHours,
  generateDateTime,
} from "helpers";
import moment from "moment";
import { handleResponse } from "components/notifications/handleResponse";
import { headers } from "helpers";
import { apiHost } from "config";

const meters = {
  "All Meters": [
    "cd4e9d7d-6f00-479d-8aca-5d462c11fa1a",
    "d6c2c4cd-44c3-491f-835a-29c5e18bdef0",
  ],
};

class StepChart extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.chart = null;
    this.state = {
      menuOpen: false,
    };
  }

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.chart = null;
  };

  generateParams() {
    let start = new Date(this.props.selectedDate).setHours(0, 0, 0, 0);
    let end = new Date(this.props.selectedDate).setHours(23, 59, 59, 999);
    let from = moment.utc(start).format();
    let to = moment.utc(end).format();
    let ids = meters["All Meters"].map((id) => `&ids[]=${id}`).join("");

    return `from_time=${from}&to_time=${to}${ids}&page=1&per_page=1000`;
  }

  generateChart = () => {
    this.chart = c3.generate({
      bindto: "#water-pump-chart",
      data: {
        xFormat: "%Y-%m-%d %H:%M:%S%Z",
        json: [],
        type: "area-step",
        keys: {
          x: "datetime",
          value: [
            "active_count",
            "cd4e9d7d-6f00-479d-8aca-5d462c11fa1a",
            "d6c2c4cd-44c3-491f-835a-29c5e18bdef0",
          ],
        },
        axes: {
          active_count: "y2",
        },
      },
      line: {
        connectNull: true,
        step: {
          type: "step-after",
        },
      },
      color: {
        pattern: ["#fea01b", "#f27876", "#11a0f8"],
      },
      point: {
        show: false,
        focus: {
          expand: {
            enabled: true,
          },
        },
      },
      tooltip: {
        format: {
          name: function(name, ratio, id, index) {
            if (id === "active_count") {
              return Array.prototype.reduce.call(
                this.graph_data[index].water_pumps,
                (accumulator, currentValue) => {
                  if (accumulator === "")
                    return capitalizeFirstLetter(currentValue);
                  return (
                    capitalizeFirstLetter(accumulator) +
                    ", " +
                    capitalizeFirstLetter(currentValue)
                  );
                },
                ""
              );
            }
            return name;
          }.bind(this),
        },
      },
      axis: {
        x: {
          padding: { left: 0, right: 0 },
          type: "timeseries",
          tick: {
            fit: true,
            format: "%H:%M",
            count: 5,
            values: generateDateTime(),
          },
          min: formatDate(setHours(new Date(), "START")),
          max: formatDate(setHours(new Date(), "END")),
        },
        y: {
          min: 0,
          padding: { top: 10, bottom: 0 },
          label: {
            text: "Consumption",
            position: "outer-middle",
          },
        },
        y2: {
          show: true,
          label: {
            text: "No.of Pump's",
            position: "outer-middle",
          },
          padding: { top: 10, bottom: 0 },
          tick: {
            values: [1, 2, 3, 4, 5, 6],
          },
        },
      },
      size: { height: 500 },
    });
  };

  _loadGraph = async () => {
    let activeHeader = headers();
    if (!activeHeader.Authorization) {
      return;
    }
    fetch(
      `${apiHost}/api/v1/water_meters/reading_summary?${this.generateParams()}`,
      {
        method: "GET",
        headers: headers(),
      }
    )
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error(res.status, await res.json());
      })
      .then(async ({ data }) => {
        this.water_meter_data = this.mergeData(data);
        this.reloadChart();
      })
      .catch((err) => {
        handleResponse(err);
      });
  };

  mergeData = (data) => {
    let arr = [];
    Object.keys(data).forEach((key) => {
      data[key].forEach((obj) => {
        arr.push({ datetime: obj.summary_datetime, [key]: obj.consumption });
      });
    });
    return arr;
  };

  reloadChart = () => {
    const { selectedDate } = this.props;
    let activeHeader = headers();
    if (!activeHeader.Authorization) {
      return;
    }
    fetch(
      `${apiHost}/api/v1/water_pumps/active_pumps?from_time=${selectedDate}`,
      {
        method: "GET",
        headers: headers(),
      }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error();
      })
      .then(async (data) => {
        this.water_pump_data = data;
        let combined_data = [...this.water_meter_data, ...this.water_pump_data];
        let sorted_data = await combined_data.sort((a, b) =>
          moment(a.datetime).diff(moment(b.datetime))
        );

        this.graph_data = await sorted_data.map((obj) => {
          obj["datetime"] =
            moment(obj.datetime)
              .utc()
              .format("YYYY-MM-DD H:mm:ss") + "+0000";
          return obj;
        });

        let start = setHours(selectedDate, "START");
        let end = setHours(selectedDate, "END");
        this.chart.internal.config.axis_x_min = formatDate(start);
        this.chart.internal.config.axis_x_max = formatDate(end);
        this.chart.internal.config.axis_x_tick_values = generateDateTime(
          selectedDate
        );
        this.chart.flush();

        this.chart.load({
          xFormat: "%Y-%m-%d %H:%M:%S%Z",
          json: this.graph_data,
          mimeType: "json",
          type: "spline",
          types: {
            active_count: "area-step", // ADD
          },
          unload: [
            "active_count",
            "cd4e9d7d-6f00-479d-8aca-5d462c11fa1a",
            "d6c2c4cd-44c3-491f-835a-29c5e18bdef0",
          ],
          names: {
            active_count: "Active Pumps",
            "cd4e9d7d-6f00-479d-8aca-5d462c11fa1a": "Small-Meter",
            "d6c2c4cd-44c3-491f-835a-29c5e18bdef0": "Big-Meter",
          },
          keys: {
            x: "datetime",
            value: [
              "active_count",
              "cd4e9d7d-6f00-479d-8aca-5d462c11fa1a",
              "d6c2c4cd-44c3-491f-835a-29c5e18bdef0",
            ],
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidUpdate(prevState, nextProps) {
    if (prevState.selectedDate !== nextProps.selectedDate) {
      this._loadGraph();
    }
  }

  componentDidMount = () => {
    this.generateChart();
    this._loadGraph();
  };

  render() {
    return (
      <Card style={{ flexBasis: "35%" }}>
        <CardHeader
          style={{
            marginTop: " -20px",
            marginRight: " 20px",
            marginLeft: "20px",
            borderRadius: "3px",
            boxShadow: "rgb(80, 146, 223) -1px 7px 8px -5px",
            background: `linear-gradient(60deg, ${lightBlue[700]}, ${lightBlue[400]})`,
          }}
        >
          <h4
            style={{
              margin: "0px 0px 3px",
              color: "white",
              fontWeight: "300",
              fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
            }}
          >
            {"Pump Vs Consumption Summary"}
          </h4>
          <p
            style={{
              color: "rgba(255,255,255,.62)",
              fontWeight: "300",
              margin: "initial",
            }}
          >
            {"Day wise comparision of Pump Status and Consumption Summary"}
          </p>
        </CardHeader>
        <AppBarContext.Consumer>
          {({ state: { menuOpen } }) => {
            if (menuOpen !== this.state.menuOpen) {
              setTimeout(
                function() {
                  this.generateChart();
                  this.reloadChart();
                }.bind(this),
                100 * 3.5
              );
              this.setState({ menuOpen });
            }
          }}
        </AppBarContext.Consumer>
        <div id="water-pump-chart" style={{ margin: 20 }} />
      </Card>
    );
  }
}

export default StepChart;
