import React, { Component } from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Divider,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardIcon,
} from "material-dashboard-react/components";
import { MdBorderAll } from "react-icons/md";
import { apiHost } from "config";
import { headers, capitalizeFirstLetter, timeDiff } from "helpers";
import Grid from "@material-ui/core/Grid";

const style = () => ({
  tableWrapper: {
    height: 300,
    overflowY: "auto",
  },
});

const Header = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left">Name</TableCell>
        <TableCell align="left">Current Status</TableCell>
        <TableCell align="left">Duration</TableCell>
      </TableRow>
    </TableHead>
  );
};

const Body = (props) => {
  const { data } = props;
  return (
    <TableBody>
      {data.length !== 0 ? (
        data.forEach((ele, index) => {
          if (!ele) {
            return;
          }
          let color = ele.state === "on" ? "green" : "red";
          return (
            <TableRow key={index}>
              <TableCell align="left" style={{ textTransform: "capitalize" }}>
                {capitalizeFirstLetter(ele.water_pump.name) || ""}
              </TableCell>
              <TableCell
                align="center"
                style={{ textTransform: "uppercase", color }}
              >
                {capitalizeFirstLetter(ele.state) || "-"}
              </TableCell>
              <TableCell
                align="left"
                style={{ textTransform: "uppercase", textAlign: "center" }}
              >
                {timeDiff(new Date(), ele.created_at) || "-"}
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow style={{ height: 250 }}>
          <TableCell align="center" colSpan={3}>
            No Data To Display
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

const MyTableCardHeader = () => {
  return (
    <React.Fragment>
      <CardHeader color="warning" stats icon style={{ marginBottom: "20px" }}>
        <CardIcon
          color="warning"
          style={{ padding: "15px", borderRadius: "6px" }}
        >
          <MdBorderAll />
        </CardIcon>
        <p
          style={{
            textAlign: "left",
            color: "#fe9f1b",
            fontSize: "18px",
            marginTop: "15px",
          }}
        >
          WaterPump Table
        </p>
      </CardHeader>
      <Divider />
    </React.Fragment>
  );
};

class MyTable extends Component {
  constructor(props) {
    super(props);
    this.state = { data: [] };
  }

  componentDidMount() {
    fetch(`${apiHost}/api/v1/water_pumps/latest`, {
      method: "GET",
      headers: headers(),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error();
      })
      .then(({ data }) => {
        this.setState({ data });
      })
      .catch((err) => console.log(err));
  }

  render() {
    const { classes } = this.props;
    const { data } = this.state;
    return (
      <Grid item xs={12} sm={12} md={4} style={{ padding: "0 8px" }}>
        <Card style={{ flexBasis: "27%" }}>
          <MyTableCardHeader />
          <div className={classes.tableWrapper}>
            <Table className={classes.table}>
              <Header />
              <Body data={data} />
            </Table>
          </div>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(style)(MyTable);
