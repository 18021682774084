import makeRequest from ".";

function generateParams(params = {}, totalEntries = null) {
  return `&query=${params.search}&page=${params.page ||
    1}&per_page=${totalEntries || params.perPage}`;
}

export function fetchRegisteredData(requestParams) {
  return makeRequest({
    uri: `/api/v1/contractors?${generateParams(requestParams)}`,
  });
}
export function createContractors(requestBody) {
  return makeRequest({
    uri: `/api/v1/contractors`,
    method: "POST",
    body: JSON.stringify(requestBody),
  });
}

export function updateContractors(requestBody, id) {
  return makeRequest({
    uri: `/api/v1/contractors/${id}`,
    method: "PUT",
    body: JSON.stringify(requestBody),
  });
}

export function uploadBulkContractor(body) {
  return makeRequest({
    uri: `/api/v1/contractors/import`,
    method: "POST",
    body,
  });
}
